import React from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import { CONTRACT_STATUSES } from '@constants';
import PeriodSelect from '../../Inputs/PeriodSelect';
import { TextWithTooltipIcon } from '@components/Display';
import { getValueObj } from '@helpers/helpers';
import moment from 'moment';
import { FLOW } from '@store/AppReducer';

function RequirementMainFields({ flow, handleValueChanges, fields, errors }) {
  const {
    contractStatusId,
    contractStatus,
    startDate,
    latestStartDate,
    dateFlexibility,
    projectName,
    duration,
    nrOfWells
  } = fields;

  return (
    <Stack spacing={1}>
      <Typography bold>Contract</Typography>
      <Stack spacing={2}>
        <Stack>
          <Typography variant={'body2'}>PROJECT NAME</Typography>
          <TextField
            value={projectName || ''}
            variant={'outlined'}
            placeholder={'Project name...'}
            onChange={({ target }) => handleValueChanges({ projectName: target.value })}
          />
        </Stack>
        <Stack>
          <Typography color={errors.contractStatus ? 'error' : 'text.primary'} variant={'body2'}>
            CONTRACT STATUS*
          </Typography>
          <LookupSelect
            error={!!errors.contractStatus && !contractStatusId ? errors.contractStatus : null}
            id={'contract-status-select'}
            handleChange={(_, val) =>
              handleValueChanges({
                contractStatusId: parseInt(val.id),
                contractStatus: val.name
              })
            }
            value={getValueObj(contractStatusId, contractStatus)}
            options={CONTRACT_STATUSES}
          />
        </Stack>
        <PeriodSelect
          rangeEndLabel={'Latest start'}
          rangeStartLabel={'Earliest start'}
          required
          startPlaceholder={'Earliest start date...'}
          endPlaceholder={'Latest start date...'}
          startTitle={
            <TextWithTooltipIcon
              error={!!errors.startDate}
              textVariant={'body2'}
              label={'EARLIEST START DATE*'}
              tooltipText={'The earliest date the contract can start.'}
            />
          }
          endTitle={
            <TextWithTooltipIcon
              textVariant={'body2'}
              label={'LATEST START DATE'}
              tooltipText={'The latest date the contract can start.'}
            />
          }
          onChange={({ value }) => {
            handleValueChanges({
              startDate: moment(value[0]).format('yyyy-MM-DD'),
              latestStartDate: moment(value[1]).format('yyyy-MM-DD')
            });
          }}
          value={[startDate, latestStartDate]}
          errorStart={errors.startDate}
        />
        <Stack>
          <Typography variant={'body2'} color={errors.duration ? 'error' : 'text.primary'}>
            DURATION*(DAYS)
          </Typography>
          <OutlinedInput
            error={!!errors.duration && !duration}
            endAdornment={<InputAdornment position="end">Days</InputAdornment>}
            type={'number'}
            value={duration || ''}
            variant={'outlined'}
            placeholder={'I.e: 30'}
            onChange={({ target }) =>
              handleValueChanges({ duration: parseInt(target.value) || '' })
            }
          />
          {!!errors.duration && (
            <FormHelperText sx={{ pl: 2 }} error>
              {errors.duration}
            </FormHelperText>
          )}
        </Stack>
        {flow === FLOW.OIL_COMPANY && (
          <Stack>
            <TextWithTooltipIcon
              textVariant={'body2'}
              label={'DATE FLEXIBILITY'}
              tooltipText={
                'The number of days allowed to overlap between rig availability and a requirement or contract'
              }
            />
            <TextField
              type={'number'}
              value={dateFlexibility || ''}
              variant={'outlined'}
              placeholder={'Date flexibility...'}
              onChange={({ target }) =>
                handleValueChanges({ dateFlexibility: parseInt(target.value) })
              }
            />
          </Stack>
        )}
        <Stack>
          <Typography variant={'body2'}>NUMBER OF WELLS</Typography>
          <TextField
            type={'number'}
            value={typeof nrOfWells === 'number' ? nrOfWells : ''}
            variant={'outlined'}
            placeholder={'Number of wells...'}
            onChange={({ target }) => {
              if (parseInt(target.value) > -1) {
                handleValueChanges({ nrOfWells: parseInt(target.value) });
              } else {
                handleValueChanges({ nrOfWells: '' });
              }
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

RequirementMainFields.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  errors: PropTypes.object,
  fields: PropTypes.object,
  flow: PropTypes.oneOf(Object.values(FLOW))
};

RequirementMainFields.defaultProps = {
  fields: {},
  errors: {},
  flow: FLOW.OIL_COMPANY
};

export default RequirementMainFields;
