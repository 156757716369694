import { FLOW, GENERAL_ACTIONS } from './AppReducer';

export const setStateFromSessionStorage = (dispatch) => {
  let tempState = JSON.parse(window.localStorage.getItem('esgianRigsPlanner')) || null;
  if (!tempState) return;
  dispatch({
    type: GENERAL_ACTIONS.SET_PROJECTS_DATA,
    flow: FLOW.GENERAL,
    payload: { projectsData: tempState.projectsData }
  });
  dispatch({
    type: GENERAL_ACTIONS.SET_RIG_SUPPLY_CHART_SETTING,
    flow: FLOW.GENERAL,
    payload: { chartSettings: { ...tempState.sectionFilters.rigSupply.chartSettings } }
  });
};

export const initState = {
  themeMode: !!JSON.parse(localStorage.getItem('esgian-theme-mode')),
  sideMenuOpen: !!JSON.parse(localStorage.getItem('esgian-menu-open')),
  user: null,
  filterLookups: {},
  sectionFilters: {
    rigSupply: {
      chartSettings: {
        dayRate: true,
        country: false,
        rigType: false,
        region: false,
        contractType: true,
        duration: false
      }
    }
  },
  projectsData: {
    version: 1.0,
    index: 1,
    projects: []
  }
};
