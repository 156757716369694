import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@mobiscroll/react';
import { Stack, Typography } from '@esgian/esgianui';
import RigInfoValueDisplay from '@components/Display/RigInfoValueDisplay/RigInfoValueDisplay';
import moment from 'moment/moment';

function ActivityTooltip({ anchor, details }) {
  const { endDate, region, country, rigStatus, startDate } = details || {};

  return (
    <Popup
      themeVariant={'dark'}
      display="anchored"
      isOpen={true}
      anchor={anchor}
      touchUi={false}
      showOverlay={false}
      contentPadding={false}
      closeOnOverlayClick={false}
      cssClass="timeline-tooltip">
      <div>
        <Stack sx={{ p: 2 }} spacing={1}>
          <Typography>{rigStatus}</Typography>
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={region}
            title={'Region:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={country}
            title={'Country:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={moment(startDate).format('DD/MM/yyyy')}
            title={'Start date: '}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={moment(endDate).format('DD/MM/yyyy')}
            title={'End date: '}
          />
        </Stack>
      </div>
    </Popup>
  );
}

ActivityTooltip.propTypes = {
  anchor: PropTypes.object,
  details: PropTypes.object
};

ActivityTooltip.defaultProps = {
  anchor: null,
  details: null
};

export default ActivityTooltip;
