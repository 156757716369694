import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FLOW } from '@store/AppReducer';
import { useParams } from 'react-router-dom';
import { useStore } from '@store/Store';
import { InsertCommentOutlinedIcon, Stack, ToolTip } from '@esgian/esgianui';
import { RequirementMenu } from '@components/Timeline';
import { getProject } from '@helpers/helpers';

function RequirementResourceButton({ flow, resource }) {
  const [mousePosition, setMousePosition] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [requirementDetails, setRequirementDetails] = useState(null);
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    }
  } = useStore();
  const { contractsWithoutRigs: lookupRequirements } = filterLookups;

  const params = useParams();

  const handleClick = (event) => {
    setMousePosition({ x: event.clientX + 100, y: event.clientY });
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMousePosition(null);
    setMenuOpen(false);
  };

  useEffect(() => {
    const { customRequirements } = getProject(projects, parseInt(params.projectId));
    let id = resource.id.split(',');
    if (flow === FLOW.RIG_SUPPLIER) {
      let requirement = customRequirements?.find(
        ({ contractId, rigId }) => rigId === parseInt(id[1]) && contractId === parseInt(id[0])
      );
      setRequirementDetails({ details: requirement });
    }
    if (flow === FLOW.OIL_COMPANY) {
      let requirement = customRequirements?.find(
        ({ contractId, operatorId }) =>
          operatorId === parseInt(id[0]) && contractId === parseInt(id[1])
      );
      if (!requirement) {
        requirement = lookupRequirements?.find(
          ({ contractId, operatorId }) =>
            operatorId === parseInt(id[0]) && contractId === parseInt(id[1])
        );
      }
      setRequirementDetails({ details: { ...requirement, ...{ isRequirement: true } } });
    }
  }, [resource, lookupRequirements]);

  const { comment } = requirementDetails?.details || {};
  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={handleClick} className="resource-name">
        {`${resource.name}  `}
        {comment && (
          <ToolTip title={comment}>
            <span style={{ fontSize: '1rem' }}>
              <InsertCommentOutlinedIcon
                // color={isContract ? '#fff' : 'inherit'}
                fontSize={'inherit'}
              />
            </span>
          </ToolTip>
        )}
      </div>
      <RequirementMenu
        flow={flow}
        handleClose={handleClose}
        eventMenuOpen={menuOpen}
        eventMenuItem={requirementDetails}
        mousePosition={mousePosition}
      />
    </>
  );
}

RequirementResourceButton.propTypes = {
  flow: PropTypes.oneOf(Object.values(FLOW)),
  resource: PropTypes.object
};

RequirementResourceButton.defaultProps = { flow: FLOW.OIL_COMPANY, resource: {} };

export default RequirementResourceButton;
