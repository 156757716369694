import moment from 'moment/moment';

export const getUpdatedRequirementDrag = (start, end, details) => {
  let updateRequirement = JSON.parse(JSON.stringify(details));
  const { startDate, duration, latestStartDate } = updateRequirement;

  let newStart = moment(moment(start).format('yyyy-MM-DD'), 'yyyy-MM-DD');
  let newEnd = moment(moment(end).format('yyyy-MM-DD'), 'yyyy-MM-DD');
  let oldStart = moment(moment(startDate, 'yyyy-MM-DD').format('yyyy-MM-DD'));
  let oldLatest = moment(latestStartDate, 'yyyy-MM-DD');
  let oldEnd = moment(oldLatest.add(duration, 'days').format('yyyy-MM-DD'));
  if (newStart.isSame(oldStart, 'day') && !oldEnd.isSame(newEnd)) {
    // Right side event dragged
    updateRequirement.duration = updateRequirement.duration + newEnd.diff(oldEnd, 'days');
  }

  if (!oldStart.isSame(newStart, 'day') && oldEnd.isSame(newEnd, 'day')) {
    // Left side event dragged
    let dateDiff = newStart.diff(oldStart, 'days');
    if (dateDiff < 0) {
      dateDiff = Math.abs(dateDiff);
      updateRequirement.startDate = oldStart.subtract(dateDiff, 'days').format('yyyy-MM-DD');
      updateRequirement.latestStartDate = moment(updateRequirement.latestStartDate, 'yyyy-MM-DD')
        .subtract(dateDiff, 'days')
        .format('yyyy-MM-DD');
      updateRequirement.duration = updateRequirement.duration + dateDiff;
    } else {
      dateDiff = Math.abs(dateDiff);
      updateRequirement.startDate = oldStart.add(dateDiff, 'days').format('yyyy-MM-DD');
      updateRequirement.latestStartDate = updateRequirement.latestStartDate = moment(
        updateRequirement.latestStartDate,
        'yyyy-MM-DD'
      )
        .add(dateDiff, 'days')
        .format('yyyy-MM-DD');
      updateRequirement.duration = updateRequirement.duration - dateDiff;
    }
  }
  return updateRequirement;
};

export const getUpdatedContractDrag = (start, end, details) => {
  let updateContract = JSON.parse(JSON.stringify(details));
  const { startDate, endDate } = updateContract;
  let newStart = moment(moment(start).format('yyyy-MM-DD'), 'yyyy-MM-DD');
  let newEnd = moment(moment(end).format('yyyy-MM-DD'), 'yyyy-MM-DD');
  let oldStart = moment(startDate, 'yyyy-MM-DD');
  let oldEnd = moment(endDate, 'yyyy-MM-DD');

  if (newStart.isSame(oldStart, 'day') && !oldEnd.isSame(newEnd)) {
    // Right side event dragged
    updateContract.duration = updateContract.duration + newEnd.diff(oldEnd, 'days');
    updateContract.endDate = moment(updateContract.startDate)
      .add(updateContract.duration, 'days')
      .format('yyyy-MM-DD');
  }
  if (!oldStart.isSame(newStart, 'day') && oldEnd.isSame(newEnd, 'day')) {
    // Left side event dragged
    let dateDiff = newStart.diff(oldStart, 'days');
    if (dateDiff < 0) {
      dateDiff = Math.abs(dateDiff);
      updateContract.startDate = oldStart.subtract(dateDiff, 'days').format('yyyy-MM-DD');
      updateContract.duration = updateContract.duration + dateDiff;
    } else {
      dateDiff = Math.abs(dateDiff);
      updateContract.startDate = oldStart.add(dateDiff, 'days').format('yyyy-MM-DD');
      updateContract.duration = updateContract.duration - dateDiff;
    }
  }
  return updateContract;
};
