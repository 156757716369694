import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Stack, Switch, Typography } from '@esgian/esgianui';
import LegendItem from '@components/Timeline/TimelineLegend/LegendItem';
import { getTimeLineLegendItems } from '@helpers/helpers';

function TimelineLegend({ showStatus, setShowStatus }) {
  return (
    <>
      <Grid item xs={12}>
        <Stack
          spacing={2}
          direction={'row'}
          divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography variant={'body2'}>SHOW STATUS DETAILS</Typography>
            <Switch
              size={'small'}
              checked={showStatus}
              onChange={() => setShowStatus(!showStatus)}
            />
          </Stack>
          {!showStatus && (
            <Stack direction={'row'} spacing={2} id={'timeline-legend'}>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'body2'}>RIG: </Typography>
                <LegendItem isFixed color={'#7519a9'} title={'Busy'} />
              </Stack>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'body2'}>RIG REQUIREMENT: </Typography>
                <LegendItem color={'#7519a9'} title={'PROPOSED'} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Grid>
      {showStatus && (
        <Grid item xs={12} id={'timeline-legend'}>
          <Stack spacing={1}>
            <Stack direction={'row'} spacing={1}>
              {getTimeLineLegendItems()
                ?.slice(5)
                ?.map(({ color, name, isFixed }) => (
                  <LegendItem
                    isFixed={isFixed}
                    key={`${name.split(' ').join('-').toLowerCase()}`}
                    color={color}
                    title={name}
                  />
                ))}
            </Stack>
            <Stack direction={'row'} spacing={1}>
              {getTimeLineLegendItems()
                ?.slice(0, 5)
                ?.map(({ color, name, isFixed }) => (
                  <LegendItem
                    isFixed={isFixed}
                    key={`${name.split(' ').join('-').toLowerCase()}`}
                    color={color}
                    title={name}
                  />
                ))}
            </Stack>
          </Stack>
        </Grid>
      )}
    </>
  );
}

TimelineLegend.propTypes = {
  showStatus: PropTypes.bool,
  setShowStatus: PropTypes.func.isRequired
};

TimelineLegend.defaultProps = {
  showstatus: false
};

export default TimelineLegend;
