import React from 'react';
import PropTypes from 'prop-types';
import { HelpOutlineIcon, ToolTip, Typography } from '@esgian/esgianui';

function TextWithTooltipIcon({ error, label, tooltipText, textVariant }) {
  return (
    <div style={{ display: 'flex', gap: '0.1em' }}>
      {typeof label === 'string' || label instanceof String ? (
        <Typography color={error ? 'error' : 'text.primary'} variant={textVariant}>
          {label}
          <ToolTip title={tooltipText}>
            <HelpOutlineIcon sx={{ ml: 1, fontSize: '1rem' }} />
          </ToolTip>
        </Typography>
      ) : (
        label
      )}
    </div>
  );
}

TextWithTooltipIcon.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  textVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  error: PropTypes.bool
};

TextWithTooltipIcon.defaultProps = {
  error: false
};

export default TextWithTooltipIcon;
