import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { PROJECT_TYPES } from '@constants';
import { useStore } from '@store/Store';
import moment from 'moment';
import { FLOW, GENERAL_ACTIONS } from '@store/AppReducer';
import { useNavigate } from 'react-router-dom';
import { getRigDemandFilterDefaults } from '@helpers/RigDemandHelpers';

function CreateProjectModal({ handleClose, createOpen }) {
  let navigate = useNavigate();

  const [input, setInput] = useState({ projectName: '', projectType: null });
  const {
    state: { filterLookups, projectsData, user },
    dispatch
  } = useStore();

  const onClose = () => {
    setInput({ projectName: '', projectType: null });
    handleClose();
  };

  const handleCreate = () => {
    let projectId = projectsData.index;

    const {
      profile: { lastName, firstName }
    } = user;
    let newProject = {
      projectName: input.projectName,
      note: null,
      type: input.projectType,
      createdBy: `${firstName} ${lastName}`,
      lastEditBy: `${firstName} ${lastName}`,
      createdDate: moment().format('DD/MM/yyyy'),
      lastEditDate: moment().format('DD/MM/yyyy'),
      rigs: [],
      operators: [],
      filters: { rigDemand: getRigDemandFilterDefaults(filterLookups) },
      eligibleRigs: [],
      customContracts: [],
      comments: [],
      deletedContracts: [],
      customRequirements: [],
      deletedRequirements: []
    };

    dispatch({
      type: GENERAL_ACTIONS.CREATE_PROJECT,
      flow: FLOW.GENERAL,
      payload: { project: newProject }
    });
    setInput({ projectName: '', projectType: null });
    handleClose();
    navigate(`${projectId}`);
  };

  const handleInputChange = (val) => {
    setInput({ ...input, ...val });
  };

  return (
    <Modal
      title={'Create new project'}
      size={'xs'}
      open={createOpen}
      DialogActionsProps={{ sx: { p: 2, justifyContent: 'center', gap: '16px' } }}
      handleClose={onClose}
      actions={[
        <Button withUnderline key={'cancel-create'} color={'secondary'} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          disableElevation
          color={'secondary'}
          disabled={!input.projectType || input.projectName === '' || filterLookups === {}}
          key={'submit-create'}
          variant={'contained'}
          onClick={handleCreate}>
          Create
        </Button>
      ]}>
      <Stack>
        <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
          Project name
        </Typography>
        <TextField
          value={input.projectName}
          variant={'outlined'}
          placeholder={'Project name...'}
          onChange={({ target }) => handleInputChange({ projectName: target.value })}
        />
        <Stack spacing={2} sx={{ p: 2 }}>
          <RadioGroup
            value={input.projectType}
            onChange={({ target }) => handleInputChange({ projectType: parseInt(target.value) })}>
            <FormControlLabel
              value={PROJECT_TYPES.RIG_OPERATOR.id}
              label={
                <Typography variant={'subtitle2'}>
                  Find matching rig requirements for my rigs
                </Typography>
              }
              control={<Radio />}
            />
            <FormControlLabel
              value={PROJECT_TYPES.OIL_COMPANY.id}
              label={
                <Typography variant={'subtitle2'}>
                  Find matching rigs for my rig requirements
                </Typography>
              }
              control={<Radio disabled value={2} />}
            />
          </RadioGroup>
        </Stack>
      </Stack>
    </Modal>
  );
}

CreateProjectModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  createOpen: PropTypes.bool
};

CreateProjectModal.defaultProps = {
  createOpen: false
};

export default CreateProjectModal;
