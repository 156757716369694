import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Datepicker, Input, localeEn } from '@mobiscroll/react';
import { FormHelperText, Stack, Typography } from '@esgian/esgianui';

function PeriodSelect({
  startPlaceholder,
  endPlaceholder,
  rangeStartLabel,
  rangeEndLabel,
  startTitle,
  endTitle,
  onChange,
  value,
  errorStart,
  errorEnd,
  required
}) {
  const [start, startRef] = useState(null);
  const [end, endRef] = useState(null);
  return (
    <Stack sx={{ pb: errorStart || errorEnd ? 2 : 0 }}>
      <Datepicker
        rangeStartLabel={rangeStartLabel}
        rangeEndLabel={rangeEndLabel}
        dateFormat={'DD/MM/YYYY'}
        locale={localeEn}
        select="range"
        startInput={start}
        endInput={end}
        returnFormat={'moment'}
        value={value}
        onChange={onChange}
      />
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
        <Stack justifyContent={'space-between'} sx={{ minWidth: '45%' }}>
          {typeof startTitle === 'string' ? (
            <Typography color={errorEnd ? 'error' : 'text.primary'} variant={'body2'}>
              {`${startTitle} ${required ? '*' : ''}`}
            </Typography>
          ) : (
            startTitle
          )}
          <Input inputStyle={'outline'} ref={startRef} placeholder={startPlaceholder} />
          {!!errorStart && (
            <FormHelperText sx={{ pl: 2 }} error>
              {errorStart}
            </FormHelperText>
          )}
        </Stack>
        <Stack justifyContent={'space-between'} sx={{ minWidth: '45%' }}>
          {typeof endTitle === 'string' ? (
            <Typography color={errorEnd ? 'error' : 'text.primary'} variant={'body2'}>
              {`${endTitle} ${required ? '*' : ''}`}
            </Typography>
          ) : (
            endTitle
          )}
          <Input inputStyle={'outline'} ref={endRef} placeholder={endPlaceholder} />
          {!!errorEnd && (
            <FormHelperText sx={{ pl: 2 }} error>
              {errorEnd}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

PeriodSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  errorStart: PropTypes.string,
  errorEnd: PropTypes.string,
  required: PropTypes.bool,
  startTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  endTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  startPlaceholder: PropTypes.string,
  endPlaceholder: PropTypes.string,
  rangeStartLabel: PropTypes.string,
  rangeEndLabel: PropTypes.string
};

PeriodSelect.defaultProps = {
  value: [],
  errorStart: null,
  errorEnd: null,
  required: false,
  startTitle: 'START DATE',
  endTitle: 'END DATE',
  startPlaceholder: 'Start date...',
  endPlaceholder: 'End date...',
  rangeStartLabel: 'Start',
  rangeEndLabel: 'End'
};

export default PeriodSelect;
