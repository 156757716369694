import { EVENT_TYPES } from '@constants';
import moment from 'moment';

export const getEventObj = (requirement, eligibleRigs) => {
  let requirementIds = eligibleRigs?.map(({ requirementId }) => requirementId);

  let resource = requirement.operatorId;
  let level = 1;
  if (requirementIds?.includes(requirement?.contractId)) {
    resource = `${requirement.operatorId},${requirement.contractId}`;
    level = 2;
  }

  let latestStartDate = requirement.latestStartDate || requirement.startDate;
  return {
    details: {
      ...requirement,
      ...{
        latestStartDate: latestStartDate,
        isRequirement: true
      }
    },
    start: requirement.startDate,
    level: level,
    end: moment(latestStartDate).add(requirement.duration, 'days'),
    resource: resource,
    title: requirement.projectName || 'Requirement',
    type: EVENT_TYPES.REQUIREMENT,
    taskType: requirement.contractStatusId
  };
};

const getEligibleEventObject = (eventType, eventData, rigType, operatorId, requirementId) => {
  let title = eventType === EVENT_TYPES.CONTRACT ? eventData?.projectName : eventData?.rigStatus;
  let resourceId = `${operatorId},${requirementId},${eventData.rigId}`;
  if (!title) {
    title = eventType;
  }

  return {
    details: {
      ...eventData,
      ...{ isRequirement: false, rigType: rigType }
    },
    start: eventData.startDate,
    level: 3,
    end: eventData.endDate,
    resource: resourceId,
    editable: true,
    title: title,
    type: eventType,
    taskType:
      eventType === EVENT_TYPES.CONTRACT ? eventData.contractStatusId : eventData.rigStatusId
  };
};

export const getEligibleRigsEvents = (
  eligibleRigs,
  lookupRigs,
  comments,
  customContracts,
  deletedContracts
) => {
  let eligibleEvents = [];
  eligibleRigs.forEach(({ operatorId, requirementId, rigs }) => {
    for (let rig of rigs) {
      let foundRig = lookupRigs.find(({ rigId }) => rigId === rig.rigId);

      customContracts
        ?.filter((event) => event.rigId === rig.rigId)
        ?.map((contract) => {
          eligibleEvents.push(
            getEligibleEventObject(
              EVENT_TYPES.CONTRACT,
              contract,
              foundRig.rigType,
              operatorId,
              requirementId
            )
          );
        });
      let customIds = customContracts?.map(({ contractId }) => contractId);
      let deletedIds = deletedContracts?.map(({ contractId }) => contractId);
      let excludedIds = [...customIds, ...deletedIds];
      foundRig?.contracts
        ?.filter((event) => !excludedIds.includes(event.contractId))
        ?.forEach((contract) => {
          eligibleEvents.push(
            getEligibleEventObject(
              EVENT_TYPES.CONTRACT,
              contract,
              foundRig.rigType,
              operatorId,
              requirementId
            )
          );
        });
      foundRig?.activities?.forEach((contract) => {
        eligibleEvents.push(
          getEligibleEventObject(
            EVENT_TYPES.ACTIVITY,
            contract,
            foundRig.rigType,
            operatorId,
            requirementId,
            comments
          )
        );
      });
    }
  });
  return eligibleEvents;
};
