import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CONTRACT_STATUSES } from '@constants';
import { Badge, InsertCommentOutlinedIcon, Stack, Typography } from '@esgian/esgianui';
import { getNumberString } from '@helpers/helpers';
import moment from 'moment';
import { Draggable } from '@mobiscroll/react';
import { ContractTooltip } from '@components/Timeline';

function ContractEvent({ event, chartSettings, showStatus }) {
  const [tooltipAnchor, setTooltipAnchor] = useState(null);
  const [myEl, setMyEl] = useState();
  const {
    dayRate: showDayRate,
    country: showCountry,
    region: showRegion,
    contractType: showContractType,
    duration: showDuration,
    rigType: showType
  } = chartSettings;
  const original = { ...event.original };
  const taskType = original.taskType - 1;
  const statusObj = CONTRACT_STATUSES[taskType];
  let color = statusObj?.color;
  if (!showStatus) {
    color = '#7519a9';
  }
  let title =
    original.title === '' || original.title === null
      ? `${original.details.operator} | ${original.details.country}`
      : `${original.details.operator} | ${event.title}`;

  let dragData = {
    title: original.title,
    start: event.original.start,
    end: event.original.end,
    type: original.type,
    prevResource: original.resource,
    details: original.details,
    taskType: original.taskType
  };

  let geoString = null;
  if (showRegion && showCountry) {
    geoString = `${original.details.country}, ${original.details.region}`;
  } else if (showCountry) {
    geoString = original.details.country;
  } else if (showRegion) {
    geoString = original.details.region;
  }
  const { dayrate, contractStatus, comment, isCustom } = original?.details || {};

  let background = '';
  let textColor = 'rgba(0, 0, 0, 0.87)';
  if ((statusObj.id === 6 && showStatus) || (!showStatus && !original.details.isRequirement)) {
    textColor = '#fff';
  }

  if (!showStatus && original.details.isRequirement) {
    background = 'rgb(255 255 255 / 80%)';
  }

  if (showStatus && !statusObj.isFixed) {
    background = 'rgb(255 255 255 / 80%)';
  }

  const onMouseEnter = (event) => {
    setTooltipAnchor(event.currentTarget);
  };
  const onMouseLeave = () => {
    setTooltipAnchor(null);
  };

  let isOriginalRequirement = original.details.originalRequirement;
  return (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
          // cursor: eventDraggable ? 'pointer' : 'default',
          display: 'block',
          color: color,
          backgroundColor: isOriginalRequirement ? `${color}9e` : color,
          borderRadius: '10px',
          margin: '0px 0 0px 0'
        }}
        ref={setMyEl}>
        <div
          style={{
            padding: '4px',
            borderRadius: '9px',
            background: background
          }}>
          <Stack spacing={0.5} sx={{ pl: 1 }}>
            <Badge
              sx={{
                top: ' -0.2em',
                left: 'calc(100% - 0.1em)'
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              invisible={!isCustom}
              variant="dot"
              color="warning"
            />
            <div
              className={'mbsc-schedule-event-title mbsc-material'}
              style={{
                minHeight: '24px',
                fontSize: '0.85rem',
                color: textColor
              }}>
              {title}
              {comment && (
                <span style={{ fontSize: '1rem', color: '#fff', paddingLeft: '5px' }}>
                  <InsertCommentOutlinedIcon color={'inherit'} fontSize={'inherit'} />
                </span>
              )}
              {showDayRate && !!dayrate && (
                <span
                  style={{
                    fontSize: '11px',
                    border: '1px solid #fff',
                    color: textColor,
                    borderRadius: '5px',
                    margin: '0 10px',
                    padding: ' 0px 5px'
                  }}>
                  ${getNumberString(dayrate)}
                </span>
              )}
              <br />
            </div>

            {(showRegion || showCountry) && geoString && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {geoString}
              </Typography>
            )}

            {showContractType && contractStatus && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {contractStatus}
              </Typography>
            )}
            {showType && (
              <Typography
                variant={'caption'}
                sx={{
                  visibility: 'hidden'
                }}>
                {'n/a'}
              </Typography>
            )}
            {showDuration && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {moment(event.start).format('DD/MM/yyyy')}-{moment(event.end).format('DD/MM/yyyy')}
              </Typography>
            )}
          </Stack>
        </div>
        <Draggable touchUi={true} dragData={dragData} element={myEl} />
      </div>
      {!!tooltipAnchor && (
        <ContractTooltip details={event?.original?.details} anchor={tooltipAnchor} />
      )}
    </>
  );
}

ContractEvent.propTypes = {
  event: PropTypes.object,
  chartSettings: PropTypes.object,
  showStatus: PropTypes.bool
};

ContractEvent.defaultProps = {
  event: {},
  chartSettings: {},
  showStatus: false
};

export default ContractEvent;
