import React, { useMemo, useState } from 'react';
import { FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { Alert, Button, CircularProgress, Grid, Modal, Stack, Typography } from '@esgian/esgianui';
import RigSelect from '@components/Inputs/RigSelect/RigSelect';
import PropTypes from 'prop-types';
import { getProject } from '@helpers/helpers';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';
import { toast } from '@mobiscroll/react';

function AddToRigSupplyModal({ event, handleClose }) {
  const [loading, setLoading] = useState(null);
  const [selectedRig, setSelectedRig] = useState(null);
  const params = useParams();
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();

  const rigs = useMemo(() => {
    const { rigs } = getProject(projects, params.projectId);
    return rigs;
  }, [projects, params.projectId]);

  const typeMismatch = useMemo(() => {
    if (!selectedRig) return false;
    const types = event.rigTypes.map(({ name }) => name);
    return !types.includes(selectedRig.rigType);
  }, [selectedRig, event]);

  const handleSelect = (id) => {
    let rig = rigs.find(({ rigId }) => rigId === id);
    setSelectedRig(rig);
  };

  const handleSave = () => {
    dispatch({
      type: RIG_SUPPLIER_ACTIONS.CREATE_REQUIREMENT,
      flow: FLOW.RIG_SUPPLIER,
      payload: {
        requirement: { ...event, rigId: selectedRig.rigId },
        projectId: parseInt(params.projectId)
      }
    });
    handleClose();
  };
  return (
    <Modal
      size={'sm'}
      handleClose={handleClose}
      title={`Add to rig supply`}
      open
      actions={[
        <Button
          disableElevation
          onClick={handleClose}
          color={'secondary'}
          key={'cancel-changes-module'}>
          Cancel
        </Button>,
        <Button
          disabled={!selectedRig}
          color={'secondary'}
          disableElevation
          onClick={handleSave}
          key={'apply-changes'}
          variant={'contained'}>
          save
        </Button>
      ]}>
      <Grid container sx={{ minHeight: '30vh' }}>
        {!loading && (
          <Grid item xs={12}>
            <Stack spacing={2}>
              {typeMismatch && (
                <Alert severity={'info'}>
                  <Stack>
                    <Typography>
                      The selected rig does not match the rig type of the requirement
                    </Typography>
                    <Typography variant={'caption'}>
                      {`• Requirement: ${event?.rigTypes.map(({ name }) => name)?.toString()}`}
                    </Typography>
                    <Typography variant={'caption'}>
                      {`• Selected rig: ${selectedRig.rigType}`}
                    </Typography>
                  </Stack>
                </Alert>
              )}
              <Stack>
                <Typography color={'text.primary'} variant={'body2'}>
                  RIG*
                </Typography>
                <RigSelect
                  flow={FLOW.RIG_SUPPLIER}
                  handleChange={(val) => handleSelect(val)}
                  selectedRig={selectedRig?.rigId ?? ''}
                />
              </Stack>
            </Stack>
          </Grid>
        )}
        {loading && (
          <Grid item xs={12} sx={{ alignSelf: 'center', textAlign: 'center' }}>
            <CircularProgress size={'6em'} />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}

AddToRigSupplyModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  event: PropTypes.object
};

AddToRigSupplyModal.defaultProps = {
  event: {}
};

export default AddToRigSupplyModal;
