import moment from 'moment';

export const getRequirementFilterDefaults = (lookups) => {
  const { hookLoad: lookupHookLoad = [], waterDepth: lookupWaterDepth = [] } = lookups || {};

  return {
    managers: { type: 'multiselect', value: [] },
    rigTypes: { type: 'multiselect', value: [] },
    owners: { type: 'multiselect', value: [] },
    countries: { type: 'multiselect', value: [] },
    regions: { type: 'multiselect', value: [] },
    design: { type: 'multiselect', value: [] },
    operatingArea: { type: 'multiselect', value: [] },
    managedPressureDrilling: { type: 'bool', value: null },
    designWaterDepth: { type: 'range', min: lookupWaterDepth[0], max: lookupWaterDepth[1] },
    hookLoad: { type: 'range', min: lookupHookLoad[0], max: lookupHookLoad[1] },
    bopRating: { type: 'multiselect', value: [] },
    yearInService: { type: 'range', min: 1968, max: moment().year() }
  };
};

export const requirementFilterToRigKey = {
  managers: 'rigManagerId',
  rigTypes: 'rigTypeId',
  owners: 'rigOwnerId',
  countries: 'countryId',
  regions: 'regionId',
  hookLoad: 'hookLoad',
  bopRating: 'bopMaxPsi',
  design: 'rigDesignCategoryId',
  designWaterDepth: 'designWaterDepth',
  yearInService: 'yearInService',
  managedPressureDrilling: 'managedPressureDrilling',
  operatingArea: 'rigOperatingAreaId'
};
