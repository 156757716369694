import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, SaveOutlinedIcon } from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { getProject } from '@helpers/helpers';
import { APP_VERSION } from '@constants';

function SaveToFileButton({ projectId }) {
  const inputFile = useRef(null);
  const { state } = useStore();
  const {
    state: { projectsData }
  } = useStore();

  const handleExport = useCallback(() => {
    // inputFile.current.click();
    let filename = 'rig-planner.json';
    let fileData = {
      fullProject: true,
      version: APP_VERSION,
      data: {
        ...projectsData
      }
    };
    if (projectId) {
      const project = getProject(projectsData.projects, projectId);
      filename = `${project.projectName.split(' ').join('-')}.json`;
      fileData = {
        fullProject: false,
        version: APP_VERSION,
        data: { ...project }
      };
    }

    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }, [JSON.stringify(state)]);

  return (
    <>
      <input ref={inputFile} hidden type={'file'} accept={'application/json'} />
      <Button
        variant={'text'}
        startIcon={<SaveOutlinedIcon />}
        sx={{ color: '#000000' }}
        onClick={handleExport}>
        Save
      </Button>
    </>
  );
}

SaveToFileButton.propTypes = {
  projectId: PropTypes.number
};

SaveToFileButton.defaultProps = {
  projectId: null
};

export default SaveToFileButton;
