import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon, Divider, Drawer, Grid, IconButton, Stack, Typography } from '@esgian/esgianui';
import RigInfoValueDisplay from '@components/Display/RigInfoValueDisplay/RigInfoValueDisplay';
import moment from 'moment';
import { getNumberString } from '@helpers/helpers';

function EventDetailsDrawer({ open, event, handleClose }) {
  const {
    isRequirement,
    operator,
    country,
    region,
    contractStatus,
    startDate,
    latestStartDate,
    endDate,
    harshEnvironment,
    dayrate,
    nrOfWells,
    rigTypes,
    projectName,
    alternativeRigType,
    waterDepth,
    outfittedWaterDepth,
    managedPressureDrilling,
    hpht,
    cantileverReach,
    dpClass,
    bopMaxPsi,
    nrOfBops,
    duration,
    nrOfStackRams,
    mooringCapability,
    hookLoad,
    variableDeckLoad,
    accommodation,
    highSpec,
    dateFlexibility,
    winterized,
    comment
  } = event || {};
  let showTech =
    waterDepth ||
    outfittedWaterDepth ||
    managedPressureDrilling ||
    hpht ||
    cantileverReach ||
    dpClass ||
    bopMaxPsi ||
    nrOfBops ||
    nrOfStackRams ||
    mooringCapability ||
    hookLoad ||
    variableDeckLoad ||
    accommodation ||
    highSpec ||
    winterized;

  let latestStartDateDisplay = latestStartDate ? latestStartDate : startDate;
  return (
    <Drawer open={open} onClose={handleClose} anchor={'right'}>
      <Grid
        container
        sx={{
          width: '25vw',
          maxHeight: '100%'
        }}>
        <Grid sx={{ height: '57px' }} item xs={12}>
          <Grid justifyContent={'space-between'} container>
            <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <Typography variant={'h6'} color={'text.primary'}>
                {isRequirement ? 'Requirement' : 'Contract'} details
              </Typography>
            </Grid>
            <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <Typography gutterBottom variant={'h6'}>
                General
              </Typography>
              <RigInfoValueDisplay title={'Operator'} value={operator} />
              {isRequirement && (
                <RigInfoValueDisplay
                  title={'Rig Types'}
                  value={rigTypes?.map(({ name }) => name)?.join(', ')}
                />
              )}
            </Stack>
            <Divider />
            <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <Typography gutterBottom variant={'h6'}>
                Contract
              </Typography>
              <RigInfoValueDisplay title={'Project name'} value={projectName} />
              <RigInfoValueDisplay title={'Contract status'} value={contractStatus} />
              <RigInfoValueDisplay
                title={isRequirement ? 'earliest start date' : 'Start date'}
                value={moment(startDate, 'yyyy-MM-DD')?.format('DD/MM/yyyy')}
              />
              <RigInfoValueDisplay
                title={isRequirement ? 'Latest start date' : 'End date'}
                value={moment(
                  isRequirement ? latestStartDateDisplay : endDate,
                  'yyyy-MM-DD'
                )?.format('DD/MM/yyyy')}
              />
              <RigInfoValueDisplay title={'duration'} value={duration} />
              <RigInfoValueDisplay title={'date flexibility'} value={dateFlexibility} />
              <RigInfoValueDisplay title={'Nr. of wells'} value={nrOfWells} />
              {!isRequirement && (
                <RigInfoValueDisplay
                  title={'Day rate'}
                  value={`$${getNumberString(dayrate || 0)}`}
                />
              )}
            </Stack>
            <Divider />
            <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <Typography gutterBottom variant={'h6'}>
                Geography
              </Typography>
              <RigInfoValueDisplay title={'Country'} value={country} />
              <RigInfoValueDisplay title={'Region'} value={region} />
            </Stack>
            {showTech && <Divider />}
            {showTech && (
              <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
                <Typography gutterBottom variant={'h6'}>
                  Technical requirements
                </Typography>
                <RigInfoValueDisplay
                  title={'WATER DEPTH AT DRILLING SITE (ft)'}
                  value={waterDepth ? `${waterDepth} ft` : null}
                />
                <RigInfoValueDisplay
                  title={'MANAGED PRESSURE DRILLING(mpd ready)'}
                  value={managedPressureDrilling ? 'yes' : 'no'}
                />
                <RigInfoValueDisplay title={'HPHT'} value={hpht ? 'yes' : 'no'} />
                <RigInfoValueDisplay title={'DP-CLASS'} value={dpClass} />
                <RigInfoValueDisplay
                  title={'BOP PRESSURE RATING (PSI)'}
                  value={bopMaxPsi ? `${bopMaxPsi} PSI` : 'Unknown'}
                />
                <RigInfoValueDisplay title={'NUMBER OF BOP STACK'} value={nrOfStackRams} />
                <RigInfoValueDisplay
                  title={'CANTILEVER REACH (ft)'}
                  value={cantileverReach ? `${cantileverReach} ft` : null}
                />
                <RigInfoValueDisplay
                  title={'MOORING CAPABILITY'}
                  value={mooringCapability ? 'yes' : 'no'}
                />
                <RigInfoValueDisplay
                  title={'HOOK LOAD (lbs)'}
                  value={hookLoad ? `${hookLoad} lbs` : null}
                />

                <RigInfoValueDisplay
                  title={'VARIABLE DECK LOAD (lbs/sqft)'}
                  value={variableDeckLoad ? `${variableDeckLoad} lbs/sqft` : null}
                />
                <RigInfoValueDisplay title={'HIGH SPECIFICATION'} value={highSpec ? 'yes' : 'no'} />
                <RigInfoValueDisplay title={'ACCOMMODATION (POB)'} value={accommodation} />
                <RigInfoValueDisplay title={'WINTERIZED'} value={winterized ? 'yes' : 'no'} />
                <RigInfoValueDisplay
                  title={'Harsh environment'}
                  value={harshEnvironment ? 'yes' : 'no'}
                />
              </Stack>
            )}
            {comment && <Divider />}
            {comment && (
              <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
                <Typography gutterBottom variant={'h6'}>
                  Comment
                </Typography>
                <Typography gutterBottom variant={'body2'}>
                  {comment}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}

EventDetailsDrawer.propTypes = {
  open: PropTypes.bool,
  event: PropTypes.object,
  handleClose: PropTypes.func.isRequired
};

EventDetailsDrawer.defaultProps = {
  open: false,
  event: null
};

export default EventDetailsDrawer;
