import React from 'react';
import PropTypes from 'prop-types';
import { DeleteModal, Grid, Stack, Typography } from '@esgian/esgianui';

function ConfirmRemoveModal({ open, handleDeleteClick, handleClose }) {
  return (
    <DeleteModal
      PaperProps={{ sx: { maxWidth: 'fit-content' } }}
      handleDeleteClick={handleDeleteClick}
      onClose={handleClose}
      open={open}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2} alignItems={'center'}>
            <Typography variant={'h4'}>Are you sure?</Typography>
            <Typography variant={'body2'}>This action can not be undone</Typography>
          </Stack>
        </Grid>
      </Grid>
    </DeleteModal>
  );
}

ConfirmRemoveModal.propTypes = {
  open: PropTypes.bool,
  handleDeleteClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

ConfirmRemoveModal.defaultProps = {
  open: false
};

export default ConfirmRemoveModal;
