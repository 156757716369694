import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Paper, RegionIcon, RigIcon, Stack, Typography } from '@esgian/esgianui';
import { ACTIVITY_STATUSES } from '@constants';

function RigDisplayBox({
  handleRigClick,
  disabled,
  rigId,
  rigName,
  rigType,
  region,
  rigStatus,
  rigStatusId,
  rigTypeId,
  selectedRigs
}) {
  return (
    <Paper
      key={`${rigId}-select`}
      variant={'outlined'}
      onClick={() => {
        if (!disabled) {
          handleRigClick({
            rigId: rigId,
            rigName: rigName,
            rigType: rigType,
            rigTypeId: rigTypeId
          });
        }
      }}
      sx={{
        cursor: disabled ? 'default' : 'pointer',
        p: 2,
        background: (theme) => {
          if (!disabled) {
            return selectedRigs?.map(({ rigId: selectedRigId }) => selectedRigId).includes(rigId)
              ? `${theme.palette.primary.light}4d`
              : theme.palette.background.paper;
          }
          return theme.palette.common.lightGray;
        }
      }}>
      <Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack>
            <Typography
              color={disabled ? 'text.secondary' : 'text.primary'}
              bold
              variant={'subtitle1'}>
              {rigName}
            </Typography>
            <Stack spacing={0.5} sx={{ pt: 0.5 }}>
              <Stack direction={'row'} spacing={0.5}>
                <RigIcon
                  sx={{ fontSize: '0.9rem', color: (theme) => theme.palette.text.secondary }}
                />
                <Typography color={'text.secondary'} variant={'caption'}>
                  {rigType}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={0.5}>
                <RegionIcon
                  sx={{ fontSize: '0.9rem', color: (theme) => theme.palette.text.secondary }}
                />
                <Typography variant={'caption'} color={'text.secondary'}>
                  {region}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Chip
            size={'small'}
            label={rigStatus}
            sx={{
              color: '#fff',
              background: ACTIVITY_STATUSES[rigStatusId - 1].color
            }}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

RigDisplayBox.propTypes = {
  handleRigClick: PropTypes.func.isRequired,
  rigId: PropTypes.number.isRequired,
  rigName: PropTypes.string.isRequired,
  rigType: PropTypes.string,
  disabled: PropTypes.bool,
  region: PropTypes.string,
  rigStatus: PropTypes.string.isRequired,
  rigStatusId: PropTypes.number.isRequired,
  selectedRigs: PropTypes.arrayOf(PropTypes.object)
};

RigDisplayBox.defaultProps = {
  disabled: false,
  rigType: '',
  region: '',
  selectedRigs: []
};

export default RigDisplayBox;
