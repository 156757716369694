import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { PROJECT_TYPES } from '@constants';
import { getProject } from '@helpers/helpers';
import { useStore } from '@store/Store';
import { FLOW, GENERAL_ACTIONS } from '@store/AppReducer';

function EditProjectModal({ open, onClose, projectId }) {
  const [input, setInput] = useState({ projectName: '', note: '' });
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const handleInputChange = useCallback(
    (val) => {
      setInput({ ...input, ...val });
    },
    [input]
  );
  const handleSave = useCallback(() => {
    if (input.projectName === '') return;
    dispatch({
      type: GENERAL_ACTIONS.UPDATE_PROJECT,
      flow: FLOW.GENERAL,
      payload: { projectId: projectId, projectName: input.projectName, note: input.note }
    });
    onClose();
  }, [input, projectId]);

  useEffect(() => {
    if (!projectId) return;
    const { note, projectName } = getProject(projects, parseInt(projectId));
    setInput({ note: note || '', projectName: projectName });
  }, [projectId]);

  return (
    <Modal
      title={'Update project'}
      size={'sm'}
      open={open}
      DialogActionsProps={{ sx: { justifyContent: 'center', gap: '16px' } }}
      handleClose={onClose}
      actions={[
        <Button withUnderline key={'cancel-create'} color={'secondary'} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          disableElevation
          color={'secondary'}
          disabled={input.projectName === ''}
          key={'submit-create'}
          variant={'contained'}
          onClick={handleSave}>
          save
        </Button>
      ]}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
            Project name*
          </Typography>
          <TextField
            value={input.projectName}
            variant={'outlined'}
            placeholder={'Project name...'}
            onChange={({ target }) => handleInputChange({ projectName: target.value })}
          />
        </Stack>
        <Stack>
          <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
            Note
          </Typography>
          <TextField
            minRows={3}
            multiline
            value={input.note}
            variant={'outlined'}
            placeholder={'Note...'}
            onChange={({ target }) => handleInputChange({ note: target.value })}
          />
        </Stack>
      </Stack>
    </Modal>
  );
}

EditProjectModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.number
};

EditProjectModal.defaultProps = {
  open: false,
  projectId: null
};

export default EditProjectModal;
