import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemText, Paper, Popover } from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { AddEditCommentModal, AddEditRequirementModal } from '@components/Modals';
import { EventDetailsDrawer } from '@components/Drawers';
import RequirementEligibleRigsDrawer from '@components/Drawers/RequirementEligibleRigsDrawer';
import { FLOW, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { snackbar } from '@mobiscroll/react';
import AssignRequirementModal from '@components/Modals/AssignRequirementModal';
import { getProject } from '@helpers/helpers';
import AddToRigSupplyModal from '@components/Modals/AddToRigSupplyModal/AddToRigSupplyModal';

function RequirementMenu({
  flow,
  isRigDemand,
  mousePosition,
  eventMenuOpen,
  handleClose,
  eventMenuItem
}) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [requirementToSupplyOpen, setRequirementToSupplyOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [eligibleDrawerOpen, setEligibleDrawerOpen] = useState(false);
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const { contractsWithoutRigs: lookupRequirements } = filterLookups;
  const projectId = parseInt(useParams().projectId);

  const isManualOriginal = useMemo(() => {
    return flow === FLOW.RIG_SUPPLIER && isRigDemand;
  }, [flow, isRigDemand]);

  const handleRemoveClick = useCallback(() => {
    if (!eventMenuItem.details) return;
    const { operatorId, contractId } = eventMenuItem.details;
    let type = OIL_COMPANY_ACTIONS.REMOVE_REQUIREMENT;
    let payload = {
      projectId: projectId,
      operatorId: operatorId,
      requirementId: contractId
    };

    if (flow === FLOW.RIG_SUPPLIER) {
      type = RIG_SUPPLIER_ACTIONS.REMOVE_REQUIREMENT;
      payload = {
        projectId: projectId,
        requirementId: eventMenuItem.details?.contractId,
        rigId: eventMenuItem.details?.rigId
      };
    }
    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: type,
          flow: flow,
          payload: payload
        });
        snackbar({
          message: `Requirement removed`,
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });
    handleClose();
  }, [flow, eventMenuItem, projectId]);

  const handleApplyComment = useCallback(
    (comment) => {
      let type = OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT;
      let payload = {
        projectId: projectId,
        requirement: { ...eventMenuItem.details, comment: comment }
      };
      if (flow === FLOW.RIG_SUPPLIER) {
        type = RIG_SUPPLIER_ACTIONS.UPDATE_REQUIREMENT;
        payload = {
          projectId: projectId,
          requirement: eventMenuItem?.details,
          newFields: { comment: comment, isCustom: !isManualOriginal }
        };
      }

      dispatch({
        type: type,
        flow: flow,
        payload: payload
      });
      let isUpdate = !!eventMenuItem?.details?.comment;

      snackbar({
        message: `Comment ${isUpdate ? 'updated' : 'added'}`,
        color: 'success',
        duration: 5000,
        display: 'top'
      });

      setCommentModalOpen(false);
    },
    [eventMenuItem, flow, projectId]
  );

  const handleResetClick = useCallback(() => {
    let type = OIL_COMPANY_ACTIONS.RESET_REQUIREMENT;
    let payload = { projectId: projectId, requirementId: eventMenuItem?.details?.contractId };

    if (flow === FLOW.RIG_SUPPLIER) {
      if (!lookupRequirements?.length) return;
      let original = null;

      if (eventMenuItem?.details.isManualCreate) {
        const { customRequirements } = getProject(projects, projectId);
        original = customRequirements?.find(
          ({ contractId, rigId }) =>
            rigId === null && contractId === eventMenuItem?.details?.contractId
        );
      } else {
        original = lookupRequirements?.find(
          ({ contractId }) => contractId === eventMenuItem?.details?.contractId
        );
      }

      type = RIG_SUPPLIER_ACTIONS.UPDATE_REQUIREMENT;
      payload = {
        requirement: {
          contractId: eventMenuItem?.details.contractId,
          rigId: eventMenuItem?.details?.rigId
        },
        projectId: projectId,
        newFields: { ...original, rigId: eventMenuItem?.details?.rigId, isRequirement: true }
      };
    }

    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: type,
          flow: flow,
          payload: payload
        });
        snackbar({
          message: `Requirement reset`,
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });

    handleClose();
  }, [eventMenuItem, lookupRequirements, flow]);

  return (
    <>
      {eventMenuOpen && (
        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{ top: mousePosition?.y || 0, left: mousePosition?.x || 0 }}
          open={eventMenuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <Paper>
            <List>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setInfoDrawerOpen(true);
                }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={`View requirement info`}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setAssignModalOpen(true);
                }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={`Assign to rig`}
                />
              </ListItemButton>
              {flow === FLOW.RIG_SUPPLIER && (
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    setRequirementToSupplyOpen(true);
                  }}>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={`Add to rig supply`}
                  />
                </ListItemButton>
              )}
              {!isRigDemand && flow === FLOW.OIL_COMPANY && (
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    setEligibleDrawerOpen(true);
                  }}>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={'Add/edit eligible rigs'}
                  />
                </ListItemButton>
              )}
              {(!isRigDemand || eventMenuItem.details.isManualCreate) && (
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    setEditModalOpen(true);
                  }}>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={'Edit requirement'}
                  />
                </ListItemButton>
              )}
              {eventMenuItem?.details?.isCustom && !isRigDemand && (
                <ListItemButton onClick={handleResetClick}>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={'Reset'} />
                </ListItemButton>
              )}
              {(!isRigDemand || eventMenuItem.details.isManualCreate) && (
                <ListItemButton onClick={handleRemoveClick}>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={'Remove'} />
                </ListItemButton>
              )}
              {(!isRigDemand || eventMenuItem.details.isManualCreate) && (
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    setCommentModalOpen(true);
                  }}>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={`${eventMenuItem?.details?.comment ? 'Edit' : 'Add'} comment`}
                  />
                </ListItemButton>
              )}
            </List>
          </Paper>
        </Popover>
      )}
      {commentModalOpen && (
        <AddEditCommentModal
          handleApplyClick={handleApplyComment}
          open={commentModalOpen}
          comment={eventMenuItem?.details?.comment || null}
          handleClose={() => setCommentModalOpen(false)}
        />
      )}
      {editModalOpen && (
        <AddEditRequirementModal
          isManualOriginal={isManualOriginal}
          flow={flow}
          handleClose={() => setEditModalOpen(false)}
          open={editModalOpen}
          requirement={eventMenuItem?.details}
        />
      )}
      {infoDrawerOpen && (
        <EventDetailsDrawer
          handleClose={() => setInfoDrawerOpen(false)}
          open={infoDrawerOpen}
          event={eventMenuItem?.details}
        />
      )}
      {eligibleDrawerOpen && (
        <RequirementEligibleRigsDrawer
          flow={flow}
          open={true}
          contract={eventMenuItem?.details}
          handleClose={() => setEligibleDrawerOpen(false)}
        />
      )}
      {requirementToSupplyOpen && (
        <AddToRigSupplyModal
          event={eventMenuItem?.details}
          open
          handleClose={() => setRequirementToSupplyOpen(false)}
        />
      )}
      {assignModalOpen && (
        <AssignRequirementModal
          disableResourceRig={flow === FLOW.OIL_COMPANY}
          projectId={projectId}
          requirementEvent={eventMenuItem}
          flow={flow}
          open
          handleClose={() => setAssignModalOpen(false)}
        />
      )}
    </>
  );
}

RequirementMenu.propTypes = {
  mousePosition: PropTypes.object,
  eventMenuItem: PropTypes.object,
  eventMenuOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isRigDemand: PropTypes.bool,
  flow: PropTypes.oneOf(Object.values(FLOW))
};

RequirementMenu.defaultProps = {
  mousePosition: {},
  eventMenuItem: null,
  eventMenuOpen: false,
  isRigDemand: false,
  flow: FLOW.OIL_COMPANY
};

export default RequirementMenu;
