import React from 'react';
import PropTypes from 'prop-types';
import { Box, FilterSlider, Stack, Typography } from '@esgian/esgianui';
import YesNoSelect from '@components/Inputs/YesNoSelect/YesNoSelect';
import { LookupSelect } from '@components/Inputs';
import { useStore } from '@store/Store';
import { getLookupMarks, getValueObj } from '@helpers/helpers';
import FilterSelect from '../../../../Inputs/FilterSelect';

function ContractTechRequirements({ handleValueChanges, tempState }) {
  const {
    waterDepth,
    managedPressureDrilling,
    hpht,
    dpId,
    dpClass,
    bopMaxPsi,
    nrOfBops,
    cantileverReach,
    nrOfStackRams,
    mooringCapability,
    hookLoad,
    variableDeckLoad,
    accommodation,
    highSpec,
    winterized
  } = tempState;

  const {
    state: { filterLookups }
  } = useStore();

  const {
    accommodation: lookupAccommodation = [],
    dpClass: lookupDpClass = [],
    numberOfBops: lookupNumberOfBops = [],
    bopPressureRating: lookupBopPressureRating = [],
    numberOfBopStackRams: lookupNumBopStackRams = [],
    cantileverReach: lookupCantileverReach = [],
    hookLoad: lookupHookLoad = [],
    waterDepth: lookupWaterDepth = [],
    variableDeckLoad: lookupVariableDeckLoad = []
  } = filterLookups || {};

  return (
    <Stack spacing={2}>
      <Typography bold>Technical requirements</Typography>
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'WATER DEPTH [ft]'}
        value={waterDepth || lookupWaterDepth[lookupWaterDepth.length - 1]}
        max={lookupWaterDepth[1]}
        min={lookupWaterDepth[0]}
        onChange={(val) => handleValueChanges({ waterDepth: parseInt(val) || 0 })}
      />
      <Stack>
        <Typography variant={'body2'}>MANAGED PRESSURE DRILLING</Typography>
        <YesNoSelect
          value={managedPressureDrilling}
          handleChange={(val) => handleValueChanges({ managedPressureDrilling: val })}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>HPHT</Typography>
        <YesNoSelect value={hpht} handleChange={(val) => handleValueChanges({ hpht: val })} />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>DP-CLASS</Typography>
        <LookupSelect
          id={'contract-region-select'}
          handleChange={(_, val) =>
            handleValueChanges({
              dpId: parseInt(val.id),
              dpClass: val.name
            })
          }
          value={getValueObj(dpId, dpClass)}
          options={lookupDpClass}
        />
      </Stack>

      <Stack>
        <Typography variant={'body2'}>BOP PRESSURE RATING (PSI)</Typography>
        <FilterSelect
          hideEmpty
          handleChange={(val) => handleValueChanges({ bopMaxPsi: val })}
          value={bopMaxPsi || null}
          options={lookupBopPressureRating}
        />
      </Stack>
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'NUMBER OF BOP STACK'}
        value={nrOfStackRams || lookupNumBopStackRams[lookupNumBopStackRams.length - 1]}
        min={0}
        max={lookupNumBopStackRams[lookupNumBopStackRams.length - 1] || 10}
        step={1}
        onChange={(val) => handleValueChanges({ nrOfStackRams: parseInt(val) || 0 })}
      />
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'NUMBER OF BOPS'}
        value={
          nrOfBops || lookupNumberOfBops[0] || lookupNumberOfBops[lookupNumberOfBops.length - 1]
        }
        min={0}
        max={lookupNumberOfBops[lookupNumberOfBops.length - 1] || 3}
        step={1}
        onChange={(val) => handleValueChanges({ nrOfBops: parseInt(val) || 0 })}
      />
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'CANTILEVER REACH (ft)'}
        value={cantileverReach || lookupCantileverReach[lookupCantileverReach.length - 1]}
        min={0}
        max={lookupCantileverReach[lookupCantileverReach.length - 1] || 3}
        step={5}
        onChange={(val) => handleValueChanges({ cantileverReach: parseInt(val) || 0 })}
      />
      <Stack>
        <Typography variant={'body2'}>MOORING CAPABILITY</Typography>
        <YesNoSelect
          value={mooringCapability}
          handleChange={(val) => handleValueChanges({ mooringCapability: val })}
        />
      </Stack>
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'HOOK LOAD (lbs)'}
        value={hookLoad || lookupHookLoad[lookupHookLoad.length - 1]}
        min={0}
        max={lookupHookLoad[lookupHookLoad.length - 1] || 3500}
        step={100}
        onChange={(val) => handleValueChanges({ hookLoad: parseInt(val) || 0 })}
      />
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'VARIABLE DECK LOAD (lbs/sqft)'}
        value={
          variableDeckLoad ||
          Math.floor(lookupVariableDeckLoad[lookupHookLoad.length - 1] / 500) * 500
        }
        min={0}
        max={Math.floor(lookupVariableDeckLoad[lookupHookLoad.length - 1] / 500) * 500 || 35000}
        step={500}
        onChange={(val) => handleValueChanges({ variableDeckLoad: parseInt(val) || 0 })}
      />
      <FilterSlider
        autoMarkers
        titleVariant={'body2'}
        title={'ACCOMMODATION (POB)'}
        value={accommodation || lookupAccommodation[lookupAccommodation.length - 1]}
        min={0}
        max={lookupAccommodation[lookupAccommodation.length - 1] || 5000}
        step={20}
        onChange={(val) => handleValueChanges({ accommodation: parseInt(val) || 0 })}
      />
      <Stack>
        <Typography variant={'body2'}>HIGH SPECIFICATION</Typography>
        <YesNoSelect
          value={highSpec}
          handleChange={(val) => handleValueChanges({ highSpec: val })}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>WINTERIZED</Typography>
        <YesNoSelect
          value={winterized}
          handleChange={(val) => handleValueChanges({ winterized: val })}
        />
      </Stack>
    </Stack>
  );
}

ContractTechRequirements.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  tempState: PropTypes.object
};

ContractTechRequirements.defaultProps = {
  tempState: {}
};

export default ContractTechRequirements;
