import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@esgian/esgianui';

function YesNoSelect({ handleChange, value }) {
  return (
    <Select
      displayEmpty
      variant={'outlined'}
      value={value !== null ? value : ''}
      onChange={({ target }) => handleChange(target.value === '' ? null : target.value === 'true')}>
      <MenuItem value={''}>-</MenuItem>
      <MenuItem value={'true'}>Yes</MenuItem>
      <MenuItem value={'false'}>No</MenuItem>
    </Select>
  );
}

YesNoSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

YesNoSelect.defaultProps = {
  value: ''
};

export default YesNoSelect;
