import moment from 'moment';

const TOKEN_STRING = `esgian_auth${process.env.COOKIE_STAGE}`;
const DOMAIN_STRING = location.hostname !== '' ? `; domain=${process.env.COOKIE_DOMAIN}` : '';
const ACCEPTANCE_STRING_RS = 'disclaimer-rigs-planner';

export const logout = () => {
  document.cookie = `${TOKEN_STRING}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${DOMAIN_STRING}; path=/`;
  document.cookie = `${ACCEPTANCE_STRING_RS}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${DOMAIN_STRING}; path=/`;
};

export const getToken = () => {
  return document.cookie
    .split(';')
    .find((e) => e.includes(TOKEN_STRING))
    ?.split('=')[1];
};

export const setToken = (token, remember = false) => {
  // Delete old tokens, if exist
  logout();

  if (remember) {
    const expireDate = moment().add(1, 'years');
    document.cookie = `${TOKEN_STRING}=${token}; expires=${expireDate}${DOMAIN_STRING}; path=/`;
  } else {
    document.cookie = `${TOKEN_STRING}=${token}${DOMAIN_STRING}; path=/`;
  }
};

export const setDisclaimer = () => {
  const expireDate = moment().add(1, 'month');
  document.cookie = `${ACCEPTANCE_STRING_RS}=accepted; expires=${expireDate}${DOMAIN_STRING}; path=/`;
};

export const getDisclaimer = () => {
  return document.cookie
    .split(';')
    .find((e) => e.includes(`${ACCEPTANCE_STRING_RS}`))
    ?.split('=')[1];
};

export const setStateToSessionStorage = (state) => {
  const stateObj = {
    themeMode: state.themeMode,
    sideMenuOpen: state.sideMenuOpen,
    user: state.user,
    sectionFilters: state.sectionFilters,
    projectsData: state.projectsData
  };

  window.localStorage.setItem('esgianRigsPlanner', JSON.stringify(stateObj));
};

export const setTour = () => {
  const expireDate = moment().add(1, 'year');
  document.cookie = `rig-planner-tour=accepted; expires=${expireDate}${DOMAIN_STRING}; path=/`;
};
export const getTour = () => {
  return document.cookie
    .split(';')
    .find((e) => e.includes(`rig-planner-tour`))
    ?.split('=')[1];
};
