import moment from 'moment';

export const getAddOperatorsFilterDefaults = () => {
  return {
    contractStatuses: { type: 'multiselect', value: [] },
    regions: { type: 'multiselect', value: [] },
    countries: { type: 'multiselect', value: [] },
    rigTypes: { type: 'multiselect', value: [] },
    startPeriod: {
      type: 'date',
      startDate: moment().format('yyyy-MM-DD'),
      endDate: moment().add(4, 'year').format('yyyy-MM-DD')
    }
  };
};

export const operatorsFilterToRequirementKey = {
  contractStatuses: 'contractStatusId',
  countries: 'countryId',
  regions: 'regionId',
  rigTypes: 'rigTypeId',
  startPeriod: 'startDate'
};
