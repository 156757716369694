import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { Stack, Typography } from '@esgian/esgianui';
import { LookupSelect, OperatorSelect } from '@components/Inputs';
import { getValueObj } from '@helpers/helpers';

function RequirementGeneralFields({ handleValueChanges, fields, errors }) {
  const {
    state: { filterLookups }
  } = useStore();

  const { rigsType: lookupRigTypes } = filterLookups || {};

  const { operatorId, operator, rigTypes } = fields;

  return (
    <Stack spacing={1}>
      <Typography bold>General info</Typography>
      <Stack spacing={2}>
        <Stack>
          <Typography color={errors.operator ? 'error' : 'text.primary'} variant={'body2'}>
            OPERATOR*
          </Typography>
          <OperatorSelect
            error={!!errors.operator && !operatorId ? errors.operator : null}
            onChange={(_, val) =>
              handleValueChanges({ operatorId: parseInt(val.id), operator: val.name })
            }
            selectedValues={getValueObj(operatorId, operator)}
          />
        </Stack>
        <Stack>
          <Typography variant={'body2'}>RIG TYPES*</Typography>
          <LookupSelect
            error={!!errors.rigTypes && !rigTypes?.length ? errors.rigTypes : null}
            handleChange={(_, val) => handleValueChanges({ rigTypes: val })}
            value={rigTypes || []}
            id={'requirement-rig-types'}
            options={lookupRigTypes}
            multiple
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

RequirementGeneralFields.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  fields: PropTypes.object,
  errors: PropTypes.object
};

RequirementGeneralFields.defaultProps = {
  errors: {},
  fields: {}
};

export default RequirementGeneralFields;
