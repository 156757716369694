import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemText, Paper, Popover } from '@esgian/esgianui';
import { AddEditCommentModal } from '@components/Modals';
import { EventDetailsDrawer } from '@components/Drawers';
import { FLOW, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';
import { getProject } from '@helpers/helpers';
import { snackbar } from '@mobiscroll/react';
import AddEditContractModule from '@components/Sections/RigSupplierSection/Modules/AddEditContractModule';
import Swal from 'sweetalert2';

function ContractMenu({ flow, mousePosition, eventMenuOpen, handleClose, eventMenuItem }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [contractComment, setContractComment] = useState(null);
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const { contractsWithoutRigs: lookupRequirements } = filterLookups;
  const params = useParams();

  const applyCommentClick = useCallback(
    (comment) => {
      if (flow === FLOW.RIG_SUPPLIER) {
        dispatch({
          type: RIG_SUPPLIER_ACTIONS.UPDATE_RIG_CONTRACT,
          flow: FLOW.RIG_SUPPLIER,
          payload: {
            projectId: parseInt(params.projectId),
            newFields: { comment: comment },
            contract: eventMenuItem?.details
          }
        });
      } else {
        dispatch({
          type: OIL_COMPANY_ACTIONS.UPDATE_CONTRACT,
          flow: FLOW.OIL_COMPANY,
          payload: {
            projectId: parseInt(params.projectId),
            newFields: { comment: comment },
            contract: eventMenuItem?.details
          }
        });
      }
      snackbar({
        message: `Comment ${contractComment ? 'updated' : 'added'}`,
        color: 'success',
        duration: 5000,
        display: 'top'
      });

      setCommentModalOpen(false);
    },
    [eventMenuItem]
  );

  const handleResetClick = useCallback(() => {
    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        if (eventMenuItem.details.originalRequirement) {
          const { customRequirements } = getProject(projects, params.projectId);
          let originalRequirement = null;
          if (eventMenuItem.details.isManualCreate) {
            if (flow === FLOW.RIG_SUPPLIER) {
              originalRequirement = customRequirements?.find(
                ({ contractId, rigId }) =>
                  contractId === eventMenuItem.details.contractId && rigId === null
              );
            }
          } else {
            originalRequirement = lookupRequirements?.find(
              ({ contractId, rigId }) =>
                contractId === eventMenuItem.details.contractId && rigId === null
            );
          }
          if (flow === FLOW.RIG_SUPPLIER) {
            dispatch({
              type: RIG_SUPPLIER_ACTIONS.REMOVE_CONTRACT_FROM_RIG,
              flow: flow,
              payload: {
                projectId: parseInt(params.projectId),
                contract: eventMenuItem?.details
              }
            });
            dispatch({
              type: RIG_SUPPLIER_ACTIONS.CREATE_REQUIREMENT,
              flow: flow,
              payload: {
                requirement: {
                  ...originalRequirement,
                  ...{ rigId: eventMenuItem.details.rigId, rigName: eventMenuItem.details.rigName }
                },
                projectId: parseInt(params.projectId)
              }
            });
          } else {
            dispatch({
              type: OIL_COMPANY_ACTIONS.RESET_REQUIREMENT_CONTRACT,
              flow: flow,
              payload: {
                projectId: parseInt(params.projectId),
                contract: eventMenuItem?.details
              }
            });
          }
          snackbar({
            message: `Contract reset`,
            color: 'success',
            duration: 5000,
            display: 'top'
          });
        } else {
          dispatch({
            type: RIG_SUPPLIER_ACTIONS.RESET_CONTRACT,
            flow: FLOW.RIG_SUPPLIER,
            payload: {
              projectId: parseInt(params.projectId),
              contractId: eventMenuItem?.details.contractId
            }
          });
        }
      }
    });
  }, [eventMenuItem, lookupRequirements, projects]);

  const handleRemoveClick = useCallback(() => {
    handleClose();

    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type:
            flow === FLOW.OIL_COMPANY
              ? OIL_COMPANY_ACTIONS.REMOVE_CONTRACT_FROM_RIG
              : RIG_SUPPLIER_ACTIONS.REMOVE_CONTRACT_FROM_RIG,
          flow: flow,
          payload: {
            projectId: parseInt(params.projectId),
            contract: eventMenuItem?.details
          }
        });
        snackbar({
          message: `Contract removed`,
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });
  }, [eventMenuItem]);

  useEffect(() => {
    setContractComment(eventMenuItem?.details?.comment);
  }, [eventMenuItem]);

  return (
    <>
      {eventMenuOpen && (
        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{ top: mousePosition?.y || 0, left: mousePosition?.x || 0 }}
          open={eventMenuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <Paper>
            <List>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setInfoDrawerOpen(true);
                }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={`View contract info`}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setEditModalOpen(true);
                }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={`Edit contract`}
                />
              </ListItemButton>
              <ListItemButton onClick={handleRemoveClick}>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={`Remove`} />
              </ListItemButton>
              {eventMenuItem?.details.isCustom && (
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    handleResetClick();
                  }}>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={`Reset`} />
                </ListItemButton>
              )}
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setCommentModalOpen(true);
                }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={`${contractComment ? 'Edit' : 'Add'} comment`}
                />
              </ListItemButton>
            </List>
          </Paper>
        </Popover>
      )}
      {editModalOpen && (
        <AddEditContractModule
          flow={flow}
          handleClose={() => setEditModalOpen(false)}
          open={editModalOpen}
          contract={eventMenuItem?.details}
        />
      )}
      {commentModalOpen && (
        <AddEditCommentModal
          comment={contractComment}
          open={commentModalOpen}
          handleClose={() => setCommentModalOpen(false)}
          handleApplyClick={applyCommentClick}
        />
      )}
      {infoDrawerOpen && (
        <EventDetailsDrawer
          handleClose={() => setInfoDrawerOpen(false)}
          open={infoDrawerOpen}
          event={eventMenuItem?.details}
        />
      )}
    </>
  );
}

ContractMenu.propTypes = {
  flow: PropTypes.oneOf(Object.values(FLOW)),
  mousePosition: PropTypes.object,
  eventMenuOpen: PropTypes.bool,
  eventMenuItem: PropTypes.object,
  handleClose: PropTypes.func.isRequired
};

ContractMenu.defaultProps = {
  flow: FLOW.OIL_COMPANY,
  mousePosition: {},
  eventMenuItem: null,
  eventMenuOpen: false
};

export default ContractMenu;
