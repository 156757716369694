import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CloseIcon,
  DeleteOutlineIcon,
  Divider,
  IconButton,
  Paper,
  ResetIcon,
  Stack,
  Typography
} from '@esgian/esgianui';

function SelectedTimelineResourcesDisplay({
  selectedResources,
  setShowSelectResource,
  setSelectedResources,
  handleDeleteRowsClick,
  handleResetRowsClick,
  showSelectResource
}) {
  return (
    <Box
      sx={{
        maxHeight: showSelectResource ? '200px' : '0px',
        transition: showSelectResource ? 'max-height 0.25s ease-in' : 'max-height 0.15s ease-out'
      }}>
      <Paper variant={'outlined'} sx={{ background: '#f3f4f6', p: 2 }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack
            direction={'row'}
            spacing={2}
            divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
            <Typography sx={{ alignSelf: 'center' }} bold variant={'body2'}>
              {selectedResources.length} selected
            </Typography>
            <Stack direction={'row'} spacing={2}>
              <Button
                variant={'text'}
                color={'secondary'}
                key={'remove-rows-button'}
                onClick={handleDeleteRowsClick}
                startIcon={<DeleteOutlineIcon color={'inherit'} />}>
                Remove
              </Button>
              <Button
                onClick={handleResetRowsClick}
                color={'secondary'}
                variant={'text'}
                key={'reset-rows-button'}
                startIcon={<ResetIcon color={'inherit'} />}>
                reset
              </Button>
            </Stack>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowSelectResource(false);
              setSelectedResources([]);
            }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Paper>
    </Box>
  );
}

SelectedTimelineResourcesDisplay.propTypes = {
  selectedResources: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setShowSelectResource: PropTypes.func.isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  handleDeleteRowsClick: PropTypes.func.isRequired,
  handleResetRowsClick: PropTypes.func.isRequired,
  showSelectResource: PropTypes.bool.isRequired
};

SelectedTimelineResourcesDisplay.defaultProps = {
  selectedResources: []
};

export default SelectedTimelineResourcesDisplay;
