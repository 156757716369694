import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid, Modal, TextField, Typography } from '@esgian/esgianui';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  ContractGeneralFields,
  ContractMainFields,
  ContractGeographyFields,
  ContractTechRequirements
} from './index';
import { FLOW, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';
import { snackbar } from '@mobiscroll/react';
import moment from 'moment';

const checkRequiredFields = (tempState, setError) => {
  const { rigId, startDate, duration, operatorId, contractStatusId } = tempState;
  let errors = {
    rigId: rigId ? null : 'Required',
    startDate: !startDate ? 'Required' : moment(startDate).isValid() ? null : 'Invalid start date',
    duration: !duration ? 'Contract duration must minimum be 1 day' : null,
    operator: !operatorId ? 'Required' : null,
    contractStatus: !contractStatusId ? 'Required' : null
  };
  setError(errors);
  return Object.entries(errors).filter(([_, v]) => v != null).length === 0;
};

function AddEditContractModule({ flow, open, contract, handleClose }) {
  const [tempState, setTempState] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorsEnabled, setErrorsEnabled] = useState(false);
  const [errors, setErrors] = useState({
    rigId: null,
    startDate: null,
    duration: null,
    operator: null,
    contractStatus: null
  });
  const { dispatch } = useStore();
  const params = useParams();

  useEffect(() => {
    if (!contract) return;
    setTempState({ ...contract });
  }, [contract]);

  useEffect(() => {
    if (!errorsEnabled) return;
    !checkRequiredFields(tempState, setErrors);
  }, [
    errorsEnabled,
    tempState.rigId,
    tempState.startDate,
    tempState.duration,
    tempState.operatorId,
    tempState.contractStatusId
  ]);

  const onClose = useCallback(() => {
    setTempState({});
    setErrorsEnabled(false);
    handleClose();
  }, []);

  const handleValueChanges = (val) => {
    setTempState({ ...tempState, ...val });
  };

  const handleModalSave = () => {
    setErrorsEnabled(true);
    const projectId = parseInt(params.projectId);
    if (!checkRequiredFields(tempState, setErrors)) return;
    setLoading(true);
    let action = null;
    let message = '';
    let payload = null;

    const hasUpdates = Object.keys(tempState).some((key) => tempState[key] !== contract[key]);
    if (!hasUpdates) {
      onClose();
      setLoading(false);
      return;
    }
    action =
      flow === FLOW.OIL_COMPANY
        ? OIL_COMPANY_ACTIONS.UPDATE_CONTRACT
        : RIG_SUPPLIER_ACTIONS.UPDATE_RIG_CONTRACT;
    message = 'Contract updated';
    let newFields = {
      ...tempState,
      ...{
        startDate: moment(tempState.startDate),
        endDate: moment(tempState.startDate).add(tempState.duration, 'days')
      }
    };
    payload = {
      newFields: newFields,
      contract: contract
    };
    if (!contract.isCustom) {
      let removeAction =
        flow === FLOW.OIL_COMPANY
          ? OIL_COMPANY_ACTIONS.REMOVE_CONTRACT_FROM_RIG
          : RIG_SUPPLIER_ACTIONS.REMOVE_CONTRACT_FROM_RIG;
      dispatch({
        type: removeAction,
        flow: flow,
        payload: {
          contract: tempState,
          ...{ projectId: projectId }
        }
      });
    }
    if (action) {
      dispatch({
        type: action,
        flow: flow,
        payload: {
          ...payload,
          ...{ projectId: projectId }
        }
      });
      snackbar({
        message: message,
        color: 'success',
        duration: 5000,
        display: 'top'
      });
    }
    onClose();
    setLoading(false);
  };

  const { comment } = tempState || {};

  return (
    <Modal
      size={'md'}
      handleClose={onClose}
      title={'Edit contract'}
      open={open}
      actions={[
        <Button withUnderline onClick={onClose} color={'secondary'} key={'cancel-changes-module'}>
          Cancel
        </Button>,
        <Button
          color={'secondary'}
          disableElevation
          onClick={handleModalSave}
          key={'apply-changes'}
          variant={'contained'}>
          Apply
        </Button>
      ]}>
      {loading ? (
        <Grid container sx={{ height: '70vh' }}>
          <Grid item xs={12} sx={{ textAlign: 'center', placeSelf: 'center' }}>
            <CircularProgress size={'5em'} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item sm={12} md={10} lg={8}>
            <ContractGeneralFields
              flow={flow}
              errors={errors}
              handleValueChanges={handleValueChanges}
              tempState={tempState}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <ContractMainFields
              errors={errors}
              handleValueChanges={handleValueChanges}
              tempState={tempState}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <ContractGeographyFields
              handleValueChanges={handleValueChanges}
              tempState={tempState}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <ContractTechRequirements
              handleValueChanges={handleValueChanges}
              tempState={tempState}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <Typography bold>Comment</Typography>
            <TextField
              value={comment || ''}
              onChange={({ target }) => handleValueChanges({ comment: target.value })}
              variant={'outlined'}
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Modal>
  );
}

AddEditContractModule.propTypes = {
  flow: PropTypes.oneOf(Object.values(FLOW)),
  contract: PropTypes.object,
  open: PropTypes.bool,
  isRequirement: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

AddEditContractModule.defaultProps = {
  flow: FLOW.OIL_COMPANY,
  open: false,
  isRequirement: false,
  contract: null
};

export default AddEditContractModule;
