import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CloseIcon,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@esgian/esgianui';

import { useStore } from '@store/Store';
import { getAppliedFilterCount } from '@helpers/helpers';
import { useParams } from 'react-router-dom';
import { getProject } from '@helpers/helpers';
import ApplicableOperatorsDisplay from '@components/Sections/OilCompanySection/Drawers/AddRequirementDrawer/ApplicableOperatorsDisplay';
import OperatorsFilterContainer from '@components/Sections/OilCompanySection/Drawers/AddRequirementDrawer/OperatorsFiltersContainer';
import {
  getAddOperatorsFilterDefaults,
  operatorsFilterToRequirementKey
} from '@components/Sections/OilCompanySection/Drawers/AddRequirementDrawer/OperatorsFiltersContainer/AddOperatorsDefaultFilters';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FLOW, OIL_COMPANY_ACTIONS } from '@store/AppReducer';
import { snackbar } from '@mobiscroll/react';
import { getRequirementFilterDefaults } from '@components/Sections/RigSupplierSection/Drawers/AddRigsDrawer/AddRigsDefaultFilters';

const getFilteredOperatorsList = (defaultFilters, lookupRequirements, lookupOperators, filters) => {
  let includeRequirement = [];
  lookupRequirements?.forEach((rig) => {
    let include = true;
    Object.entries(filters).forEach(([key, filter]) => {
      if (filter.type === 'multiselect' && filter.value?.length) {
        let filterIds = [...filter.value].map(({ id }) => id);
        if (!filterIds.includes(rig[operatorsFilterToRequirementKey[key]])) {
          include = false;
        }
      }
      if (filter.type === 'date' && filter?.startDate && filter?.endDate) {
        if (
          !moment(rig[operatorsFilterToRequirementKey[key]], 'yyyy-MM-DD').isBetween(
            moment(filter.startDate, 'yyyy-MM-DD'),
            moment(filter.endDate, 'yyyy-MM-DD'),
            'day'
          )
        ) {
          include = false;
        }
      }
    });
    if (include) {
      includeRequirement.push(rig);
    }
  });
  const includeIds = includeRequirement?.map(({ operatorId }) => operatorId);

  return [...lookupOperators]?.filter(({ id }) => includeIds.includes(id));
};

function AddRequirementDrawer({ open, setOpen }) {
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [operatorsInState, setOperatorsInState] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [viewFilters, setViewFilters] = useState(false);
  const [query, setQuery] = useState('');
  const [displayOperators, setDisplayOperators] = useState([]);
  const params = useParams();
  const projectId = params.projectId;
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    },
    dispatch
  } = useStore();

  const { lookupOperators, lookupRequirements } = useMemo(() => {
    const { rigsOperators = [], contractsWithoutRigs = [] } = filterLookups;
    return { lookupOperators: rigsOperators, lookupRequirements: contractsWithoutRigs };
  }, [filterLookups]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query === '') {
        setDisplayOperators(lookupOperators);
        return;
      }
      let tempDisplay = [];
      displayOperators.forEach((operator) => {
        if (operator.name.toLowerCase().includes(query.toLowerCase())) {
          tempDisplay.push(operator);
        }
      });
      setDisplayOperators(tempDisplay);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [query, lookupOperators]);

  useEffect(() => {
    if (!filterLookups) return;
    setFilters(getAddOperatorsFilterDefaults(filterLookups));
    setAppliedFilters(getAddOperatorsFilterDefaults(filterLookups));
  }, [filterLookups]);

  useEffect(() => {
    const { operators } = getProject(projects, projectId);
    setOperatorsInState(operators);
  }, [JSON.stringify(projects)]);

  const handleApplyFilters = useCallback(
    (reset = false) => {
      setAppliedFilters({ ...filters });
      let defaultFilters = getAddOperatorsFilterDefaults(filterLookups);
      const count = reset ? 0 : getAppliedFilterCount(filters, defaultFilters);
      if (count > 0) {
        setDisplayOperators(
          getFilteredOperatorsList(defaultFilters, lookupRequirements, lookupOperators, filters)
        );
      } else {
        setDisplayOperators(lookupOperators);
      }
      setFiltersCount(count);
      setViewFilters(false);
    },
    [filters, filterLookups]
  );

  const addOperators = useCallback((projectId, selectedOperators, filterLookups) => {
    dispatch({
      type: OIL_COMPANY_ACTIONS.ADD_OPERATORS_TO_PROJECT,
      flow: FLOW.OIL_COMPANY,
      payload: { projectId: projectId, operators: selectedOperators }
    });
    snackbar({
      message: `Operators added to project`,
      color: 'success',
      duration: 5000,
      display: 'top'
    });
    setFilters(getRequirementFilterDefaults(filterLookups));
    setAppliedFilters(getRequirementFilterDefaults(filterLookups));
    setSelectedOperators([]);
  }, []);

  const handleAddOperators = useCallback(() => {
    setOpen(false);
    if (selectedOperators.length <= 20) {
      addOperators(params.projectId, selectedOperators, filterLookups);
      return;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: `You're about to add ${selectedOperators.length} operators to your project`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        addOperators(params.projectId, selectedOperators, filterLookups);
      } else {
        setOpen(true);
      }
    });
  }, [selectedOperators, filterLookups]);

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} anchor={'right'}>
        <Grid
          container
          sx={{
            width: '25vw',
            maxHeight: '100%'
          }}>
          <Grid sx={{ height: '57px' }} item xs={12}>
            <Grid justifyContent={'space-between'} container>
              <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
                <Typography variant={'h6'} color={'text.primary'}>
                  {viewFilters ? 'Filters' : 'Add operators'}
                </Typography>
              </Grid>
              <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
                <IconButton
                  onClick={() => {
                    if (viewFilters) {
                      setViewFilters(false);
                      setFilters(appliedFilters);
                    } else {
                      setOpen(false);
                    }
                  }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack
              justifyContent={'space-between'}
              sx={{
                overflowX: 'hidden',
                height: 'calc(100vh - 57px - 70px )',
                overflowY: 'auto'
              }}>
              {viewFilters ? (
                <OperatorsFilterContainer filters={filters} setFilters={setFilters} />
              ) : (
                <ApplicableOperatorsDisplay
                  setSelectedOperators={setSelectedOperators}
                  operatorsInState={operatorsInState}
                  selectedOperators={selectedOperators}
                  displayOperators={displayOperators}
                  filtersCount={filtersCount}
                  setViewFilters={setViewFilters}
                  setQuery={setQuery}
                  query={query}
                />
              )}
              <Stack
                direction={'row'}
                sx={{
                  p: 2,
                  boxShadow: '0 0 8px rgb(0 0 0 / 18%)',
                  position: 'fixed',
                  bottom: 0,
                  height: '70px',
                  background: (theme) => theme.palette.background.paper,
                  width: '25vw'
                }}
                justifyContent={'space-between'}>
                <Button
                  color={'secondary'}
                  onClick={() => {
                    if (viewFilters) {
                      setFilters(getAddOperatorsFilterDefaults(filterLookups));
                      handleApplyFilters(true);
                    } else {
                      setSelectedOperators([]);
                    }
                  }}
                  variant={'text'}
                  withUnderline>
                  Clear All
                </Button>
                <Button
                  disabled={!viewFilters && !selectedOperators?.length}
                  disableElevation
                  onClick={() => {
                    if (viewFilters) {
                      handleApplyFilters();
                    } else {
                      handleAddOperators();
                    }
                  }}
                  variant={'contained'}>
                  {viewFilters ? 'Apply' : 'Add operators'}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

AddRequirementDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

AddRequirementDrawer.defaultProps = {};

export default AddRequirementDrawer;
