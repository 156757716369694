import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useStore } from '@store/Store';
import {
  CloseIcon,
  Collapse,
  Divider,
  Drawer,
  ExpandLessIcon,
  ExpandMoreIcon,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@esgian/esgianui';
import RigInfoValueDisplay from '@components/Display/RigInfoValueDisplay/RigInfoValueDisplay';
import moment from 'moment';

function RigInfoDrawer({ rigId, handleClose }) {
  const [rig, setRig] = useState(null);
  const [collapseOpen, setCollapseOpen] = useState({ general: true, tech: true });
  const params = useParams();
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    }
  } = useStore();
  const { rigs: lookupRigs = [] } = filterLookups;

  useEffect(() => {
    if (!projects?.length || !lookupRigs?.length || !rigId) return;
    let resourceId = rigId;
    if (typeof resourceId === 'string') {
      let ids = resourceId.split(',');
      resourceId = ids.length === 3 ? parseInt(ids[2]) : parseInt(ids[1]);
    }
    setRig(lookupRigs.find((rig) => rig.rigId === resourceId));
  }, [rigId, filterLookups]);

  const {
    rigName,
    rigType,
    rigManager,
    rigOwner,
    operator,
    rigStatus,
    country,
    region,
    yearInService,
    rigDesign,
    rigOperatingArea,
    contractNextAvailable,
    availableUntil,
    competitive,
    harshEnvironment,
    winterized,
    designWaterDepth,
    outfittedWaterDepth,
    variableDeckLoad,
    cantileverReach,
    hpht,
    highSpec,
    managedPressureDrilling,
    dpClass,
    mooringCapability,
    bopMaxPsi,
    nrOfBops,
    nrOfStackRams,
    bopControlSystem,
    derrickType,
    accommodation,
    comment
  } = rig || {};

  return (
    <Drawer open={!!rigId} onClose={handleClose} anchor={'right'}>
      <Grid
        container
        sx={{
          width: '25vw',
          maxHeight: '100%'
        }}>
        <Grid sx={{ height: '57px' }} item xs={12}>
          <Grid justifyContent={'space-between'} container sx={{}}>
            <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <Typography variant={'h6'} color={'text.primary'}>
                Rig info
              </Typography>
            </Grid>
            <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ListItemButton
            onClick={() =>
              setCollapseOpen({ ...collapseOpen, ...{ general: !collapseOpen.general } })
            }
            sx={{ justifyContent: 'space-between' }}>
            <ListItemText>
              <Typography variant={'subtitle1'} bold>
                General
              </Typography>
            </ListItemText>
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              {collapseOpen.general ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
          </ListItemButton>
          <Divider />
          <Collapse in={collapseOpen.general} timeout="auto" unmountOnExit>
            <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <RigInfoValueDisplay title={'Rig name'} value={rigName} />
              <RigInfoValueDisplay title={'Rig type'} value={rigType} />
              <RigInfoValueDisplay title={'Rig Manager'} value={rigManager} />
              <RigInfoValueDisplay title={'Rig owner'} value={rigOwner} />
              <RigInfoValueDisplay title={'operator'} value={operator} />
              <RigInfoValueDisplay title={'current operational status'} value={rigStatus} />
              <RigInfoValueDisplay title={'Country'} value={country} />
              <RigInfoValueDisplay title={'Region'} value={region} />
              <RigInfoValueDisplay title={'Year in service'} value={yearInService} />
              <RigInfoValueDisplay title={'Design category'} value={rigDesign} />
              <RigInfoValueDisplay title={'Rig operational area'} value={rigOperatingArea} />
              <RigInfoValueDisplay
                title={'Available from'}
                value={
                  contractNextAvailable ? moment(contractNextAvailable).format('DD/MM/yyyy') : null
                }
              />
              <RigInfoValueDisplay
                title={'Available until'}
                value={availableUntil ? moment(availableUntil).format('DD/MM/yyyy') : null}
              />
              <RigInfoValueDisplay title={'competitive'} value={competitive ? 'yes' : 'no'} />
            </Stack>
            <Divider />
          </Collapse>

          <ListItemButton
            onClick={() => setCollapseOpen({ ...collapseOpen, ...{ tech: !collapseOpen.tech } })}
            sx={{ justifyContent: 'space-between' }}>
            <ListItemText>
              <Typography variant={'subtitle1'} bold>
                Technical
              </Typography>
            </ListItemText>
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              {collapseOpen.tech ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
          </ListItemButton>
          <Divider />
          <Collapse in={collapseOpen.tech} timeout="auto" unmountOnExit>
            <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <RigInfoValueDisplay
                title={'Harsh environment'}
                value={harshEnvironment ? 'yes' : 'no'}
              />
              <RigInfoValueDisplay title={'Winterised'} value={winterized ? 'yes' : 'no'} />
              <RigInfoValueDisplay
                title={'Design Water depth (ft)'}
                value={designWaterDepth ? `${designWaterDepth} ft` : null}
              />
              <RigInfoValueDisplay
                title={'Outfitted Water depth (ft)'}
                value={outfittedWaterDepth ? `${outfittedWaterDepth} ft` : null}
              />
              <RigInfoValueDisplay
                title={'Variable deck load (lbs/sqft)'}
                value={variableDeckLoad ? `${variableDeckLoad} lbs/sqft` : null}
              />
              <RigInfoValueDisplay
                title={'Cantilever reach (ft)'}
                value={cantileverReach ? `${cantileverReach} ft` : null}
              />
              <RigInfoValueDisplay title={'HPHT'} value={hpht ? 'yes' : 'no'} />
              <RigInfoValueDisplay title={'High specification'} value={highSpec ? 'yes' : 'no'} />
              <RigInfoValueDisplay
                title={'Managed pressure drilling (MPD)'}
                value={managedPressureDrilling ? 'yes' : 'no'}
              />
              <RigInfoValueDisplay title={'DP-class'} value={dpClass} />
              <RigInfoValueDisplay
                title={'Mooring capability'}
                value={mooringCapability ? 'yes' : 'no'}
              />
              <RigInfoValueDisplay title={'Number of BOPs'} value={nrOfBops} />
              <RigInfoValueDisplay
                title={'BOP pressure rating (PSI)'}
                value={bopMaxPsi || 'Unknown'}
              />
              <RigInfoValueDisplay title={'Number of bop-stack rams'} value={nrOfStackRams} />
              <RigInfoValueDisplay title={'Number control system'} value={bopControlSystem} />
              <RigInfoValueDisplay title={'Derrick type'} value={derrickType} />
              <RigInfoValueDisplay title={'Accommodation (POB)'} value={accommodation} />
            </Stack>
          </Collapse>
          {comment && (
            <Stack spacing={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <Typography variant={'subtitle1'} bold>
                Comment
              </Typography>
              <Typography variant={'body2'}>{comment}</Typography>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
}

RigInfoDrawer.propTypes = {
  rigId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClose: PropTypes.func.isRequired
};

RigInfoDrawer.defaultProps = {
  rigId: null
};

export default RigInfoDrawer;
