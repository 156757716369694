import moment from 'moment';

export const findEligibleRigs = (
  rigs,
  earliestStartDate,
  latestStartDate,
  duration,
  dateFlexibility,
  filters
) => {
  const { region, rigType, owner, manager, waterDepth, rigStatus } = filters;
  const availableRigs = [];
  const availableRigsFlex = [];
  const earliestStart = moment(earliestStartDate, 'yyyy-MM-DD');
  const latestStart = moment(latestStartDate, 'yyyy-MM-DD');
  let rigTypeIds = rigType?.map(({ id }) => id) || [];
  let regionIds = region?.map(({ id }) => id) || [];
  let ownerIds = owner?.map(({ id }) => id) || [];
  let managerIds = manager?.map(({ id }) => id) || [];
  let rigStatusIds = rigStatus?.map(({ id }) => id) || [];

  // Loop through each rig in the rigs array
  for (let i = 0; i < rigs.length; i++) {
    const rig = rigs[i];
    let isAvailable = false;
    let isFlexAvailable = false;
    if (regionIds?.length && !regionIds.includes(rig.regionId)) {
      continue;
    }
    if (rigTypeIds?.length && !rigTypeIds.includes(rig.rigTypeId)) {
      continue;
    }
    if (ownerIds?.length && !ownerIds?.includes(rig.rigOwnerId)) {
      continue;
    }
    if (managerIds?.length && !managerIds?.includes(rig.rigManagerId)) {
      continue;
    }
    if (rigStatusIds?.length && !rigStatusIds?.includes(rig.rigStatusId)) {
      continue;
    }
    if (waterDepth.min >= rig.outfittedWaterDepth || waterDepth.max <= rig.outfittedWaterDepth) {
      continue;
    }

    // Loop through each contract for the current rig
    for (let j = 0; j < rig?.contracts.length; j++) {
      const currentContract = rig.contracts[j];
      const nextContract = rig.contracts[j + 1];
      // Check for rigs that matches without the date flex availability
      if (moment(currentContract.endDate).isSameOrBefore(latestStart)) {
        if (!nextContract) {
          isAvailable = true;
          break;
        }
        if (
          moment(nextContract.startDate).isSameOrAfter(moment(earliestStart).add(duration, 'days'))
        ) {
          if (
            moment(nextContract.startDate).diff(moment(currentContract.endDate), 'days') >= duration
          ) {
            isAvailable = true;
            break;
          }
        }
      }

      if (
        moment(currentContract.endDate).isSameOrBefore(
          moment(latestStart).add(dateFlexibility, 'days')
        )
      ) {
        if (!nextContract) {
          isFlexAvailable = true;
          break;
        }
        if (
          moment(nextContract.startDate).isSameOrAfter(
            moment(earliestStart).add(duration + dateFlexibility, 'days')
          )
        ) {
          if (
            moment(nextContract.startDate).diff(moment(currentContract.endDate), 'days') >= duration
          ) {
            isFlexAvailable = true;
            break;
          }
        }
      }
    }

    let rigObj = {
      rigId: rig.rigId,
      rigName: rig.rigName,
      region: rig.region,
      rigType: rig.rigType,
      rigStatus: rig.rigStatus,
      rigStatusId: rig.rigStatusId
    };

    if (isAvailable) {
      availableRigs.push(rigObj);
    }
    if (isFlexAvailable) {
      availableRigsFlex.push(rigObj);
    }
  }
  return { availableRigsFlex: availableRigsFlex, availableRigs: availableRigs };
};
