import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack, Typography } from '@esgian/esgianui';
import RigInfoValueDisplay from '@components/Display/RigInfoValueDisplay/RigInfoValueDisplay';
import { Popup } from '@mobiscroll/react';
import moment from 'moment';

function RequirementTooltip({ anchor, details }) {
  const {
    operator,
    region,
    country,
    rigTypes,
    contractStatus,
    startDate,
    latestStartDate,
    duration,
    projectName,
    nrOfWells,
    comment
  } = details || {};

  let nameDisplay = projectName || 'Requirement';

  return (
    <Popup
      maxWidth={'25em'}
      display="anchored"
      isOpen={true}
      anchor={anchor}
      themeVariant={'dark'}
      touchUi={false}
      showOverlay={false}
      contentPadding={false}
      closeOnOverlayClick={false}
      cssClass="timeline-tooltip">
      <div>
        <Stack sx={{ p: 2 }} spacing={1}>
          <Typography>{nameDisplay}</Typography>
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={operator}
            title={'Operator:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={region}
            title={'Region:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={country}
            title={'Country:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={rigTypes?.map(({ name }) => name)?.join(', ')}
            title={'Rig type(s):'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={contractStatus}
            title={'Contract type:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={moment(startDate, 'yyyy-MM-DD').format('DD/MM/yyyy')}
            title={'Earliest start date: '}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={moment(latestStartDate, 'yyyy-MM-DD').format('DD/MM/yyyy')}
            title={'Latest start date: '}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={duration}
            title={'Duration(Days):'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={nrOfWells}
            title={'Number of Wells:'}
          />
          {comment && <div style={{ width: '100%', borderBottom: '1px solid #7a7a7b' }} />}
          {comment && (
            <Stack>
              <Typography>Comment</Typography>
              <Typography variant={'caption'}>{comment}</Typography>
            </Stack>
          )}
        </Stack>
      </div>
    </Popup>
  );
}

RequirementTooltip.propTypes = {
  anchor: PropTypes.object,
  details: PropTypes.object
};

RequirementTooltip.defaultProps = {
  anchor: null,
  details: null
};

export default RequirementTooltip;
