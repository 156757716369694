import React from 'react';
import PropTypes from 'prop-types';
import { FilterSlider, Stack, Typography } from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import { useStore } from '@store/Store';
import { ACTIVITY_STATUSES } from '@constants';

function RequirementEligibleRigsFilters({ filters, setFilters }) {
  const {
    state: { filterLookups }
  } = useStore();

  const {
    region: lookupRegion = [],
    rigsType: lookupRigsType = [],
    rigsOwner: lookupRigsOwner = [],
    outfittedWaterDepth: lookupWaterDepth = [],
    rigsManagers: lookupRigsManagers = []
  } = filterLookups || {};

  const handleFilterChange = (val) => {
    setFilters({ ...filters, ...val });
  };

  const {
    waterDepth = {},
    region = [],
    rigType = [],
    owner = [],
    manager = [],
    rigStatus = []
  } = filters;

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Stack>
        <Typography variant={'subtitle2'} gutterBottom color={'text.primary'}>
          REGIONS
        </Typography>
        <LookupSelect
          multiple
          handleChange={(_, val) => handleFilterChange({ region: val })}
          options={lookupRegion}
          value={region || null}
          id={'eligible-rig-region-select'}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG TYPES</Typography>
        <LookupSelect
          multiple
          handleChange={(_, val) => handleFilterChange({ rigType: val })}
          options={lookupRigsType}
          value={rigType || null}
          id={'eligible-rig-type-select'}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG MANAGERS</Typography>
        <LookupSelect
          multiple
          handleChange={(_, val) => handleFilterChange({ manager: val })}
          options={lookupRigsManagers}
          value={manager || null}
          id={'eligible-manager-select'}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG OWNERS</Typography>
        <LookupSelect
          multiple
          handleChange={(_, val) => handleFilterChange({ owner: val })}
          options={lookupRigsOwner}
          value={owner || null}
          id={'eligible-owner-select'}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG STATUS</Typography>
        <LookupSelect
          multiple
          handleChange={(_, val) => handleFilterChange({ rigStatus: val })}
          options={ACTIVITY_STATUSES}
          value={rigStatus || null}
          id={'eligible-status-select'}
        />
      </Stack>
      <Stack>
        <FilterSlider
          sliderWidth={'95%'}
          autoMarkers
          titleVariant={'body2'}
          title={'MAX RIG WATER DEPTH [ft]'}
          value={[
            waterDepth.min || 0,
            waterDepth.max || lookupWaterDepth[lookupWaterDepth.length - 1]
          ]}
          onChange={(value) =>
            handleFilterChange({
              waterDepth: { min: parseInt(value[0]), max: parseInt(value[1]), type: 'number' }
            })
          }
          max={lookupWaterDepth[lookupWaterDepth.length - 1]}
          min={0}
          step={100}
        />
      </Stack>
    </Stack>
  );
}

RequirementEligibleRigsFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired
};

RequirementEligibleRigsFilters.defaultProps = {
  filters: {}
};

export default RequirementEligibleRigsFilters;
