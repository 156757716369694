import React from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import { CONTRACT_STATUSES } from '@constants';
import { getValueObj } from '@helpers/helpers';
import { Datepicker, localeEn } from '@mobiscroll/react';

function ContractMainFields({ handleValueChanges, tempState, errors }) {
  const { contractStatusId, contractStatus, startDate, dayrate, duration, projectName } = tempState;
  return (
    <Stack spacing={2}>
      <Typography bold>Contract</Typography>
      <Stack>
        <Typography variant={'body2'}>PROJECT NAME</Typography>
        <TextField
          value={projectName || ''}
          variant={'outlined'}
          placeholder={'Project name...'}
          onChange={({ target }) => handleValueChanges({ projectName: target.value })}
        />
      </Stack>
      <Stack>
        <Typography color={errors.contractStatus ? 'error' : 'text.primary'} variant={'body2'}>
          CONTRACT STATUS*
        </Typography>
        <LookupSelect
          error={errors.contractStatus}
          id={'contract-status-select'}
          handleChange={(_, val) =>
            handleValueChanges({
              contractStatusId: parseInt(val.id),
              contractStatus: val.name
            })
          }
          value={getValueObj(contractStatusId, contractStatus)}
          options={CONTRACT_STATUSES}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>START DATE*</Typography>
        <Datepicker
          inputStyle={'outline'}
          dateFormat={'DD/MM/YYYY'}
          locale={localeEn}
          startInput={startDate}
          returnFormat={'moment'}
          value={startDate}
          onChange={({ value }) => {
            handleValueChanges({ startDate: value });
          }}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'} color={errors.duration ? 'error' : 'text.primary'}>
          DURATION*(DAYS)
        </Typography>
        <OutlinedInput
          error={!!errors.duration}
          endAdornment={<InputAdornment position="end">Days</InputAdornment>}
          type={'number'}
          value={duration || ''}
          variant={'outlined'}
          placeholder={'I.e: 30'}
          onChange={({ target }) => handleValueChanges({ duration: parseInt(target.value) })}
        />
        {!!errors.duration && (
          <FormHelperText sx={{ pl: 2 }} error>
            {errors.duration}
          </FormHelperText>
        )}
      </Stack>
      <Stack>
        <Typography variant={'body2'}>DAY RATE</Typography>
        <OutlinedInput
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type={'number'}
          value={dayrate || ''}
          variant={'outlined'}
          placeholder={'Day rate...'}
          onChange={({ target }) => handleValueChanges({ dayrate: parseInt(target.value) })}
        />
      </Stack>
    </Stack>
  );
}

ContractMainFields.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  errors: PropTypes.object,
  tempState: PropTypes.object
};

ContractMainFields.defaultProps = {
  tempState: {},
  errors: {}
};

export default ContractMainFields;
