import React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField, Typography } from '@esgian/esgianui';
import { OperatorSelect } from '@components/Inputs';
import RigSelect from '@components/Inputs/RigSelect/RigSelect';
import { getValueObj } from '@helpers/helpers';
import { FLOW } from '@store/AppReducer';

function ContractGeneralFields({ flow, handleValueChanges, tempState, errors }) {
  const { operatorId, operator, nrOfWells, rigId } = tempState;

  return (
    <Stack spacing={2}>
      <Typography bold>General info</Typography>
      <Stack>
        <Typography variant={'body2'}>RIG*</Typography>
        <RigSelect
          flow={flow}
          handleChange={(val) => handleValueChanges({ rigId: val })}
          selectedRig={rigId}
        />
      </Stack>
      <Stack>
        <Typography color={errors.operator ? 'error' : 'text.primary'} variant={'body2'}>
          OPERATOR*
        </Typography>
        <OperatorSelect
          flow={flow}
          error={errors.operator}
          onChange={(_, val) =>
            handleValueChanges({ operatorId: parseInt(val.id), operator: val.name })
          }
          selectedValues={getValueObj(operatorId, operator)}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>NUMBER OF WELLS</Typography>
        <TextField
          InputProps={{
            inputProps: { min: 0 }
          }}
          type={'number'}
          value={nrOfWells || ''}
          variant={'outlined'}
          placeholder={'Number of wells...'}
          onChange={({ target }) => {
            if (parseInt(target.value) > -1) {
              handleValueChanges({ nrOfWells: parseInt(target.value) });
            } else {
              handleValueChanges({ nrOfWells: '' });
            }
          }}
        />
      </Stack>
    </Stack>
  );
}

ContractGeneralFields.propTypes = {
  flow: PropTypes.oneOf(Object.values(FLOW)),
  handleValueChanges: PropTypes.func.isRequired,
  tempState: PropTypes.object,
  errors: PropTypes.object,
  isRequirement: PropTypes.bool
};

ContractGeneralFields.defaultProps = {
  flow: FLOW.OIL_COMPANY,
  errors: {},
  tempState: {},
  isRequirement: false
};

export default ContractGeneralFields;
