import React from 'react';
import PropTypes from 'prop-types';
import { DeleteModal, Grid, Stack, Typography } from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { ACTIONS, FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { useParams } from 'react-router-dom';

function RemoveRigModule({ handleClose, rigId }) {
  const params = useParams();

  const { dispatch } = useStore();

  const handleDeleteClick = () => {
    dispatch({
      type: RIG_SUPPLIER_ACTIONS.REMOVE_RIG_FROM_PROJECT,
      flow: FLOW.RIG_SUPPLIER,
      payload: { projectId: params.projectId, id: rigId }
    });
    handleClose();
  };

  return (
    <DeleteModal
      PaperProps={{ sx: { maxWidth: 'fit-content' } }}
      handleDeleteClick={handleDeleteClick}
      onClose={handleClose}
      open={!!rigId}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2} alignItems={'center'}>
            <Typography variant={'h4'}>Are you sure?</Typography>
            <Typography variant={'body2'}>This action can not be undone</Typography>
          </Stack>
        </Grid>
      </Grid>
    </DeleteModal>
  );
}

RemoveRigModule.propTypes = {
  rigId: PropTypes.number,
  handleClose: PropTypes.func.isRequired
};

RemoveRigModule.defaultProps = {
  rigId: null
};

export default RemoveRigModule;
