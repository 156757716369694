import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';

function RigInfoValueDisplay({ textTransform, disableBoldTitle, variant, title, value }) {
  return (
    <Stack
      direction={'row'}
      sx={{ textTransform: textTransform }}
      justifyContent={'space-between'}
      spacing={2}>
      <Typography variant={variant} bold={!disableBoldTitle}>
        {title}
      </Typography>
      <Typography variant={variant} sx={{ textTransform: textTransform }}>
        {value || '-'}
      </Typography>
    </Stack>
  );
}

RigInfoValueDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  textTransform: PropTypes.string,
  disableBoldTitle: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ])
};

RigInfoValueDisplay.defaultProps = {
  variant: 'body2',
  textTransform: 'uppercase',
  value: '',
  disableBoldTitle: false
};

export default RigInfoValueDisplay;
