import React from 'react';
import {
  AnalysisIcon,
  DashboardIcon,
  InsightsOutlinedIcon,
  MonetizationOnOutlinedIcon,
  ReportIcon,
  RigRequirementsIcon,
  RigDatabaseIcon
} from '@esgian/esgianui';

export const getSideMenuList = (routeChange) => {
  return [
    {
      key: 'dashboard',
      id: 'dashboard-menu',
      icon: <DashboardIcon color={'inherit'} />,
      text: 'Dashboard',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/dashboard', '_blank');
      }
    },
    {
      key: 'rig-database',
      id: 'rig-database-menu',
      icon: <RigDatabaseIcon fontSize={'small'} color={'inherit'} />,
      text: 'Rig database',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/rig-database/drilling', '_blank');
      }
    },
    {
      key: 'demand-analysis',
      id: 'demand-analysis-menu',
      icon: <AnalysisIcon color={'inherit'} />,
      text: 'Demand analysis',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/demand-analytics/forecast', '_blank');
      }
    },
    {
      key: 'rig-requirements',
      id: 'rig-requirements-menu',
      icon: <RigRequirementsIcon color={'inherit'} />,
      text: 'Rig Requirements ',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/known-rig-requirements', '_blank');
      }
    },
    {
      key: 'market-planner',
      id: 'market-planner-menu',
      icon: <InsightsOutlinedIcon color={'inherit'} />,
      text: 'Market planner ',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/known-rig-requirements', '_blank');
      }
    },
    {
      key: 'day-rates',
      id: 'day-rates-menu',
      icon: <MonetizationOnOutlinedIcon color={'inherit'} />,
      text: 'Day rates ',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/dayrates/current', '_blank');
      }
    },
    {
      key: 'fid-well',
      id: 'fid-well-menu',
      icon: <DashboardIcon color={'inherit'} />,
      text: 'FID & Well results ',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/results/fid', '_blank');
      }
    },
    {
      key: 'reports',
      id: 'reports-menu',
      icon: <ReportIcon color={'inherit'} />,
      text: 'Reports ',
      onClick: () => {
        window.open('https://riganalytics.esgian.com/reports', '_blank');
      }
    }
  ];
};
