import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid, Modal, TextField, Typography } from '@esgian/esgianui';
import { FLOW, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { snackbar } from '@mobiscroll/react';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';
import RequirementGeneralFields from '@components/Modals/AddEditRequirementModal/RequirementGeneralFields';
import RequirementMainFields from '@components/Modals/AddEditRequirementModal/RequirementMainFields';
import moment from 'moment/moment';
import RequirementTechFields from '@components/Modals/AddEditRequirementModal/RequirementTechFields';
import RequirementGeoFields from '@components/Modals/AddEditRequirementModal/RequirementGeoFields';
import { getProject } from '@helpers/helpers';

const checkRequiredFields = (tempState, setError) => {
  const { operatorId, rigTypes, startDate, duration, country, contractStatusId } = tempState;
  let errors = {
    startDate: !startDate ? 'Required' : moment(startDate).isValid() ? null : 'Invalid start date',
    operator: !operatorId ? 'Required' : null,
    rigTypes: !rigTypes?.length ? 'Required' : null,
    duration: !duration ? 'Required' : null,
    country: !country ? 'Required' : null,
    contractStatus: !contractStatusId ? 'Required' : null
  };
  setError(errors);
  return Object.entries(errors).filter(([_, v]) => v != null).length === 0;
};

function AddEditRequirementModal({ isManualOriginal, flow, open, requirement, handleClose }) {
  const [tempState, setTempState] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorsEnabled, setErrorsEnabled] = useState(false);
  const [errors, setErrors] = useState({
    startDate: null,
    operator: null,
    rigTypes: null,
    duration: null,
    contractStatus: null,
    country: null
  });
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const params = useParams();
  useEffect(() => {
    if (!errorsEnabled) return;
    !checkRequiredFields(tempState, setErrors);
  }, [
    errorsEnabled,
    tempState.startDate,
    tempState.operator,
    tempState.rigTypes,
    tempState.duration,
    tempState.contractStatus,
    tempState.country
  ]);

  useEffect(() => {
    if (!requirement) return;
    setTempState({ ...requirement });
  }, [requirement, open]);

  const onClose = useCallback(() => {
    setTempState({});
    setErrorsEnabled(false);
    handleClose();
  }, []);

  const handleValueChanges = useCallback(
    (val) => {
      setTempState({ ...tempState, ...val });
    },
    [tempState]
  );

  const handleModalSave = () => {
    setErrorsEnabled(true);
    const projectId = parseInt(params.projectId);
    if (!checkRequiredFields(tempState, setErrors)) return;
    setLoading(true);
    let action = OIL_COMPANY_ACTIONS.ADD_CUSTOM_REQUIREMENT;
    let message = 'Requirement created';
    let payload = {
      projectId: projectId,
      requirement: {
        ...tempState,
        projectName: tempState.projectName || null,
        dateFlexibility: tempState.dateFlexibility || 0
      }
    };

    if (requirement && flow === FLOW.OIL_COMPANY) {
      message = 'Requirement updated';
      action = OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT;
    }
    if (!requirement && flow === FLOW.OIL_COMPANY) {
      const { operators } = getProject(projects, projectId);
      if (!operators?.some(({ id }) => id === tempState.operatorId)) {
        dispatch({
          type: OIL_COMPANY_ACTIONS.ADD_OPERATORS_TO_PROJECT,
          flow: FLOW.OIL_COMPANY,
          payload: {
            projectId: params.projectId,
            operators: [{ name: tempState.operator, id: tempState.operatorId }]
          }
        });
      }
    }

    if (flow === FLOW.RIG_SUPPLIER) {
      if (!requirement) {
        message = 'Requirement created';
        action = RIG_SUPPLIER_ACTIONS.CREATE_REQUIREMENT;
        payload = {
          requirement: { ...tempState, ...{ rigId: null, rigName: null, isManualCreate: true } },
          projectId: projectId
        };
      } else {
        message = 'Requirement updated';
        action = RIG_SUPPLIER_ACTIONS.UPDATE_REQUIREMENT;
        payload = {
          requirement: requirement,
          projectId: projectId,
          newFields: { ...tempState, ...{ isCustom: !isManualOriginal } }
        };
      }
    }

    if (action) {
      dispatch({
        type: action,
        flow: flow,
        payload: payload
      });
      snackbar({
        message: message,
        color: 'success',
        duration: 5000,
        display: 'top'
      });
    }
    onClose();
    setLoading(false);
  };

  const { comment } = tempState || {};

  return (
    <Modal
      size={'md'}
      handleClose={onClose}
      title={`${requirement ? 'Update' : 'Create'} requirement`}
      open={open}
      actions={[
        <Button withUnderline onClick={onClose} color={'secondary'} key={'cancel-changes-module'}>
          Cancel
        </Button>,
        <Button
          color={'secondary'}
          disableElevation
          onClick={handleModalSave}
          key={'apply-changes'}
          variant={'contained'}>
          {requirement ? 'Update' : 'Create'}
        </Button>
      ]}>
      {loading ? (
        <Grid container sx={{ height: '70vh' }}>
          <Grid item xs={12} sx={{ textAlign: 'center', placeSelf: 'center' }}>
            <CircularProgress size={'5em'} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item sm={12} md={10} lg={8}>
            <RequirementGeneralFields
              errors={errors}
              handleValueChanges={handleValueChanges}
              fields={tempState}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <RequirementMainFields
              flow={flow}
              errors={errors}
              handleValueChanges={handleValueChanges}
              fields={tempState}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <RequirementGeoFields
              errors={errors}
              fields={tempState}
              handleValueChanges={handleValueChanges}
            />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <RequirementTechFields handleValueChanges={handleValueChanges} fields={tempState} />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <Typography bold>Comment</Typography>
            <TextField
              value={comment || ''}
              onChange={({ target }) => handleValueChanges({ comment: target.value })}
              variant={'outlined'}
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Modal>
  );
}

AddEditRequirementModal.propTypes = {
  requirement: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  isManualOriginal: PropTypes.bool,
  flow: PropTypes.oneOf(Object.values(FLOW))
};

AddEditRequirementModal.defaultProps = {
  requirement: null,
  open: false,
  isManualOriginal: false,
  flow: FLOW.OIL_COMPANY
};

export default AddEditRequirementModal;
