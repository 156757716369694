import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';

function ApplicableOperatorsDisplay({
  setQuery,
  query,
  filtersCount,
  setViewFilters,
  operatorsInState,
  selectedOperators,
  setSelectedOperators,
  displayOperators
}) {
  const operatorsInStateIds = useMemo(
    () => operatorsInState?.map(({ id }) => id),
    [operatorsInState]
  );

  const handleOperatorClick = useCallback(
    (value) => {
      let tempOperators = [...selectedOperators];
      let operatorIds = tempOperators?.map(({ id }) => id);
      if (operatorIds.includes(value?.id)) {
        setSelectedOperators(tempOperators.filter((item) => item.id !== value?.id));
      } else {
        tempOperators.push(value);
        setSelectedOperators(tempOperators);
      }
    },
    [selectedOperators]
  );

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <TextField
            onChange={({ target }) => setQuery(target.value)}
            value={query}
            fullWidth
            placeholder={'Search for operators...'}
          />
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center' }}>
          <Badge invisible={filtersCount <= 0} badgeContent={filtersCount} color="primary">
            <Button
              onClick={() => setViewFilters(true)}
              withUnderline
              size={'large'}
              color={'secondary'}>
              Filter
            </Button>
          </Badge>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedOperators?.length === displayOperators?.length}
            onChange={() => {
              if (selectedOperators === displayOperators) {
                setSelectedOperators([]);
              } else {
                setSelectedOperators(displayOperators);
              }
            }}
          />
        }
        label="Check all"
      />
      <Divider />
      <Typography
        variant={'body2'}>{`Displaying: ${displayOperators?.length} operators`}</Typography>
      {displayOperators?.map((item) => (
        <Paper
          key={`${item.id}-select`}
          variant={'outlined'}
          onClick={() => {
            if (!operatorsInStateIds?.includes(item.id)) {
              handleOperatorClick(item);
            }
          }}
          sx={{
            cursor: operatorsInStateIds?.includes(item.id) ? '' : 'pointer',
            p: 2,
            color: () => {
              if (operatorsInStateIds?.includes(item.id)) {
                return 'rgba(0, 0, 0, 0.26)';
              }
              return '';
            },
            background: (theme) => {
              if (operatorsInStateIds?.includes(item.id)) {
                return theme.palette.common.lightGray;
              } else {
                return selectedOperators?.map(({ id }) => id).includes(item.id)
                  ? `${theme.palette.primary.light}4d`
                  : theme.palette.background.paper;
              }
            }
          }}>
          <Stack>
            <Typography variant={'body1'}>{item.name}</Typography>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );
}

ApplicableOperatorsDisplay.propTypes = {
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.string,
  setViewFilters: PropTypes.func.isRequired,
  filtersCount: PropTypes.number,
  operatorsInState: PropTypes.arrayOf(PropTypes.object),
  selectedOperators: PropTypes.arrayOf(PropTypes.object),
  setSelectedOperators: PropTypes.func.isRequired,
  displayOperators: PropTypes.arrayOf(PropTypes.object)
};

ApplicableOperatorsDisplay.defaultProps = {
  query: '',
  filtersCount: 0,
  displayOperators: [],
  selectedOperators: [],
  operatorsInState: []
};

export default ApplicableOperatorsDisplay;
