import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CloseIcon,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@esgian/esgianui';

import RequirementsGeneralFilters from './RequirementsGeneralFilters';
import { useStore } from '@store/Store';
import { getProject } from '@helpers/helpers';
import { useParams } from 'react-router-dom';
import { FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { snackbar } from '@mobiscroll/react';
import { getRigDemandFilterDefaults } from '@helpers/RigDemandHelpers';

function RequirementsFilterDrawer({ open, handleClose }) {
  const [filters, setFilters] = useState(null);
  const params = useParams();
  const {
    dispatch,
    state: {
      filterLookups,
      projectsData: { projects }
    }
  } = useStore();

  const projectId = useMemo(() => {
    return params.projectId;
  }, [params.projectId]);

  useEffect(() => {
    const { filters: projectFilters } = getProject(projects, projectId);
    setFilters({ ...projectFilters.rigDemand });
  }, [JSON.stringify(projects), projectId]);

  const handleFilterChange = useCallback(
    (val) => {
      setFilters({ ...filters, ...val });
    },
    [filters]
  );

  const setFiltersToState = useCallback(
    (filters) => {
      dispatch({
        type: RIG_SUPPLIER_ACTIONS.UPDATE_RIG_DEMAND_FILTERS,
        flow: FLOW.RIG_SUPPLIER,
        payload: {
          projectId: projectId,
          filters: filters
        }
      });
      snackbar({
        message: 'Filters updated',
        color: 'success',
        duration: 5000,
        display: 'top'
      });
      handleClose();
    },
    [projectId]
  );

  let defaultFilters = useMemo(() => {
    return getRigDemandFilterDefaults(filterLookups);
  }, [filterLookups]);

  return (
    <Drawer open={open} onClose={handleClose} anchor={'right'}>
      <Grid
        container
        sx={{
          width: '25vw',
          maxHeight: '100%'
        }}>
        <Grid sx={{ height: '57px' }} item xs={12}>
          <Grid justifyContent={'space-between'} container>
            <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <Typography variant={'h6'} color={'text.primary'}>
                Filters
              </Typography>
            </Grid>
            <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack
            justifyContent={'space-between'}
            sx={{ overflowX: 'hidden', height: 'calc(100vh - 57px - 70px )', overflowY: 'auto' }}>
            <Stack>
              <RequirementsGeneralFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            </Stack>
            <Stack
              direction={'row'}
              sx={{
                p: 2,
                boxShadow: '0 0 8px rgb(0 0 0 / 18%)',
                position: 'fixed',
                bottom: 0,
                height: '70px',
                background: (theme) => theme.palette.background.paper,
                width: '25vw'
              }}
              justifyContent={'space-between'}>
              <Button
                onClick={() => setFiltersToState(defaultFilters)}
                variant={'text'}
                withUnderline
                color={'secondary'}>
                Clear All
              </Button>
              <Button
                disableElevation
                onClick={() => setFiltersToState(filters)}
                variant={'contained'}>
                Apply
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}

RequirementsFilterDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

RequirementsFilterDrawer.defaultProps = {
  open: null
};

export default RequirementsFilterDrawer;
