import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { Stack, Typography } from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import { getValueObj } from '@helpers/helpers';

function RequirementGeoFields({ handleValueChanges, fields, errors }) {
  const {
    state: { filterLookups }
  } = useStore();

  const { region: lookupRegion, country: lookupCountry } = filterLookups || {};
  const { countryId, country } = fields;

  const handleCountrySelect = useCallback(
    (_, val) => {
      let region = lookupRegion?.find(({ id }) => id === val.regionId) || {};

      handleValueChanges({
        countryId: parseInt(val.id),
        country: val.name,
        regionId: region.id,
        region: region.name
      });
    },
    [lookupRegion, handleValueChanges]
  );

  return (
    <Stack spacing={1}>
      <Typography bold>Geography</Typography>
      <Stack spacing={2}>
        <Stack>
          <Typography color={errors.country ? 'error' : 'text.primary'} variant={'body2'}>
            COUNTRY*
          </Typography>
          <LookupSelect
            error={!!errors.country && !countryId ? errors.country : null}
            id={'requirement-country-select'}
            handleChange={handleCountrySelect}
            value={getValueObj(countryId, country)}
            options={lookupCountry}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

RequirementGeoFields.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  fields: PropTypes.object,
  errors: PropTypes.object
};

RequirementGeoFields.defaultProps = {
  fields: {},
  errors: {}
};

export default RequirementGeoFields;
