import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, CircularProgress, TextField } from '@esgian/esgianui';
import { useStore } from '@store/Store';

function OperatorSelect({ multiple, disabled, selectedValues, id, onChange, error }) {
  const {
    state: { filterLookups }
  } = useStore();
  const { rigsOperators } = filterLookups || {};

  return (
    <Autocomplete
      multiple={multiple}
      loading={!rigsOperators?.length}
      id={id}
      disabled={disabled}
      value={selectedValues || null}
      onChange={onChange}
      fullWidth
      options={rigsOperators || []}
      isOptionEqualToValue={(option, value) => {
        if (rigsOperators.length) {
          return option.id === value.id;
        }
        return false;
      }}
      getOptionLabel={(option) => {
        return option?.name;
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          error={!!error}
          helperText={error || ''}
          placeholder={'Select operator...'}
          {...params}
          label={rigsOperators?.length ? '' : <CircularProgress size={25} />}
        />
      )}
    />
  );
}

OperatorSelect.propTypes = {
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValues: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

OperatorSelect.defaultProps = {
  disabled: false,
  multiple: false,
  selectedValues: null,
  id: 'operator-select'
};

export default OperatorSelect;
