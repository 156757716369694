import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import RigDisplayBox from '@components/Drawers/RequirementEligibleRigsDrawer/RequirementEligibleRigsFilters/RigDisplayBox';

function ApplicableRigsDisplay({
  setViewFilters,
  setSelectedRigs,
  numberOfRigsDisplay,
  setNumberOfRigsDisplay,
  filtersCount,
  selectedRigs,
  rigsInState,
  displayRigs,
  setQuery,
  query
}) {
  const handleRigClick = useCallback(
    (value) => {
      let tempRigs = [...selectedRigs];
      let rigIds = tempRigs?.map(({ rigId }) => rigId);
      if (rigIds.includes(value?.rigId)) {
        setSelectedRigs(tempRigs.filter((item) => item.rigId !== value?.rigId));
      } else {
        tempRigs.push(value);
        setSelectedRigs(tempRigs);
      }
    },
    [selectedRigs]
  );

  const disableRigs = useMemo(() => {
    return rigsInState?.map(({ rigId }) => rigId) || [];
  }, [rigsInState]);

  const validRigsForSelect = useMemo(() => {
    return displayRigs?.filter(({ rigId }) => !disableRigs.includes(rigId));
  }, [disableRigs, displayRigs]);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <TextField
            onChange={({ target }) => setQuery(target.value)}
            value={query}
            fullWidth
            placeholder={'Search for rig...'}
          />
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center' }}>
          <Badge invisible={filtersCount <= 0} badgeContent={filtersCount} color="primary">
            <Button
              onClick={() => {
                setNumberOfRigsDisplay(10);
                setViewFilters(true);
              }}
              withUnderline
              size={'large'}
              color={'secondary'}>
              Filter
            </Button>
          </Badge>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              !!validRigsForSelect?.length && selectedRigs?.length === validRigsForSelect?.length
            }
            onChange={() => {
              if (selectedRigs?.length === validRigsForSelect?.length) {
                setSelectedRigs([]);
              } else {
                setSelectedRigs(validRigsForSelect);
              }
            }}
          />
        }
        label="Check all"
      />
      <Divider />
      <Typography variant={'body2'}>{`Displaying: ${displayRigs?.length} rigs`}</Typography>
      {[...displayRigs]
        .slice(0, numberOfRigsDisplay)
        ?.map(({ rigId, rigName, rigType, region, rigStatus, rigStatusId, rigTypeId }) => (
          <RigDisplayBox
            disabled={disableRigs.includes(rigId)}
            key={`${rigId}-display-elig`}
            handleRigClick={handleRigClick}
            selectedRigs={selectedRigs}
            rigId={rigId}
            rigName={rigName}
            rigTypeId={rigTypeId}
            rigType={rigType}
            region={region}
            rigStatus={rigStatus}
            rigStatusId={rigStatusId}
          />
        ))}
    </Stack>
  );
}

ApplicableRigsDisplay.propTypes = {
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.string,
  filtersCount: PropTypes.number,
  displayRigs: PropTypes.arrayOf(PropTypes.object),
  selectedRigs: PropTypes.arrayOf(PropTypes.object),
  rigsInState: PropTypes.arrayOf(PropTypes.object),
  setSelectedRigs: PropTypes.func.isRequired,
  numberOfRigsDisplay: PropTypes.number.isRequired,
  setNumberOfRigsDisplay: PropTypes.func.isRequired,
  setViewFilters: PropTypes.func.isRequired
};

ApplicableRigsDisplay.defaultProps = {
  query: '',
  filtersCount: 0,
  displayRigs: [],
  rigsInState: [],
  selectedRigs: []
};

export default ApplicableRigsDisplay;
