import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import { useStore } from '@store/Store';
import { getValueObj } from '@helpers/helpers';
import { TextWithTooltipIcon } from '@components/Display';

function ContractGeographyFields({ handleValueChanges, tempState }) {
  const {
    state: { filterLookups }
  } = useStore();

  const { region: lookupRegion, country: lookupCountry } = filterLookups || {};
  const { countryId, country } = tempState;

  const handleCountrySelect = useCallback(
    (_, val) => {
      let region = lookupRegion?.find(({ id }) => id === val.regionId) || {};

      handleValueChanges({
        countryId: parseInt(val.id),
        country: val.name,
        regionId: region.id,
        region: region.name
      });
    },
    [lookupRegion]
  );

  return (
    <Stack spacing={2}>
      <Typography bold>Geography</Typography>

      <Stack>
        <TextWithTooltipIcon
          label={'COUNTRY'}
          tooltipText={'Region will automatically be set based on the selected country'}
          textVariant={'body2'}
        />
        <LookupSelect
          id={'contract-country-select'}
          handleChange={handleCountrySelect}
          value={getValueObj(countryId, country)}
          options={lookupCountry}
        />
      </Stack>
    </Stack>
  );
}

ContractGeographyFields.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  tempState: PropTypes.object
};

ContractGeographyFields.defaultProps = {
  tempState: {}
};

export default ContractGeographyFields;
