import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, ReportIcon, Stack, Typography } from '@esgian/esgianui';

import RigDemandTimelineContainer from '@components/Sections/OilCompanySection/RigDemandTimelineContainer/RigDemandTimelineContainer';
import SaveToFileButton from '../../Inputs/SaveToFileButton';
import { getProject } from '@helpers/helpers';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { useStore } from '@store/Store';
import { useExports } from '@hooks/useExports';

function OilCompanySection({ project }) {
  const params = useParams();
  const { exportPdf } = useExports();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'h5'} bold>
            WORKBOOK
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'text'}
              onClick={exportPdf}
              sx={{ color: '#000000' }}
              startIcon={<ReportIcon color={'inherit'} />}>
              Export
            </Button>
            <SaveToFileButton projectId={parseInt(params.projectId)} />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <RigDemandTimelineContainer project={project} />
      </Grid>
    </Grid>
  );
}

OilCompanySection.propTypes = {
  project: PropTypes.object.isRequired
};

OilCompanySection.defaultProps = {};

export default OilCompanySection;
