import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, CircularProgress, TextField } from '@esgian/esgianui';

function LookupSelect({
  getOptionDisabled,
  multiple,
  id,
  disabled,
  options,
  value,
  error,
  handleChange
}) {
  return (
    <Autocomplete
      getOptionDisabled={getOptionDisabled}
      disableCloseOnSelect={multiple}
      multiple={multiple}
      loading={!options?.length}
      id={id}
      disabled={disabled}
      value={value || null}
      onChange={handleChange}
      fullWidth
      options={options || []}
      getOptionLabel={(option) => {
        return option?.name || '';
      }}
      isOptionEqualToValue={(option, value) => {
        if (options.length) {
          return option.id === value.id;
        }
        return true;
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          error={!!error}
          helperText={error || ''}
          placeholder={'Select...'}
          {...params}
          label={options?.length ? '' : <CircularProgress size={25} />}
        />
      )}
    />
  );
}

LookupSelect.propTypes = {
  disabled: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  multiple: PropTypes.bool,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  handleChange: PropTypes.func.isRequired
};

LookupSelect.defaultProps = {
  multiple: false,
  disabled: false,
  value: null,
  getOptionDisabled: undefined,
  options: [],
  error: null
};

export default LookupSelect;
