import React from 'react';
import PropTypes from 'prop-types';
import RigInfoValueDisplay from '@components/Display/RigInfoValueDisplay/RigInfoValueDisplay';
import { getNumberString } from '@helpers/helpers';
import moment from 'moment';
import { Stack, Typography } from '@esgian/esgianui';
import { Popup } from '@mobiscroll/react';

function ContractTooltip({ anchor, details }) {
  const {
    operator,
    region,
    country,
    contractStatus,
    dayrate,
    startDate,
    endDate,
    duration,
    projectName,
    nrOfWells,
    comment,
    contractId
  } = details || {};

  let nameDisplay = projectName || 'Contract';

  return (
    <Popup
      maxWidth={'25em'}
      display="anchored"
      isOpen={true}
      themeVariant={'dark'}
      anchor={anchor}
      touchUi={false}
      showOverlay={false}
      contentPadding={false}
      closeOnOverlayClick={false}
      cssClass="timeline-tooltip">
      <div>
        <Stack sx={{ p: 2 }} spacing={1}>
          <Typography>{nameDisplay}</Typography>
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={operator}
            title={'Operator:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={region}
            title={'Region:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={country}
            title={'Country:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={contractStatus}
            title={'Contract type:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={dayrate ? `$${getNumberString(dayrate)}` : '-'}
            title={'Day rate:'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={moment(startDate).format('DD/MM/yyyy')}
            title={'Start date: '}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={moment(endDate).format('DD/MM/yyyy')}
            title={'End date: '}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={duration}
            title={'Duration(Days):'}
          />
          <RigInfoValueDisplay
            textTransform={'none'}
            disableBoldTitle
            value={nrOfWells}
            title={'Number of Wells:'}
          />
          {comment && <div style={{ width: '100%', borderBottom: '1px solid #7a7a7b' }} />}
          {comment && (
            <Stack>
              <Typography>Comment</Typography>
              <Typography variant={'caption'}>{comment}</Typography>
            </Stack>
          )}
        </Stack>
      </div>
    </Popup>
  );
}

ContractTooltip.propTypes = { anchor: PropTypes.object, details: PropTypes.object };

ContractTooltip.defaultProps = { anchor: null, details: null };

export default ContractTooltip;
