import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CONTRACT_STATUSES } from '@constants';
import {
  Badge,
  InfoOutlineIcon,
  InsertCommentOutlinedIcon,
  Stack,
  Typography
} from '@esgian/esgianui';
import moment from 'moment';
import { Draggable } from '@mobiscroll/react';
import { RequirementTooltip } from '@components/Timeline';
import { FLOW } from '@store/AppReducer';

function RequirementEvent({ flow, event, chartSettings, showStatus }) {
  const [tooltipAnchor, setTooltipAnchor] = useState(null);
  const [myEl, setMyEl] = useState();
  const {
    country: showCountry,
    region: showRegion,
    rigType: showType,
    contractType: showContractType,
    duration: showDuration
  } = chartSettings;
  const original = { ...event.original };
  const taskType = original.taskType - 1;
  const statusObj = CONTRACT_STATUSES[taskType];

  let color = statusObj?.color;
  if (!showStatus) {
    color = '#7519a9';
  }

  let title =
    original.title === '' || original.title === null
      ? `${original.details.operator} | ${original.details.country}`
      : `${original.details.operator} | ${event.title}`;
  let dragData = {
    title: original.title,
    start: event.original.start,
    end: event.original.end,
    type: original.type,
    prevResource: original.resource,
    details: original.details,
    taskType: original.taskType
  };

  let geoString = null;
  if (showRegion && showCountry) {
    geoString = `${original.details.country}, ${original.details.region}`;
  } else if (showCountry) {
    geoString = original.details.country;
  } else if (showRegion) {
    geoString = original.details.region;
  }
  const { rigTypes, contractStatus, comment, isCustom, count } = original?.details || {};
  let background = '';
  let textColor = 'rgba(0, 0, 0, 0.87)';
  if ((statusObj.id === 6 && showStatus) || (!showStatus && !original.details.isRequirement)) {
    textColor = '#fff';
  }

  if (!showStatus && original.details.isRequirement) {
    background = 'rgb(255 255 255 / 80%)';
  }

  if (showStatus && !statusObj.isFixed) {
    background = 'rgb(255 255 255 / 80%)';
  }

  const onMouseEnter = (event) => {
    setTooltipAnchor(event.currentTarget);
  };
  const onMouseLeave = () => {
    setTooltipAnchor(null);
  };

  return (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
          // cursor: eventDraggable ? 'pointer' : 'default',
          display: 'block',
          color: color,
          background: color,
          borderRadius: '10px',
          border: `1px solid ${color}`,
          margin: '0px 0 0px 0'
        }}
        ref={setMyEl}>
        <div
          style={{
            padding: '4px',
            borderRadius: '9px',
            background: background
          }}>
          <Stack spacing={0.5} sx={{ pl: 1 }}>
            <Badge
              sx={{
                top: ' -0.2em',
                left: 'calc(100% - 0.1em)'
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              badgeContent={count || null}
              invisible={!isCustom && !count}
              variant={isCustom ? 'dot' : 'standard'}
              color="warning"
            />
            <div
              className={'mbsc-schedule-event-title mbsc-material'}
              style={{
                minHeight: '24px',
                fontSize: '0.85rem',
                color: textColor
              }}>
              {title}
              {comment && (
                <span style={{ fontSize: '1rem', paddingLeft: '5px' }}>
                  <InsertCommentOutlinedIcon fontSize={'inherit'} />
                </span>
              )}
            </div>

            {(showCountry || showRegion) && geoString && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {geoString}
              </Typography>
            )}
            {showType && rigTypes?.length && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {rigTypes?.map(({ name }) => name)?.join(',')}
              </Typography>
            )}
            {showContractType && contractStatus && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {contractStatus}
              </Typography>
            )}
            {showDuration && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {moment(event.start).format('DD/MM/yyyy')}-{moment(event.end).format('DD/MM/yyyy')}
              </Typography>
            )}
          </Stack>
        </div>
        <Draggable touchUi={true} dragData={dragData} element={myEl} />
      </div>

      {!!tooltipAnchor && (
        <RequirementTooltip details={event?.original?.details} anchor={tooltipAnchor} />
      )}
    </>
  );
}

RequirementEvent.propTypes = {
  event: PropTypes.object,
  chartSettings: PropTypes.object,
  showStatus: PropTypes.bool,
  flow: PropTypes.oneOf(Object.values(FLOW))
};

RequirementEvent.defaultProps = {
  event: {},
  chartSettings: {},
  showStatus: false,
  flow: FLOW.OIL_COMPANY
};

export default RequirementEvent;
