import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ChecklistIcon,
  CloseIcon,
  DeleteOutlineIcon,
  Divider,
  Grid,
  IconButton,
  PlusIcon,
  ResetIcon,
  Stack,
  Typography
} from '@esgian/esgianui';
import { TimelineLegend } from '@components';
import { RigsChartSettingButton } from '@components/Sections/RigSupplierSection/Menues';
import RigDemandTimeline from '@components/Sections/OilCompanySection/RigDemandTimeline';
import AddRequirementDrawer from '../Drawers/AddRequirementDrawer';
import { AddEditRequirementModal } from '@components/Modals';
import { useStore } from '@store/Store';
import { FLOW, GENERAL_ACTIONS, OIL_COMPANY_ACTIONS } from '@store/AppReducer';
import { useParams } from 'react-router-dom';
import SelectedTimelineResourcesDisplay from '../../../Display/SelectedTimelineResourcesDisplay';
import Swal from 'sweetalert2';
import { snackbar } from '@mobiscroll/react';
import DemandTimelineTwo from '@components/Sections/OilCompanySection/RigDemandTimeline/DemandTimelineTwo';

function RigDemandTimelineContainer({ project }) {
  const [showStatus, setShowStatus] = useState(false);
  const [requirementModalOpen, setRequirementModalOpen] = useState(false);
  const [editRequirement, setEditRequirement] = useState(null);
  const [addRequirementOpen, setAddRequirementOpen] = useState(false);

  return (
    <>
      <Grid container spacing={2}>
        <TimelineLegend setShowStatus={setShowStatus} showStatus={showStatus} />
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography bold>Rig Demand</Typography>
              <Typography variant={'body2'}>
                Displaying {project?.operators?.length || 0} operators
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}
              spacing={2}>
              <Stack direction={'row'} spacing={2}>
                <Button
                  variant={'text'}
                  key={'create-requirement'}
                  onClick={() => setRequirementModalOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  Create requirement
                </Button>
                <Button
                  variant={'text'}
                  key={'add-requirement'}
                  onClick={() => setAddRequirementOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  add requirements
                </Button>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <RigsChartSettingButton />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <RigDemandTimeline
              showStatus={showStatus}
              setRequirementModalOpen={setRequirementModalOpen}
              setAddRequirementOpen={setAddRequirementOpen}
            />
          </Stack>
        </Grid>
      </Grid>
      <AddRequirementDrawer setOpen={setAddRequirementOpen} open={addRequirementOpen} />
      <AddEditRequirementModal
        flow={FLOW.OIL_COMPANY}
        open={requirementModalOpen}
        requirement={editRequirement}
        handleClose={() => {
          setRequirementModalOpen(false);
          setEditRequirement(null);
        }}
      />
    </>
  );
}

RigDemandTimelineContainer.propTypes = {
  project: PropTypes.object.isRequired
};

RigDemandTimelineContainer.defaultProps = {};

export default RigDemandTimelineContainer;
