import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { MenuItem, Select } from '@esgian/esgianui';
import { getProject } from '@helpers/helpers';
import { useParams } from 'react-router-dom';
import { FLOW } from '@store/AppReducer';
const sortRigs = (a, b) => {
  if (a.rigName < b.rigName) {
    return -1;
  }
  if (a.rigName > b.rigName) {
    return 1;
  }
  return 0;
};
function RigSelect({ error, flow, selectedRig, handleChange }) {
  const [options, setOptions] = useState([]);
  const params = useParams();
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    }
  } = useStore();
  const { rigs: lookupRigs = [] } = filterLookups;

  useEffect(() => {
    const projectId = params.projectId;
    const { rigs } = getProject(projects, projectId);
    if (flow === FLOW.OIL_COMPANY) {
      setOptions(lookupRigs);
    } else {
      if (rigs.length) {
        setOptions(rigs.sort(sortRigs));
      }
    }
  }, [lookupRigs]);

  return (
    <Select
      error={!!error}
      variant={'outlined'}
      value={options.length ? selectedRig : ''}
      onChange={({ target }) => handleChange(target.value)}>
      {options?.map(({ rigId, rigName }) => (
        <MenuItem key={`rig-select-${rigId}`} value={rigId}>
          {rigName}
        </MenuItem>
      ))}
    </Select>
  );
}

RigSelect.propTypes = {
  flow: PropTypes.oneOf(Object.values(FLOW)),
  disabled: PropTypes.bool,
  selectedRig: PropTypes.number,
  id: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

RigSelect.defaultProps = {
  flow: FLOW.OIL_COMPANY,
  disabled: false,
  selectedRig: null,
  id: 'rig-select'
};

export default RigSelect;
