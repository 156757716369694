import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import { useStore } from '@store/Store';
import { CONTRACT_STATUSES } from '@constants';
import { TextWithTooltipIcon } from '@components/Display';
import moment from 'moment/moment';
import PeriodSelect from '../../../../../Inputs/PeriodSelect';

function OperatorsFilterContainer({ filters, setFilters }) {
  const {
    state: { filterLookups }
  } = useStore();
  const {
    region: lookupRegion = [],
    country: lookupCountry = [],
    rigsType: lookupRigTypes = []
  } = filterLookups || {};

  const handleFilterChange = (val) => {
    setFilters({ ...filters, ...val });
  };

  const { regions, countries, rigTypes, contractStatuses, startPeriod } = filters;
  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Stack>
        <Typography variant={'body2'}>CONTRACT STATUSES</Typography>
        <LookupSelect
          id={'contract-status-select'}
          handleChange={(_, val) =>
            handleFilterChange({ contractStatuses: { type: 'multiselect', value: val } })
          }
          value={contractStatuses?.value}
          options={CONTRACT_STATUSES}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>REGIONS</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ regions: { type: 'multiselect', value: val } })
          }
          value={regions?.value || []}
          id={'operator-region-filter'}
          options={lookupRegion}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>COUNTRIES</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ countries: { type: 'multiselect', value: val } })
          }
          value={countries?.value || []}
          id={'operator-country-filter'}
          options={lookupCountry}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG TYPES</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ rigTypes: { type: 'multiselect', value: val } })
          }
          value={rigTypes?.value || []}
          id={'operator-rig-types-filter'}
          options={lookupRigTypes}
          multiple
        />
      </Stack>
      <PeriodSelect
        rangeEndLabel={'Latest start'}
        rangeStartLabel={'Earliest start'}
        required
        startPlaceholder={'Earliest start date...'}
        endPlaceholder={'Latest start date...'}
        startTitle={
          <TextWithTooltipIcon
            textVariant={'body2'}
            label={'EARLIEST START DATE*'}
            tooltipText={'The earliest date a requirement can start.'}
          />
        }
        endTitle={
          <TextWithTooltipIcon
            textVariant={'body2'}
            label={'LATEST START DATE'}
            tooltipText={'The latest date a requirement can start.'}
          />
        }
        onChange={({ value }) => {
          handleFilterChange({
            startPeriod: {
              type: 'date',
              startDate: moment(value[0]).format('yyyy-MM-DD'),
              endDate: moment(value[1]).format('yyyy-MM-DD')
            }
          });
        }}
        value={[startPeriod?.startDate, startPeriod?.endDate]}
      />
    </Stack>
  );
}

OperatorsFilterContainer.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired
};

OperatorsFilterContainer.defaultProps = {
  filters: null
};

export default OperatorsFilterContainer;
