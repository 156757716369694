import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Modal, Stack, TextField, Typography } from '@esgian/esgianui';

function AddEditCommentModal({ handleClose, comment: propComment, handleApplyClick, open }) {
  const [comment, setComment] = useState(null);

  useEffect(() => {
    setComment(propComment || null);
  }, [propComment]);
  return (
    <Modal
      size={'sm'}
      handleClose={handleClose}
      title={`${propComment ? 'Edit' : 'Add'} comment `}
      open={open}
      actions={[
        <Button
          disableElevation
          onClick={handleClose}
          color={'secondary'}
          key={'cancel-changes-module'}>
          Cancel
        </Button>,
        <Button
          disabled={!propComment && (comment === '' || comment === null)}
          color={'secondary'}
          disableElevation
          onClick={() => handleApplyClick(comment)}
          key={'apply-changes'}
          variant={'contained'}>
          {propComment ? 'Update' : 'Add'}
        </Button>
      ]}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant={'body2'}>COMMENT</Typography>
            <TextField
              placeholder={'Write down comment...'}
              onChange={({ target }) => setComment(target.value)}
              value={comment || ''}
              variant={'outlined'}
              multiline
              minRows={4}
              fullWidth
            />
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
}

AddEditCommentModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleApplyClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
  comment: PropTypes.string
};

AddEditCommentModal.defaultProps = {
  open: false,
  comment: null
};

export default AddEditCommentModal;
