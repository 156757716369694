import React, { useCallback } from 'react';
import { Button, PlusIcon } from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { FLOW, GENERAL_ACTIONS } from '@store/AppReducer';
import Swal from 'sweetalert2';
import { snackbar } from '@mobiscroll/react';

function ImportFileButton() {
  const {
    state: { projectsData },
    dispatch
  } = useStore();

  const handleChange = useCallback((e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const { version, fullProject, data } = JSON.parse(e.target.result);

      if (fullProject) {
        if (projectsData.projects?.length) {
          Swal.fire({
            title: 'Are you sure?',
            text: `Importing a project will overwrite your existing projects.`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch({
                type: GENERAL_ACTIONS.SET_PROJECTS_DATA,
                flow: FLOW.GENERAL,
                payload: { projectsData: data }
              });
            }
          });
        } else {
          dispatch({
            type: GENERAL_ACTIONS.SET_PROJECTS_DATA,
            flow: FLOW.GENERAL,
            payload: { projectsData: data }
          });
        }
      } else {
        dispatch({
          type: GENERAL_ACTIONS.CREATE_PROJECT,
          flow: FLOW.GENERAL,
          payload: { project: data }
        });
      }
    };
    snackbar({
      message: `Project(s) added`,
      color: 'success',
      duration: 5000,
      display: 'top'
    });
  }, []);

  return (
    <Button
      startIcon={<PlusIcon color={'inherit'} />}
      variant={'text'}
      component="label"
      disableElevation>
      Import a project
      <input
        key={'upload-input'}
        hidden
        type={'file'}
        id={'csvFileInput'}
        accept={'application/json'}
        onChange={handleChange}
      />
    </Button>
  );
}

ImportFileButton.propTypes = {};

ImportFileButton.defaultProps = {};

export default ImportFileButton;
