import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Stack, Typography } from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { LookupSelect } from '@components/Inputs';

function GeneralFilters({ filters, setFilters }) {
  const {
    state: { filterLookups }
  } = useStore();

  const handleFilterChange = (val) => {
    setFilters({ ...filters, ...val });
  };

  const {
    rigsType: lookupRigType,
    rigsOwner: lookupOwners,
    country: lookupCountries,
    region: lookupRegion,
    rigsManagers: lookupManagers
  } = filterLookups || {};
  const { rigTypes, owners, countries, regions, managers } = filters || {};

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant={'subtitle2'}>RIG MANAGERS</Typography>
        <LookupSelect
          disabled={!filters}
          handleChange={(_, val) =>
            handleFilterChange({ managers: { type: 'multiselect', value: val } })
          }
          value={managers?.value || []}
          id={'rigs-manager-filter'}
          options={lookupManagers}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG OWNERS</Typography>
        <LookupSelect
          disabled={!filters}
          handleChange={(_, val) =>
            handleFilterChange({ owners: { type: 'multiselect', value: val } })
          }
          value={owners?.value || []}
          id={'rigs-owner-filter'}
          options={lookupOwners}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG TYPES</Typography>
        <LookupSelect
          disabled={!filters}
          handleChange={(_, val) =>
            handleFilterChange({ rigTypes: { type: 'multiselect', value: val } })
          }
          value={rigTypes?.value || []}
          id={'rigs-rig-types-filter'}
          options={lookupRigType}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>REGIONS</Typography>
        <LookupSelect
          disabled={!filters}
          handleChange={(_, val) =>
            handleFilterChange({ regions: { type: 'multiselect', value: val } })
          }
          value={regions?.value || []}
          id={'rigs-region-filter'}
          options={lookupRegion}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>COUNTRIES</Typography>
        <LookupSelect
          disabled={!filters}
          handleChange={(_, val) =>
            handleFilterChange({ countries: { type: 'multiselect', value: val } })
          }
          value={countries?.value || []}
          id={'rigs-country-filter'}
          options={lookupCountries}
          multiple
        />
      </Stack>
    </Stack>
  );
}

GeneralFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired
};

GeneralFilters.defaultProps = {};

export default GeneralFilters;
