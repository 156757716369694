import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FilterSlider, Typography } from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { getSliderMarks } from '@helpers/helpers';
import { LookupSelect, YesNoSelect } from '@components/Inputs';
import moment from 'moment';

function AdvancedFilters({ filters, setFilters }) {
  const {
    state: { filterLookups }
  } = useStore();

  const handleFilterChange = (val) => {
    setFilters({ ...filters, ...val });
  };

  const {
    bopPressureRating: lookupBopPressureRating = [],
    rigOperatingArea: lookupOperatingArea = [],
    designCategory: lookupDesignCategory = [],
    hookLoad: lookupHookLoad = [],
    waterDepth: lookupWaterDepth = []
  } = filterLookups || {};

  const {
    designWaterDepth,
    design,
    managedPressureDrilling,
    yearInService,
    operatingArea,
    hookLoad,
    bopRating
  } = filters;

  return (
    <Stack spacing={2}>
      <FilterSlider
        autoMarkers
        sliderWidth={'95%'}
        titleVariant={'body2'}
        title={'DESIGN WATER DEPTH [ft]'}
        value={[designWaterDepth.min, designWaterDepth.max]}
        onChange={(value) =>
          handleFilterChange({
            designWaterDepth: { type: 'range', min: parseInt(value[0]), max: parseInt(value[1]) }
          })
        }
        max={lookupWaterDepth[1]}
        min={lookupWaterDepth[0]}
        step={100}
      />
      <Stack>
        <Typography variant={'subtitle2'}>DESIGN</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ design: { type: 'multiselect', value: val } })
          }
          value={design?.value || []}
          id={'rigs-region-filter'}
          options={lookupDesignCategory}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>MANAGED PRESSURE DRILLING (MPD)</Typography>
        <YesNoSelect
          value={managedPressureDrilling.value}
          handleChange={(val) =>
            handleFilterChange({ managedPressureDrilling: { type: 'bool', value: val } })
          }
        />
      </Stack>
      <FilterSlider
        marks={getSliderMarks(1968, moment().year(), true)}
        sliderWidth={'95%'}
        titleVariant={'body2'}
        title={'YEAR IN SERVICE'}
        value={[yearInService.min, yearInService.max]}
        onChange={(value) =>
          handleFilterChange({
            yearInService: { type: 'range', min: parseInt(value[0]), max: parseInt(value[1]) }
          })
        }
        max={moment().year()}
        min={1968}
        step={1}
      />
      <Stack>
        <Typography variant={'subtitle2'}>OPERATIONAL AREA</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ operatingArea: { type: 'multiselect', value: val } })
          }
          value={operatingArea?.value || []}
          id={'rigs-operating-area-filter'}
          options={lookupOperatingArea}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>BOP RATING [psi]</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ bopRating: { type: 'multiselect', value: val } })
          }
          value={bopRating?.value || []}
          id={'rigs-bop-rating-filter'}
          options={lookupBopPressureRating}
          multiple
        />
      </Stack>
      <FilterSlider
        autoMarkers
        sliderWidth={'95%'}
        titleVariant={'body2'}
        title={'HOOK LOAD [lbs]'}
        value={[hookLoad.min, hookLoad.max]}
        onChange={(value) =>
          handleFilterChange({
            hookLoad: { type: 'range', min: parseInt(value[0]), max: parseInt(value[1]) }
          })
        }
        max={lookupHookLoad[1]}
        min={lookupHookLoad[0]}
        step={10000}
      />
    </Stack>
  );
}

AdvancedFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired
};

AdvancedFilters.defaultProps = {
  filters: null
};

export default AdvancedFilters;
