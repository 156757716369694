import { useCallback } from 'react';
import { getProject } from '@helpers/helpers';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';

export const useExports = () => {
  const params = useParams();
  const {
    state: {
      projectsData: { projects }
    }
  } = useStore();

  const exportPdf = useCallback(async () => {
    let legend = document.querySelector('#timeline-legend');
    legend.style.width = '100vw';
    let timeline = document.querySelector('#timeline-container');
    let prevHeight = timeline.style.height;
    timeline.style.height = 'fit-content';
    timeline.style.maxHeight = 'fit-content';

    const { projectName, note, createdBy, createdDate, lastEditBy, lastEditDate } = getProject(
      projects,
      params.projectId
    );

    const imgWidth = 275;
    const pageHeight = 210;
    let position = 35;
    const doc = new jsPDF('landscape', 'mm', 'a4');

    // Add project information on the first page
    doc.setFontSize(10).setFont(undefined, 'bold');
    doc.text('Project name:', 10, 10);
    doc.text('Note:', 10, 15);
    doc.text('Created By:', 10, 20);
    doc.text('Created:', 10, 25);
    doc.text('Last edited by:', 80, 20);
    doc.text('Last edited:', 80, 25);

    doc.setFont(undefined, 'normal');
    doc.text(projectName, 40, 10);
    doc.text(note || '-', 40, 15);
    doc.text(createdBy, 40, 20);
    doc.text(createdDate, 40, 25);

    doc.text(lastEditBy, 110, 20);
    doc.text(lastEditDate, 110, 25);

    // Adjust the position to start the images below the text
    position = 40; // Adjust this to ensure enough space after the text

    // Capture the legend
    await html2canvas(legend).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      position += imgHeight + 5; // Update position after the legend
    });

    legend.removeAttribute('style');

    // Capture the timeline (use updated position)
    await html2canvas(timeline).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const totalHeight = canvas.height;
      const scale = canvas.width / imgWidth; // Scale factor based on canvas and PDF width

      let remainingHeight = totalHeight / scale;
      let currentPosition = position; // Start with updated position

      // Function to add content to a new page and update the startY
      const addPageContent = (startY, remainingHeight, isFirstPage) => {
        const pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        const pageHeightPx = pageHeight * scale;
        const heightToRender = Math.min(remainingHeight * scale, pageHeightPx);
        pageCanvas.height = heightToRender;

        const ctx = pageCanvas.getContext('2d');
        ctx.drawImage(
          canvas,
          0,
          startY * scale,
          canvas.width,
          heightToRender,
          0,
          0,
          canvas.width,
          heightToRender
        );

        const imgData = pageCanvas.toDataURL('image/png');
        doc.addImage(
          imgData,
          'PNG',
          10,
          isFirstPage ? currentPosition : 0,
          imgWidth,
          heightToRender / scale
        );
      };

      let startY = 0;

      while (remainingHeight > 0) {
        // Check if there's enough room for another full page
        const pageSpace = pageHeight - currentPosition;

        if (remainingHeight <= pageSpace) {
          // If remaining content fits in the current page
          addPageContent(startY, remainingHeight, true);
          remainingHeight = 0; // Content fits, stop adding pages
        } else {
          // Add content to fill the rest of the page, then move to the next page
          addPageContent(startY, pageSpace, true);
          doc.addPage();
          startY += pageSpace;
          remainingHeight -= pageSpace;

          // Remove padding for pages after the first
          currentPosition = 0; // Remove the padding on top of subsequent pages
        }
      }
    });

    doc.save('rigs-planner-summary.pdf');

    // Reset the timeline styles
    timeline.style.height = prevHeight;
    timeline.style.maxHeight = '75vh';
  }, [JSON.stringify(projects)]);

  return { exportPdf };
};
