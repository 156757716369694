import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@esgian/esgianui';

function FilterSelect({ options, value, handleChange, hideEmpty }) {
  return (
    <Select
      displayEmpty
      variant={'outlined'}
      value={value}
      onChange={({ target }) => handleChange(target.value)}>
      {!hideEmpty && <MenuItem value={''}>-</MenuItem>}
      {options?.map(({ id, name }) => (
        <MenuItem key={`${id}-${name.split(' ').join('-').toLowerCase()}`} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}

FilterSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChange: PropTypes.func.isRequired,
  hideEmpty: PropTypes.bool
};

FilterSelect.defaultProps = {
  hideEmpty: false
};

export default FilterSelect;
