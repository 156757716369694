import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@esgian/esgianui';
function addAlpha(color, opacity) {
  const opc = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + opc.toString(16).toUpperCase();
}

function LegendItem({ isFixed, title, color }) {
  return (
    <Paper variant={'outlined'} sx={{ p: 1, whiteSpace: 'nowrap' }}>
      <Stack spacing={1} direction={'row'} alignItems={'center'}>
        <span
          style={{
            height: '10px',
            width: '10px',
            border: `1px solid ${color}`,
            backgroundColor: color,
            background: isFixed ? color : addAlpha(color, 0.2),
            borderRadius: '50%',
            display: 'inline-block'
          }}></span>
        <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'}>
          {title}
        </Typography>
      </Stack>
    </Paper>
  );
}

LegendItem.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isFixed: PropTypes.bool
};

LegendItem.defaultProps = {
  isFixed: false
};

export default LegendItem;
