import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DeleteOutlineIcon,
  EditIcon,
  Grid,
  IconButton,
  Link,
  Paper,
  PlusIcon,
  Table,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import { PROJECT_TYPES } from '@constants';
import { DeleteProjectModal, EditProjectModal } from '@components/Modals';

function MyProjectsTable({ setCreateProjectOpen }) {
  const [deleteProject, setDeleteProject] = useState(null);
  const [editProject, setEditProject] = useState(null);
  const { state } = useStore();
  const {
    themeMode,
    projectsData: { projects }
  } = state;

  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const columns = [
    {
      name: 'projectName',
      label: 'Project',
      options: {
        sort: true,
        sortThirdClickReset: true,
        setCellProps: () => {
          return {
            style: {
              boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
            }
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ToolTip placement={'right'} title={`Go to project ${rowData[1]}`}>
              <div style={{ display: ' inline-flex' }}>
                <Link
                  color={'info.dark'}
                  underline="hover"
                  variant={'body2'}
                  sx={{ textAlign: 'left' }}
                  component={'button'}
                  onClick={() => routeChange(`/market-planner/${rowData[1]}`)}>
                  {dataIndex}
                </Link>
              </div>
            </ToolTip>
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'projectId',
      options: { display: false, viewColumns: false, filter: false }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: (value) => {
          return value === PROJECT_TYPES.OIL_COMPANY.id
            ? PROJECT_TYPES.OIL_COMPANY.name
            : PROJECT_TYPES.RIG_OPERATOR.name;
        },
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        sort: true,
        filter: false
      }
    },
    {
      name: 'lastEditDate',
      label: 'Last edit date',
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'createdDate',
      label: 'Created Date',
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return (
            <IconButton onClick={() => setEditProject(rowData[1])}>
              <EditIcon fontSize={'small'} />
            </IconButton>
          );
        }
      }
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return (
            <IconButton onClick={() => setDeleteProject(rowData[1])}>
              <DeleteOutlineIcon fontSize={'small'} />
            </IconButton>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    hideToolbarMargin: true,
    responsive: 'standard',
    filter: false,
    search: false,
    download: false,
    downloadOptions: {
      filename: 'my-projects'
    },
    print: false,
    viewColumns: false,
    pagination: projects?.length >= 10,
    elevation: 0,
    toolbar: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    textLabels: {
      body: {
        noMatch: (
          <Grid container direction={'column'} justifyContent={'flex-start'} spacing={2}>
            <Grid item key={'no-project-text'}>
              <Typography bold>
                Create marker planner analysis project and explore opportunities in the rig market
              </Typography>
            </Grid>
          </Grid>
        )
      }
    },
    sortOrder: {
      name: 'projectName',
      direction: 'asc'
    }
  };

  return (
    <>
      <Paper variant={'outlined'}>
        <Table
          app={'rigsPlanner'}
          mode={'light'}
          title={''}
          data={projects?.length ? projects : []}
          columns={columns}
          options={options}
        />
      </Paper>
      <DeleteProjectModal setDeleteProject={setDeleteProject} deleteProject={deleteProject} />
      <EditProjectModal
        open={editProject !== null}
        projectId={editProject}
        onClose={() => setEditProject(null)}
      />
    </>
  );
}

MyProjectsTable.propTypes = {
  setCreateProjectOpen: PropTypes.func.isRequired
};

MyProjectsTable.defaultProps = {};

export default MyProjectsTable;
