import React from 'react';
import PropTypes from 'prop-types';

const Store = React.createContext();

export const useStore = () => React.useContext(Store);

export const StoreProvider = ({ children, state, dispatch }) => {
  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>;
};
StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

StoreProvider.defaultProps = {};
