import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Grid } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers/helpers';

function PrivateRoute({ children, loadingUser }) {
  const {
    state: { user }
  } = useStore();

  if (loadingUser && !user) {
    return (
      <Grid container sx={{ height: '100vh' }} justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <CircularProgress size={'5em'} />
        </Grid>
      </Grid>
    );
  }
  if (checkUserHasAccess(user)) {
    return children;
  }
  return <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  loadingUser: PropTypes.bool
};

PrivateRoute.defaultProps = {
  loadingUser: true
};

export default PrivateRoute;
