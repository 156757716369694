import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';

import { useStore } from '@store/Store';
import RigDisplayBox from '@components/Drawers/RequirementEligibleRigsDrawer/RequirementEligibleRigsFilters/RigDisplayBox';

function RequirementEligibleRigsDisplay({
  displayRigs,
  selectedRigs,
  setDisplayRigs,
  eligibleRigs,
  setViewFilters,
  setSelectedRigs,
  filters
}) {
  const [query, setQuery] = useState('');
  const [init, setInit] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const {
    state: { filterLookups }
  } = useStore();
  const { waterDepth: lookupWaterDepth = [] } = filterLookups;
  const allRigs = useMemo(
    () => [...(displayRigs?.availableRigs || []), ...(displayRigs?.availableRigsFlex || [])],
    [displayRigs]
  );
  const handleRigClick = (value) => {
    let tempRigs = [...selectedRigs];
    let rigIds = tempRigs?.map(({ rigId }) => rigId);
    if (rigIds.includes(value?.rigId)) {
      setSelectedRigs(tempRigs.filter((item) => item.rigId !== value?.rigId));
    } else {
      tempRigs.push(value);
      setSelectedRigs(tempRigs);
    }
  };

  const handleAllClick = useCallback(() => {
    if (selectedRigs?.length === allRigs?.length) {
      setSelectedRigs([]);
    } else {
      setSelectedRigs(allRigs);
    }
  }, [allRigs, selectedRigs]);

  useEffect(() => {
    let count = 0;
    const { waterDepth, regionId, rigType } = filters;

    if (rigType?.length) {
      count += 1;
    }
    if (
      waterDepth?.min !== 0 ||
      waterDepth?.max !== lookupWaterDepth[lookupWaterDepth?.length - 1]
    ) {
      count += 1;
    }
    if (regionId) {
      count += 1;
    }

    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (init) {
        setInit(false);
        return;
      }
      if (query === '') {
        setDisplayRigs(eligibleRigs);
        return;
      }
      let tempDisplay = { availableRigsFlex: [], availableRigs: [] };

      eligibleRigs?.availableRigsFlex.forEach((rig) => {
        if (rig.rigName.toLowerCase().includes(query.toLowerCase())) {
          tempDisplay.availableRigsFlex.push(rig);
        }
      });
      eligibleRigs?.availableRigs.forEach((rig) => {
        if (rig.rigName.toLowerCase().includes(query.toLowerCase())) {
          tempDisplay.availableRigs.push(rig);
        }
      });
      setDisplayRigs(tempDisplay);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [query, eligibleRigs]);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <TextField
            onChange={({ target }) => setQuery(target.value)}
            value={query}
            fullWidth
            placeholder={'Search for rig...'}
          />
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center' }}>
          <Badge invisible={filterCount === 0} badgeContent={filterCount} color="primary">
            <Button
              onClick={() => setViewFilters(true)}
              withUnderline
              size={'large'}
              color={'secondary'}>
              Filter
            </Button>
          </Badge>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox checked={selectedRigs?.length === allRigs?.length} onChange={handleAllClick} />
        }
        label="Check all"
      />
      <Divider />
      <Typography variant={'body2'}>Displaying {allRigs?.length || 0} rigs</Typography>
      {displayRigs?.availableRigs?.map(
        ({ rigId, rigName, rigType, region, rigStatus, rigStatusId }) => (
          <RigDisplayBox
            key={`${rigId}-display-elig`}
            handleRigClick={handleRigClick}
            selectedRigs={selectedRigs}
            rigId={rigId}
            rigName={rigName}
            rigType={rigType}
            region={region}
            rigStatus={rigStatus}
            rigStatusId={rigStatusId}
          />
        )
      )}
      {!!displayRigs?.availableRigsFlex?.length && (
        <Typography variant={'body2'}>
          Below is a list of rigs that meet the requirement with flexibility days applied:
        </Typography>
      )}
      {displayRigs?.availableRigsFlex?.map(
        ({ rigId, rigName, rigType, region, rigStatus, rigStatusId }) => (
          <RigDisplayBox
            key={`${rigId}-display-elig`}
            handleRigClick={handleRigClick}
            selectedRigs={selectedRigs}
            rigId={rigId}
            rigName={rigName}
            rigType={rigType}
            region={region}
            rigStatus={rigStatus}
            rigStatusId={rigStatusId}
          />
        )
      )}
    </Stack>
  );
}

RequirementEligibleRigsDisplay.propTypes = {
  displayRigs: PropTypes.object,
  selectedRigs: PropTypes.arrayOf(PropTypes.object),
  setDisplayRigs: PropTypes.func.isRequired,
  setViewFilters: PropTypes.func.isRequired,
  eligibleRigs: PropTypes.object,
  filters: PropTypes.object,
  setSelectedRigs: PropTypes.func.isRequired
};

RequirementEligibleRigsDisplay.defaultProps = {
  displayRigs: { availableRigsFlex: [], availableRigs: [] },
  selectedRigs: [],
  eligibleRigs: { availableRigsFlex: [], availableRigs: [] },
  filters: {}
};

export default RequirementEligibleRigsDisplay;
