import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CloseIcon,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@esgian/esgianui';
import { useStore } from '@store/Store';
import { getProject } from '@helpers/helpers';
import { useParams } from 'react-router-dom';
import { FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { snackbar } from '@mobiscroll/react';
import RequirementEligibleRigsFilters from '@components/Drawers/RequirementEligibleRigsDrawer/RequirementEligibleRigsFilters';
import RequirementEligibleRigsDisplay from '@components/Drawers/RequirementEligibleRigsDrawer/RequirementEligibleRigsDisplay';
import { findEligibleRigs } from '@components/Drawers/RequirementEligibleRigsDrawer/helpers';

function RequirementEligibleRigsDrawer({ open, handleClose, contract, flow }) {
  const [filters, setFilters] = useState({});
  const [viewFilters, setViewFilters] = useState(false);
  const [eligibleRigs, setEligibleRigs] = useState({ availableRigsFlex: [], availableRigs: [] });
  const [selectedRigs, setSelectedRigs] = useState([]);
  const [displayRigs, setDisplayRigs] = useState({ availableRigsFlex: [], availableRigs: [] });
  const params = useParams();
  const projectId = params.projectId;
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const { rigs: lookupRigs, waterDepth: lookupWaterDepth = [] } = filterLookups;

  const handleCloseClick = () => {
    if (viewFilters) {
      setViewFilters(false);
    } else {
      handleClose();
    }
  };

  const handleReset = () => {
    if (viewFilters) {
      setFilters({});
    } else {
      let resource =
        flow === FLOW.OIL_COMPANY ? { operatorId: contract.operatorId } : { rigId: contract.rigId };
      dispatch({
        type: RIG_SUPPLIER_ACTIONS.REMOVE_ELIGIBLE_RIGS_FOR_REQUIREMENT,
        flow: FLOW.RIG_SUPPLIER,
        payload: { projectId: projectId, ...resource, requirementId: contract.contractId }
      });
      setSelectedRigs([]);
      handleClose();
      snackbar({
        message: 'Eligible rigs removed',
        color: 'success',
        duration: 5000,
        display: 'top'
      });
    }
  };

  const handleApplyClick = () => {
    if (viewFilters) {
      applyFilters();
      setViewFilters(false);
    } else {
      let resource =
        flow === FLOW.OIL_COMPANY ? { operatorId: contract.operatorId } : { rigId: contract.rigId };
      dispatch({
        type: RIG_SUPPLIER_ACTIONS.UPDATE_ELIGIBLE_RIGS_FOR_REQUIREMENT,
        flow: FLOW.RIG_SUPPLIER,
        payload: {
          projectId: projectId,
          ...resource,
          requirementId: contract.contractId,
          rigs: [...selectedRigs]
        }
      });
      handleClose();
      snackbar({
        message: 'Eligible rigs added',
        color: 'success',
        duration: 5000,
        display: 'top'
      });
      setSelectedRigs([]);
    }
  };

  const applyFilters = (setEligible, tempFilters) => {
    const { dateFlexibility = 0, startDate, latestStartDate, duration } = contract;
    let { availableRigsFlex, availableRigs } = findEligibleRigs(
      lookupRigs,
      startDate,
      latestStartDate,
      duration,
      dateFlexibility,
      tempFilters || filters
    );

    setDisplayRigs({ availableRigsFlex: availableRigsFlex, availableRigs: availableRigs });
    setEligibleRigs({ availableRigsFlex: availableRigsFlex, availableRigs: availableRigs });
  };

  useEffect(() => {
    if (!contract) return;
    const { eligibleRigs } = getProject(projects, projectId);
    if (!eligibleRigs?.length) return;
    if (flow === FLOW.OIL_COMPANY) {
      const eligible =
        eligibleRigs.find(
          ({ requirementId, operatorId }) =>
            requirementId === contract.contractId && operatorId === contract.operatorId
        )?.rigs || [];
      setSelectedRigs([...eligible]);
    } else {
      const eligible =
        eligibleRigs.find(
          ({ requirementId, rigId }) =>
            requirementId === contract.contractId && rigId === contract.rigId
        )?.rigs || [];
      setSelectedRigs([...eligible]);
    }
  }, [JSON.stringify(projects), open, contract]);

  useEffect(() => {
    if (!lookupRigs?.length || !contract || !lookupWaterDepth?.length) return;
    const { rigTypes, outfittedWaterDepth, regionId, region } = contract;

    let tempFilters = {
      waterDepth: {
        min: outfittedWaterDepth || 0,
        max: lookupWaterDepth[lookupWaterDepth.length - 1]
      },
      region: regionId ? [{ id: regionId, name: region }] : [],
      rigType: rigTypes
    };
    setFilters(tempFilters);
    applyFilters(true, tempFilters);
  }, [lookupRigs, lookupWaterDepth, contract]);
  return (
    <Drawer open={open} onClose={handleCloseClick} anchor={'right'}>
      <Grid
        container
        sx={{
          width: '25vw',
          maxHeight: '100%'
        }}>
        <Grid sx={{ height: '57px' }} item xs={12}>
          <Grid justifyContent={'space-between'} container>
            <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <Typography variant={'h6'} color={'text.primary'}>
                {viewFilters ? 'Filters' : 'Eligible rigs'}
              </Typography>
            </Grid>
            <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <IconButton onClick={handleCloseClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack
            justifyContent={'space-between'}
            sx={{
              overflowX: 'hidden',
              height: 'calc(100vh - 57px - 70px )',
              overflowY: 'auto'
            }}>
            {viewFilters ? (
              <RequirementEligibleRigsFilters setFilters={setFilters} filters={filters} />
            ) : (
              <RequirementEligibleRigsDisplay
                filters={filters}
                displayRigs={displayRigs}
                selectedRigs={selectedRigs}
                setDisplayRigs={setDisplayRigs}
                eligibleRigs={eligibleRigs}
                setViewFilters={setViewFilters}
                setSelectedRigs={setSelectedRigs}
              />
            )}
            <Stack
              direction={'row'}
              sx={{
                p: 2,
                boxShadow: '0 0 8px rgb(0 0 0 / 18%)',
                position: 'fixed',
                bottom: 0,
                height: '70px',
                background: (theme) => theme.palette.background.paper,
                width: '25vw'
              }}
              justifyContent={'space-between'}>
              <Button color={'secondary'} onClick={handleReset} variant={'text'} withUnderline>
                Clear All
              </Button>
              <Button disableElevation onClick={handleApplyClick} variant={'contained'}>
                Apply
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}

RequirementEligibleRigsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  contract: PropTypes.object,
  isRigDemand: PropTypes.bool,
  flow: PropTypes.oneOf(Object.values(FLOW))
};

RequirementEligibleRigsDrawer.defaultProps = {
  contract: null,
  isRigDemand: false,
  flow: FLOW.OIL_COMPANY
};

export default RequirementEligibleRigsDrawer;
