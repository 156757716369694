import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { Autocomplete, Chip, CircularProgress, TextField } from '@esgian/esgianui';

function RigsMultiSelect({
  id,
  selectedRigs,
  limitTags,
  error,
  helperText,
  placeholder,
  disabled,
  getOptionDisabled,
  handleChange
}) {
  const {
    state: { filterLookups }
  } = useStore();
  const { rigs } = filterLookups;
  return (
    <Autocomplete
      id={id}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        if (rigs.length) {
          return option.rigId === value.rigId;
        }
        return false;
      }}
      value={rigs?.length ? selectedRigs : []}
      disableCloseOnSelect
      onChange={handleChange}
      fullWidth
      multiple
      options={rigs || []}
      limitTags={limitTags}
      getOptionLabel={(option) => option.rigName}
      renderTags={(value) => {
        if (value.length === rigs?.length) {
          return 'All';
        }
        let i = 0;
        return value.map(({ rigName }) => {
          if (!rigName) {
            return;
          }
          if (i === limitTags) {
            i++;
            return `+${value?.length - limitTags}`;
          } else if (i < limitTags) {
            i++;
            return (
              <Chip size={'small'} key={`chip-${rigName.split(' ').join('-')}`} label={rigName} />
            );
          }
        });
      }}
      getOptionDisabled={getOptionDisabled}
      filterOptions={(rigs, state) => {
        return rigs.filter(
          ({ rigName, rigId }) =>
            rigName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            rigId?.toString().includes(state.inputValue)
        );
      }}
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          placeholder={selectedRigs?.length >= 1 ? '' : placeholder}
          {...params}
          label={rigs?.length ? '' : <CircularProgress size={25} />}
        />
      )}
    />
  );
}

RigsMultiSelect.propTypes = {
  id: PropTypes.string,
  limitTags: PropTypes.number,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  selectedRigs: PropTypes.arrayOf(PropTypes.object),
  getOptionDisabled: PropTypes.func,
  handleChange: PropTypes.func.isRequired
};

RigsMultiSelect.defaultProps = {
  id: 'rig-multi-select',
  limitTags: 1,
  placeholder: '',
  error: false,
  helperText: null,
  disabled: false,
  getOptionDisabled: null,
  selectedRigs: []
};

export default RigsMultiSelect;
