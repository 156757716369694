import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import picture1 from '@assets/images/planner-inprog.png';

function UnderConstPage(props) {
  return (
    <Grid container spacing={2} sx={{ textAlign: 'center' }}>
      <Grid item xs={11}>
        <Stack spacing={2} alignItems={'center'}>
          <Typography variant={'h4'}>Coming soon...</Typography>
          <img style={{ width: '50vw' }} srcSet={picture1} src={picture1} />
          <Paper variant={'outlined'} sx={{ p: 2, width: '60%' }}>
            <Typography>
              {`
              Market planner is a new function that allows the user to create “scenarios” on how
              future rig requirements/demand will affect rig utilization and availability. Designed
              to allow the user flexibility on allocating known requirements across multiple or
              individual rigs, this state-of-the-art tool will allow users to build forecasts on rig
              requirements and contract allocations.`}
            </Typography>
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
}

UnderConstPage.propTypes = {};

UnderConstPage.defaultProps = {};

export default UnderConstPage;
