import React, { useState } from 'react';
import { Button, Grid, ReportIcon, Stack, Typography } from '@esgian/esgianui';
import TimelineContainer from '@components/Sections/RigSupplierSection/TimelineContainer';
import PropTypes from 'prop-types';
import { AddRigsDrawer } from '@components/Sections/RigSupplierSection/Drawers';
import { RemoveRigModule } from '@components/Sections/RigSupplierSection/Modules';
import { RigInfoDrawer } from '@components/Drawers';
import { useParams } from 'react-router-dom';
import SaveToFileButton from '../../Inputs/SaveToFileButton';
import { useExports } from '@hooks/useExports';

function RigSupplierSection({ project }) {
  const [addRigsDrawerOpen, setAddRigsDrawerOpen] = useState(false);
  const [viewRigInfo, setViewRigInfo] = useState(null);
  const [removeRig, setRemoveRig] = useState(null);
  const params = useParams();
  const { exportPdf } = useExports();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h5'} bold>
              WORKBOOK
            </Typography>
            <Stack direction={'row'} spacing={2}>
              <Button
                variant={'text'}
                onClick={exportPdf}
                sx={{ color: '#000000' }}
                startIcon={<ReportIcon color={'inherit'} />}>
                Export
              </Button>
              <SaveToFileButton projectId={parseInt(params.projectId)} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TimelineContainer
            project={project}
            addRigsButtonClick={() => setAddRigsDrawerOpen(!addRigsDrawerOpen)}
            setRemoveRig={setRemoveRig}
            setViewRigInfo={setViewRigInfo}
          />
        </Grid>
      </Grid>
      <AddRigsDrawer open={addRigsDrawerOpen} setOpen={setAddRigsDrawerOpen} />
      <RigInfoDrawer handleClose={() => setViewRigInfo(null)} rigId={viewRigInfo} />
      <RemoveRigModule handleClose={() => setRemoveRig(null)} rigId={removeRig} />
    </>
  );
}

RigSupplierSection.propTypes = {
  project: PropTypes.object.isRequired
};

RigSupplierSection.defaultProps = {};

export default RigSupplierSection;
