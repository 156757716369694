import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ACTIVITY_STATUSES } from '@constants';
import { Stack, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { ActivityTooltip } from '@components/Timeline';

function ActivityEvent({ event, chartSettings, showStatus }) {
  const [tooltipAnchor, setTooltipAnchor] = useState(null);
  const [myEl, setMyEl] = useState();
  const { country: showCountry, region: showRegion, duration: showDuration } = chartSettings;
  const original = { ...event.original };
  const taskType = original.taskType - 1;
  const statusObj = ACTIVITY_STATUSES[taskType];

  let title = statusObj?.name;
  let color = statusObj?.color;

  if (!showStatus) {
    color = '#7519a9';
  }

  let background = '';
  let textColor = 'rgba(0, 0, 0, 0.87)';
  if ((statusObj.id === 6 && showStatus) || (!showStatus && !original.details.isRequirement)) {
    textColor = '#fff';
  }

  if (!showStatus && original.details.isRequirement) {
    background = 'rgb(255 255 255 / 80%)';
  }

  if (showStatus && !statusObj.isFixed) {
    background = 'rgb(255 255 255 / 80%)';
  }

  const onMouseEnter = (event) => {
    setTooltipAnchor(event.currentTarget);
  };
  const onMouseLeave = () => {
    setTooltipAnchor(null);
  };

  let geoString = null;
  if (showRegion && showCountry) {
    geoString = `${original.details.country}, ${original.details.region}`;
  } else if (showCountry) {
    geoString = original.details.country;
  } else if (showRegion) {
    geoString = original.details.region;
  }

  let eventSettings = { ...chartSettings };
  eventSettings.region = false;
  eventSettings.dayRate = false;
  eventSettings.country = false;
  eventSettings.duration = false;
  return (
    <>
      <div
        ref={setMyEl}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
          minHeight: '100%',
          cursor: 'default',
          display: 'block',
          color: color,
          background: color,
          borderRadius: '10px',
          border: `1px solid ${color}`,
          margin: '0px 0 0px 0'
        }}>
        <div
          style={{
            padding: '4px',
            borderRadius: '9px',
            background: background
          }}>
          <Stack spacing={0.5} sx={{ pl: 1 }}>
            <div
              className={'mbsc-schedule-event-title mbsc-material'}
              style={{
                fontSize: '0.85rem',
                color: textColor,
                marginTop: '4px',
                minHeight: '24px'
              }}>
              {title}
            </div>
            {geoString && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {geoString}
              </Typography>
            )}
            {Object.entries(eventSettings)?.map(([key, value]) => {
              if (value) {
                return (
                  <Typography
                    key={`${key}-setting`}
                    variant={'caption'}
                    sx={{
                      visibility: 'hidden'
                    }}>
                    {'n/a'}
                  </Typography>
                );
              }
            })}

            {showDuration && (
              <Typography
                variant={'caption'}
                sx={{
                  color: textColor,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {moment(event.start).format('DD/MM/yyyy')}-{moment(event.end).format('DD/MM/yyyy')}
              </Typography>
            )}
          </Stack>
        </div>
      </div>

      {!!tooltipAnchor && (
        <ActivityTooltip details={event?.original?.details} anchor={tooltipAnchor} />
      )}
    </>
  );
}

ActivityEvent.propTypes = {
  event: PropTypes.object,
  chartSettings: PropTypes.object,
  showStatus: PropTypes.bool
};

ActivityEvent.defaultProps = {
  event: {},
  chartSettings: {},
  showStatus: false
};

export default ActivityEvent;
