import React from 'react';
import PropTypes from 'prop-types';
import { FilterSlider, Stack, Typography } from '@esgian/esgianui';
import { getLookupMarks, getValueObj } from '@helpers/helpers';
import { LookupSelect, YesNoSelect } from '@components/Inputs';
import { LOOKUP_NUM_BOP_STACKS } from '@constants';
import FilterSelect from '../../Inputs/FilterSelect';
import { useStore } from '@store/Store';

function RequirementTechFields({ handleValueChanges, fields }) {
  const {
    waterDepth,
    managedPressureDrilling,
    hpht,
    dpId,
    dpClass,
    bopMaxPsi,
    harshEnvironment,
    cantileverReach,
    nrOfStackRams,
    mooringCapability,
    hookLoad,
    variableDeckLoad,
    accommodation,
    highSpec,
    winterized
  } = fields;

  const {
    state: { filterLookups }
  } = useStore();

  const {
    accommodation: lookupAccommodation = [],
    dpClass: lookupDpClass = [],
    cantileverReach: lookupCantileverReach = [],
    hookLoad: lookupHookLoad = [],
    waterDepth: lookupWaterDepth = [],
    variableDeckLoad: lookupVariableDeckLoad = [],
    bopPressureRating: lookupBopPressureRating = []
  } = filterLookups || {};
  return (
    <Stack spacing={1}>
      <Typography bold>Technical requirements</Typography>
      <FilterSlider
        autoMarkers
        sliderWidth={'98%'}
        titleVariant={'body2'}
        title={'WATER DEPTH AT DRILLING SITE [ft]'}
        value={waterDepth || 0}
        min={lookupWaterDepth[0]}
        max={lookupWaterDepth[1]}
        step={100}
        onChange={(val) => handleValueChanges({ waterDepth: parseInt(val) || 0 })}
      />
      <Stack>
        <Typography variant={'body2'}>MANAGED PRESSURE DRILLING</Typography>
        <YesNoSelect
          value={managedPressureDrilling}
          handleChange={(val) => handleValueChanges({ managedPressureDrilling: val })}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>HPHT</Typography>
        <YesNoSelect value={hpht} handleChange={(val) => handleValueChanges({ hpht: val })} />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>DP-CLASS</Typography>
        <LookupSelect
          id={'requirement-dp-select'}
          handleChange={(_, val) =>
            handleValueChanges({
              dpId: parseInt(val.id),
              dpClass: val.name
            })
          }
          value={getValueObj(dpId, dpClass)}
          options={lookupDpClass}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>BOP PRESSURE RATING [psi]</Typography>
        <FilterSelect
          hideEmpty
          handleChange={(val) => handleValueChanges({ bopMaxPsi: val })}
          value={bopMaxPsi || ''}
          options={lookupBopPressureRating}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>NUMBER OF BOP STACKS</Typography>
        <FilterSelect
          handleChange={(val) => handleValueChanges({ nrOfStackRams: val })}
          value={nrOfStackRams || ''}
          options={LOOKUP_NUM_BOP_STACKS}
        />
      </Stack>
      <FilterSlider
        autoMarkers
        sliderWidth={'98%'}
        titleVariant={'body2'}
        title={'CANTILEVER REACH [ft]'}
        value={cantileverReach || 0}
        min={lookupCantileverReach[0]}
        max={lookupCantileverReach[1]}
        step={5}
        onChange={(val) => handleValueChanges({ cantileverReach: parseInt(val) || 0 })}
      />
      <Stack>
        <Typography variant={'body2'}>MOORING CAPABILITY</Typography>
        <YesNoSelect
          value={mooringCapability}
          handleChange={(val) => handleValueChanges({ mooringCapability: val })}
        />
      </Stack>
      <FilterSlider
        autoMarkers
        sliderWidth={'98%'}
        titleVariant={'body2'}
        title={'HOOK LOAD (lbs)'}
        value={hookLoad || 0}
        min={lookupHookLoad[0]}
        max={lookupHookLoad[1]}
        step={100}
        onChange={(val) => handleValueChanges({ hookLoad: parseInt(val) || 0 })}
      />
      <FilterSlider
        autoMarkers
        sliderWidth={'98%'}
        titleVariant={'body2'}
        title={'VARIABLE DECK LOAD (lbs/sqft)'}
        value={variableDeckLoad || 0}
        min={lookupVariableDeckLoad[0]}
        max={lookupVariableDeckLoad[1]}
        step={50}
        onChange={(val) => handleValueChanges({ variableDeckLoad: parseInt(val) || 0 })}
      />

      <FilterSlider
        autoMarkers
        sliderWidth={'98%'}
        titleVariant={'body2'}
        title={'ACCOMMODATION (POB)'}
        value={accommodation || 0}
        min={lookupAccommodation[0]}
        max={lookupAccommodation[1]}
        step={20}
        onChange={(val) => handleValueChanges({ accommodation: parseInt(val) || 0 })}
      />
      <Stack>
        <Typography variant={'body2'}>HIGH SPECIFICATION</Typography>
        <YesNoSelect
          value={highSpec}
          handleChange={(val) => handleValueChanges({ highSpec: val })}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>WINTERIZED</Typography>
        <YesNoSelect
          value={winterized}
          handleChange={(val) => handleValueChanges({ winterized: val })}
        />
      </Stack>
      <Stack>
        <Typography variant={'body2'}>HARSH ENVIRONMENT</Typography>
        <YesNoSelect
          value={harshEnvironment}
          handleChange={(val) => handleValueChanges({ harshEnvironment: val })}
        />
      </Stack>
    </Stack>
  );
}

RequirementTechFields.propTypes = {
  handleValueChanges: PropTypes.func.isRequired,
  fields: PropTypes.object
};

RequirementTechFields.defaultProps = {
  fields: {}
};

export default RequirementTechFields;
