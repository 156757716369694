import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import {
  FLOW,
  GENERAL_ACTIONS,
  OIL_COMPANY_ACTIONS,
  RIG_SUPPLIER_ACTIONS
} from '@store/AppReducer';
import {
  InsertCommentOutlinedIcon,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import { AddEditCommentModal } from '@components/Modals';
import { snackbar } from '@mobiscroll/react';
import { useParams } from 'react-router-dom';
import { getProject } from '@helpers/helpers';
import { RigInfoDrawer } from '@components/Drawers';
import Swal from 'sweetalert2';

function EligibleRigResourceButton({ flow, resource }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [rigInfoDrawerOpen, setRigInfoDrawerOpen] = useState(false);
  const [resourceComment, setResourceComment] = useState(null);
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const params = useParams();
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCommentApplyClick = useCallback(
    (comment) => {
      dispatch({
        type: GENERAL_ACTIONS.ADD_OR_UPDATE_COMMENT,
        flow: FLOW.GENERAL,
        payload: {
          projectId: parseInt(params.projectId),
          comment: comment,
          resourceId: resource.id
        }
      });
      setCommentModalOpen(false);
      snackbar({
        message: 'Comment saved',
        color: 'success',
        duration: 5000,
        display: 'top'
      });
    },
    [resource]
  );

  useEffect(() => {
    const { comments } = getProject(projects, parseInt(params.projectId));
    setResourceComment(
      comments?.find(({ resourceId, eventId }) => eventId === null && resourceId === resource.id)
        ?.comment || null
    );
  }, [resource]);

  const handleRemoveClick = useCallback(() => {
    const ids = resource.id.split(',');
    let type = OIL_COMPANY_ACTIONS.REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT;
    let payload = {
      projectId: params.projectId,
      operatorId: parseInt(ids[0]),
      requirementId: parseInt(ids[1]),
      rigId: parseInt(ids[2])
    };
    if (flow === FLOW.RIG_SUPPLIER) {
      type = RIG_SUPPLIER_ACTIONS.REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT;
      payload = {
        ...payload,
        ...{
          parentRigId: parseInt(ids[0])
        }
      };
    }
    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: type,
          flow: flow,
          payload: payload
        });
        snackbar({
          message: 'Eligible rig removed',
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });
  }, [resource]);

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={handleClick} className="resource-name">
        {`${resource.name}  `}
        {resourceComment && (
          <ToolTip title={resourceComment}>
            <span style={{ fontSize: '1rem' }}>
              <InsertCommentOutlinedIcon
                // color={isContract ? '#fff' : 'inherit'}
                fontSize={'inherit'}
              />
            </span>
          </ToolTip>
        )}
      </div>
      <Typography variant={'caption'} color={'text.secondary'}>
        {resource.rigType}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <List>
          <ListItemButton
            onClick={() => {
              handleClose();
              setRigInfoDrawerOpen(true);
            }}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={`View rig info`} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleClose();
              handleRemoveClick();
            }}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={`Remove`} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleClose();
              setCommentModalOpen(true);
            }}>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2' }}
              primary={`${resourceComment ? 'Edit' : 'Add'} Comment`}
            />
          </ListItemButton>
        </List>
      </Menu>
      {commentModalOpen && (
        <AddEditCommentModal
          handleApplyClick={handleCommentApplyClick}
          open={commentModalOpen}
          comment={resourceComment}
          handleClose={() => setCommentModalOpen(false)}
        />
      )}
      {rigInfoDrawerOpen && (
        <RigInfoDrawer rigId={resource.id} handleClose={() => setRigInfoDrawerOpen(false)} />
      )}
    </>
  );
}

EligibleRigResourceButton.propTypes = {
  resource: PropTypes.object,
  flow: PropTypes.oneOf(Object.values(FLOW))
};

EligibleRigResourceButton.defaultProps = {
  resource: {},
  flow: FLOW.OIL_COMPANY
};

export default EligibleRigResourceButton;
