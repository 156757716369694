import { BaseApiRequest } from './BaseApi';

export const getLookupRigs = (signal) => {
  return BaseApiRequest(signal, `/RigsPlanner/RigsLookup`);
};
export const getRigsOverview = (signal) => {
  return BaseApiRequest(signal, `/RigsPlanner/RigOverview`);
};
export const getAvailableContracts = (signal) => {
  return BaseApiRequest(signal, `/RigsPlanner/Contracts`);
};
