import { ACTIVITY_STATUSES, CONTRACT_STATUSES } from '@constants';

export const getNumberString = (number, decimals = 0) => {
  return number.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getTimeLineLegendItems = () => {
  let tempLegendItems = [];

  tempLegendItems = CONTRACT_STATUSES?.map((task) => {
    return { isFixed: task.isFixed, color: task.color, name: task.name };
  });
  ACTIVITY_STATUSES?.forEach((task) => {
    tempLegendItems.push({ isFixed: task.isFixed, color: task.color, name: task.name });
  });
  return tempLegendItems;
};

export const getProject = (projects, projectId) => {
  const foundIndex = [...projects]?.findIndex(
    (project) => parseInt(project.projectId) === parseInt(projectId)
  );
  if (foundIndex <= -1) return;
  return { ...projects[foundIndex] };
};

export const checkUserHasAccess = (user) => {
  if (!user) return false;
  const {
    profile: { permissionList }
  } = user;

  return permissionList.includes('ANALYTICS_premiumAccount');
};

export const getSliderMarks = (min, max, disableFormatting) => {
  return [
    { value: min, label: min },
    {
      value: Math.floor((max + min) / 2),
      label: disableFormatting
        ? Math.floor((max + min) / 2)
        : getNumberString(Math.floor((max + min) / 2))
    },
    {
      value: max,
      label: disableFormatting ? max : getNumberString(max)
    }
  ];
};

export const getLookupMarks = (lookup) => {
  return [
    { value: 0, label: '0' },
    {
      value: lookup[lookup.length - 1] / 2,
      label: getNumberString(parseInt(lookup[lookup.length - 1] / 2), 0)
    },
    {
      value: lookup[lookup.length - 1],
      label: getNumberString(parseInt(lookup[lookup.length - 1]), 0)
    }
  ];
};

export const getValueObj = (id, val) => {
  return id && val ? { id: id, name: val } : null;
};

export const getAppliedFilterCount = (filters, defaults) => {
  let count = 0;
  Object.entries(filters).forEach(([key, filter]) => {
    if (JSON.stringify(filter) !== JSON.stringify(defaults[key])) {
      count += 1;
    }
  });
  return count;
};
