import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, CircularProgress, Grid, Link, Typography } from '@esgian/esgianui';
import { useNavigate, useParams } from 'react-router-dom';
import RigSupplierSection from '../../components/Sections/RigSupplierSection';
import { PROJECT_TYPES, ROUTES } from '@constants';
import { useStore } from '@store/Store';
import OilCompanySection from '@components/Sections/OilCompanySection';

function ProjectPage({ loadingUser }) {
  const [project, setProject] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const {
    state: { projectsData }
  } = useStore();
  const { projects } = projectsData;

  useEffect(() => {
    if (loadingUser) return;
    const projectId = params.projectId;
    const project = projects?.find(
      (project) => parseInt(project.projectId) === parseInt(projectId)
    );
    if (project) {
      setProject(project);
    } else {
      navigate(ROUTES.PROJECTS_OVERVIEW);
    }
  }, [loadingUser, JSON.stringify(projects)]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/market-planner');
            }}>
            Back
          </Link>
          <Typography color="primary">{project?.projectName}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        {!project && (
          <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} size={'5em'} />
        )}
        {!!project && (
          <>
            {project.type === PROJECT_TYPES.RIG_OPERATOR.id ? (
              <RigSupplierSection project={project} />
            ) : (
              <OilCompanySection project={project} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

ProjectPage.propTypes = {
  loadingUser: PropTypes.bool
};

ProjectPage.defaultProps = { loadingUser: true };

export default ProjectPage;
