import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Divider, Stack, Switch, Typography } from '@esgian/esgianui';
import GeneralFilters from '@components/Sections/RigSupplierSection/Drawers/AddRigsDrawer/RequirementsFiltersContainer/GeneralFilters';
import AdvancedFilters from '@components/Sections/RigSupplierSection/Drawers/AddRigsDrawer/RequirementsFiltersContainer/AdvancedFilters';

function RequirementsFiltersContainer({ filters, setFilters }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <GeneralFilters filters={filters} setFilters={setFilters} />
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Typography variant={'body2'} bold>
          Advanced filters
        </Typography>
        <Switch
          disabled={!filters}
          checked={advancedOpen}
          onChange={() => setAdvancedOpen(!advancedOpen)}
        />
      </Stack>

      <Collapse in={advancedOpen} timeout="auto" unmountOnExit>
        <Stack spacing={2}>
          <Divider />
          {filters && <AdvancedFilters setFilters={setFilters} filters={filters} />}
        </Stack>
      </Collapse>
    </Stack>
  );
}

RequirementsFiltersContainer.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired
};

RequirementsFiltersContainer.defaultProps = {
  filters: null
};

export default RequirementsFiltersContainer;
