import { BaseApiRequest } from './BaseApi';
import cryptoJs from 'crypto-js';

export const verifyToken = (signal, token) => {
  return BaseApiRequest(signal, `/profile?token=${token}`, 'auth');
};

export const registerUser = (signal, firstName, lastName, email, password, company) => {
  const data = {
    firstname: firstName,
    lastname: lastName,
    email: email,
    password: password,
    company: company,
    project: 'values',
    tokenType: 'web'
  };
  let urlParameters = new URLSearchParams(data).toString();

  return BaseApiRequest(signal, `/register?${urlParameters}`, 'auth', 'POST');
};

export const loginUser = (signal, username, password) => {
  const key = cryptoJs.enc.Utf8.parse('9754445415798101');
  const iv = cryptoJs.enc.Utf8.parse('9754445415798101');
  const hashedPassword = cryptoJs.AES.encrypt(cryptoJs.enc.Utf8.parse(password), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7
  }).toString();
  const body = {
    username: username,
    password: hashedPassword,
    project: 'values',
    tokenType: 'web'
  };
  return BaseApiRequest(signal, `/login`, 'auth', 'POST', body);
};

export const logoutUser = (token) => {
  return BaseApiRequest(null, `/logout?token=${token}`, 'auth', 'DELETE');
};
