import React from 'react';
import { useStore } from '@store/Store';
import { Box, FilterSlider, Stack, Typography } from '@esgian/esgianui';
import { LookupSelect } from '@components/Inputs';
import PropTypes from 'prop-types';
import { CONTRACT_STATUSES } from '@constants';
import { getSliderMarks } from '@helpers/helpers';

function RequirementsGeneralFilters({ filters, handleFilterChange }) {
  const {
    state: { filterLookups }
  } = useStore();

  const {
    region: lookupRegion = [],
    country: lookupCountry = [],
    rigsType: lookupRigTypes = [],
    waterDepth: lookupWaterDepth = [],
    rigsOperators: lookupRigsOperators = []
  } = filterLookups || {};
  const { waterDepth, regions, countries, rigTypes, operators, contractStatus } = filters || {};

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Stack>
        <Typography variant={'subtitle2'}>REGIONS</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ regions: { type: 'multiselect', value: val } })
          }
          value={regions?.value || []}
          id={'operator-region-filter'}
          options={lookupRegion}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>COUNTRIES</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ countries: { type: 'multiselect', value: val } })
          }
          value={countries?.value || []}
          id={'operator-country-filter'}
          options={lookupCountry}
          multiple
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle2'}>RIG TYPES</Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ rigTypes: { type: 'multiselect', value: val } })
          }
          value={rigTypes?.value || []}
          id={'operator-rig-types-filter'}
          options={lookupRigTypes}
          multiple
        />
      </Stack>
      <Stack>
        <Typography color={'text.primary'} variant={'body2'}>
          OPERATORS
        </Typography>
        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ operators: { type: 'multiselect', value: val } })
          }
          value={operators?.value || []}
          id={'operator-operators-filter'}
          options={lookupRigsOperators}
          multiple
        />
      </Stack>
      <Stack>
        <Typography color={'text.primary'} variant={'body2'}>
          CONTRACT STATUS
        </Typography>

        <LookupSelect
          handleChange={(_, val) =>
            handleFilterChange({ contractStatus: { type: 'multiselect', value: val } })
          }
          value={contractStatus?.value || []}
          id={'operator-contract-status-filter'}
          options={CONTRACT_STATUSES}
          multiple
        />
      </Stack>
      <Box>
        <FilterSlider
          autoMarkers
          sliderWidth={'95%'}
          titleVariant={'body2'}
          title={'WATER DEPTH AT DRILLING SITE [ft]'}
          value={[waterDepth.min, waterDepth.max]}
          onChange={(value) =>
            handleFilterChange({
              waterDepth: { type: 'range', min: parseInt(value[0]), max: parseInt(value[1]) }
            })
          }
          max={lookupWaterDepth[1]}
          min={lookupWaterDepth[0]}
          step={1000}
        />
      </Box>
    </Stack>
  );
}

RequirementsGeneralFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object
};

RequirementsGeneralFilters.defaultProps = {};

export default RequirementsGeneralFilters;
