import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Modal, Stack, TextField, Typography } from '@esgian/esgianui';
import { ACTIONS, FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { useStore } from '@store/Store';
import { snackbar } from '@mobiscroll/react';

function AddRigCommentModal({ project, selectedResource, setRigComment }) {
  const [rig, setRig] = useState(null);
  const [comment, setComment] = useState(null);
  const { dispatch } = useStore();

  useEffect(() => {
    if (!selectedResource) return;
    let currentRig = project.rigs?.find(({ rigId }) => rigId === selectedResource.id);
    if (currentRig.comment) {
      setComment(currentRig.comment);
    }
    setRig(currentRig);
  }, [selectedResource]);

  const handleApplyClick = () => {
    if (comment !== '' && comment !== null) {
      dispatch({
        type: RIG_SUPPLIER_ACTIONS.ADD_RIG_COMMENT,
        flow: FLOW.RIG_SUPPLIER,
        payload: { comment: comment, projectId: project.projectId, rigId: selectedResource.id }
      });
      setComment(null);
      setRigComment(null);
      snackbar({
        message: 'Comment added',
        color: 'success',
        duration: 5000,
        display: 'top'
      });
    }
  };

  return (
    <Modal
      size={'sm'}
      handleClose={() => {
        setComment(null);
        setRigComment(null);
      }}
      title={`${rig?.comment ? 'Edit' : 'Add'} comment `}
      open={!!selectedResource}
      actions={[
        <Button
          disableElevation
          onClick={() => {
            setComment(null);
            setRigComment(null);
          }}
          color={'secondary'}
          key={'cancel-changes-module'}>
          Cancel
        </Button>,
        <Button
          disabled={comment === '' || comment === null}
          color={'secondary'}
          disableElevation
          onClick={handleApplyClick}
          key={'apply-changes'}
          variant={'contained'}>
          Apply
        </Button>
      ]}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant={'body2'}>COMMENT</Typography>
            <TextField
              onChange={({ target }) => setComment(target.value)}
              value={comment}
              variant={'outlined'}
              multiline
              minRows={4}
              fullWidth
            />
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
}

AddRigCommentModal.propTypes = {
  selectedResource: PropTypes.object,
  setRigComment: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

AddRigCommentModal.defaultProps = {
  rigComment: null
};

export default AddRigCommentModal;
