import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Box, Dashboard, EsgianTheme } from '@esgian/esgianui';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Route, useNavigate, Navigate, Routes } from 'react-router-dom';
import { AppReducer, FLOW, GENERAL_ACTIONS } from '@store/AppReducer';
import { StoreProvider } from '@store/Store';
import { getSideMenuList } from './SideMenuList';
import { getToken, logout, setStateToSessionStorage, setToken } from '@store/helpers';
import { logoutUser, verifyToken } from '@api';
import { initState, setStateFromSessionStorage } from '@store/InitState';
import { MyProjectsPage } from './pages';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import { getLookupRigs } from '@api/Rigs';
import './mobiscrollTheme.sass';
import { PrivateRoute } from '@components';
import { LoginPage } from '@pages';
import Swal from 'sweetalert2';
import UnderConstPage from '@pages/UnderConstPage';
function App() {
  const [loadingUser, setLoadingUser] = useState(true);
  const [init, setInit] = useState(false);
  const [loginRedirect, setLoginRedirect] = useState('/');
  const [state, dispatch] = useReducer(AppReducer, initState);
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  const toggleDarkMode = useCallback(() => {
    localStorage.setItem('esgian-theme-mode', `${!state.themeMode}`);
    dispatch({
      type: GENERAL_ACTIONS.UPDATE_THEME_MODE,
      flow: FLOW.GENERAL,
      payload: { themeMode: !state.themeMode }
    });
  }, [state.themeMode]);

  const handleSideMenuOpen = useCallback((val) => {
    localStorage.setItem('esgian-menu-open', `${val}`);
    dispatch({
      type: GENERAL_ACTIONS.UPDATE_MENU_OPEN,
      flow: FLOW.GENERAL,
      payload: { sideMenuOpen: val }
    });
  }, []);
  const handleLogout = useCallback(async () => {
    Swal.fire({
      title: 'Signing out...'
    });
    Swal.showLoading();
    await logoutUser(getToken()).then(() => {
      logout();
      dispatch({ type: GENERAL_ACTIONS.SET_USER, flow: FLOW.GENERAL, payload: { user: null } });
    });
    Swal.close();

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Logout successful',
      showConfirmButton: false,
      timer: 1500
    });
    routeChange('/login');
  }, []);

  useEffect(() => {
    if (init) {
      setStateToSessionStorage(state);
    }
  }, [state]);

  useEffect(() => {
    setStateFromSessionStorage(dispatch);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const checkValidToken = async () => {
      try {
        let tempState = JSON.parse(window.sessionStorage.getItem('esgianShipAnalytics')) || null;
        const { user } = tempState || {};

        let token = state.user?.token || getToken();
        if (token) {
          await verifyToken(signal, token)
            .then(async (response) => {
              if (user) {
                setStateFromSessionStorage(dispatch, response);
              }
              dispatch({
                type: GENERAL_ACTIONS.SET_USER,
                flow: FLOW.GENERAL,
                payload: { user: response }
              });
              setToken(response.token);
              setLoadingUser(false);
            })
            .catch(() => {
              dispatch({
                type: GENERAL_ACTIONS.SET_USER,
                flow: FLOW.GENERAL,
                payload: { user: null }
              });
              setLoadingUser(false);
            });
        } else {
          dispatch({ type: GENERAL_ACTIONS.SET_USER, flow: FLOW.GENERAL, payload: { user: null } });
          setLoadingUser(false);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (window.location.search && window.location.pathname) {
      let tempPath = window.location.pathname + window.location.search;
      setLoginRedirect(tempPath);
    }
    checkValidToken();
    setInit(true);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const loadInitData = async () => {
      try {
        await getLookupRigs(signal).then((result) => {
          dispatch({
            type: GENERAL_ACTIONS.SET_LOOKUP_DATA,
            flow: FLOW.GENERAL,
            payload: {
              filterLookups: result
            }
          });
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    loadInitData();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <EsgianTheme app={'rigsPlanner'} mode={'light'}>
      <Dashboard
        manualContainerStyles
        app={'gpr'}
        handleLogoutClick={handleLogout}
        hideMenu={false}
        handleFaqClick={() => {
          routeChange('/rigs-planner/faq');
        }}
        user={state.user}
        appTitle={'MARKET PLANNER'}
        themeMode={'light'}
        sideMenuOpen={state.sideMenuOpen}
        setSideMenuOpen={(val) => handleSideMenuOpen(val)}
        sideMenuContent={getSideMenuList(routeChange, state.sideMenuOpen)}>
        <Box sx={{ padding: '1em', width: '100%' }}>
          <StoreProvider dispatch={dispatch} state={state}>
            <Routes>
              <Route path="/" element={<Navigate to="/market-planner" />} />
              <Route path="/*" element={<Navigate to="/market-planner" />} />
              <Route path="/login" exact element={<LoginPage loginRedirect={loginRedirect} />} />
              <Route
                exact
                path="/market-planner"
                element={
                  <PrivateRoute loadingUser={loadingUser}>
                    <MyProjectsPage />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/market-planner/:projectId"
                element={
                  <PrivateRoute loadingUser={loadingUser}>
                    <ProjectPage loadingUser={loadingUser} />
                  </PrivateRoute>
                }
              />
            </Routes>
          </StoreProvider>
        </Box>
      </Dashboard>
    </EsgianTheme>
  );
}

export default App;
