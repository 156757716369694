import moment from 'moment/moment';
import { EVENT_TYPES } from '@constants';

/**
 * Create the event and resources required for the rigs timeline
 */

export const getRequirementResources = (customRequirements, rigId) => {
  let rigRequirements = [];
  let ids = [];
  customRequirements?.forEach((requirement) => {
    if (requirement.rigId === rigId) {
      ids.push(`${requirement.contractId},${requirement.rigId}`);
      rigRequirements.push({
        level: 2,
        isRequirement: true,
        id: `${requirement.contractId},${requirement.rigId}`,
        name: requirement.projectName
          ? `${requirement.operator} | ${requirement.projectName}`
          : `${requirement.operator} | ${requirement.country}`,
        color: '#595959',
        rigType: ''
      });
    }
  });
  return { rigRequirements: rigRequirements, ids: ids };
};

export const createRigSupplyEvent = (event, eventType, customResource = null) => {
  let resource = event.rigId;
  let title = event.projectName !== '' && event.projectName !== null ? event.projectName : null;

  let editable = !customResource;
  let endDate = event.endDate;
  if (eventType === EVENT_TYPES.ACTIVITY) {
    editable = false;
    title = event.rigStatus;
  }
  if (eventType === EVENT_TYPES.CONTRACT) {
    endDate = moment(event.startDate).add(event.duration, 'days').format('yyyy-MM-DD');
  }
  if (eventType === EVENT_TYPES.CONTRACT) {
    title = title || null;
  }

  if (eventType === EVENT_TYPES.REQUIREMENT) {
    title = title || null;
    event.latestStartDate = event.latestStartDate
      ? moment(event.latestStartDate).format('yyyy-MM-DD')
      : moment(event.startDate).format('yyyy-MM-DD');
    endDate = moment(event.latestStartDate).add(event.duration, 'days').format('yyyy-MM-DD');
    resource = `${event.contractId},${event.rigId}`;
  }

  return {
    details: { ...event, type: eventType },
    editable: editable,
    start: moment(event.startDate, 'yyyy-MM-DD'),
    end: moment(endDate, 'yyyy-MM-DD'),
    resource: customResource || resource,
    title: title,
    type: eventType,
    taskType: eventType === EVENT_TYPES.ACTIVITY ? event.rigStatusId : event.contractStatusId
  };
};

export const getRigSupplyData = (
  selectedRigs,
  customContracts,
  deletedContracts,
  customRequirements,
  lookupRigs
) => {
  let resources = [];
  let contracts = [];
  let activities = [];
  let childResourceEventIds = [];
  const selectedRigIds = selectedRigs.map(({ rigId }) => rigId) || [];

  let deletedContractIds = deletedContracts?.map(({ contractId }) => contractId);
  lookupRigs?.some(
    ({ rigId, rigType, rigName, contracts: rigContracts, activities: rigActivities }) => {
      if (selectedRigIds.includes(rigId)) {
        let displayContracts =
          rigContracts?.filter(({ contractId }) => !deletedContractIds.includes(contractId)) || [];
        contracts.push(...displayContracts);
        activities.push(...rigActivities);
        let { rigRequirements, ids } = getRequirementResources(customRequirements, rigId);
        childResourceEventIds.push(...ids);
        let rank = selectedRigs?.find(({ rigId: id }) => id === rigId).rank;
        resources.push({
          id: rigId,
          level: 1,
          rank: rank,
          name: rigName,
          color: '#595959',
          rigType: rigType,
          children: rigRequirements
        });
      }
    }
  );
  activities = activities?.map((item) => createRigSupplyEvent(item, EVENT_TYPES.ACTIVITY));
  contracts = contracts?.map((item) => createRigSupplyEvent(item, EVENT_TYPES.CONTRACT));
  let contractsCustom = customContracts?.map((item) =>
    createRigSupplyEvent(item, EVENT_TYPES.CONTRACT)
  );
  let requirementEvents = [...customRequirements]?.map((item) =>
    createRigSupplyEvent(item, EVENT_TYPES.REQUIREMENT)
  );
  return {
    events: [...contracts, ...contractsCustom, ...activities, ...requirementEvents],
    resources: resources.sort((a, b) => a.rank - b.rank),
    childResourceEventIds: childResourceEventIds
  };
};

export const checkIfDuplicateContract = (rigsContracts, event) => {
  return rigsContracts?.some((contract) => {
    return (
      contract.details.rigId === event.resource &&
      contract.type === event.type &&
      contract.details.contractId === event.details.contractId
    );
  });
};

export const checkAndAcceptUpdate = (event, rigs) => {
  const {
    details: { rigTypes }
  } = event;

  let rigTypeIds = rigTypes?.map(({ id }) => id);
  if (!rigTypeIds?.length) {
    return true;
  }
  let eventId = event.resource;

  if (typeof eventId === 'string') {
    eventId = parseInt(eventId.split(',')[1]);
  }

  let rig = rigs.find(({ rigId }) => rigId === eventId);
  return rigTypeIds?.includes(rig.rigTypeId);
};
