import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InfoModal, Stack, Typography } from '@esgian/esgianui';
import { useStore } from '@store/Store';

function EventMismatchModal({ event, handleAccept, onClose }) {
  const {
    state: { filterLookups }
  } = useStore();
  const { rigs: lookupRigs } = filterLookups;

  const { details } = event || {};
  const { rigTypes } = details || {};

  const { rigType: resourceRigType } =
    lookupRigs?.find(({ rigId }) => rigId === event?.resource) || {};

  return (
    <InfoModal
      PaperProps={{ sx: { maxWidth: 'fit-content' } }}
      open={!!event}
      onClose={onClose}
      acceptButtonColor={'primary'}
      handleAcceptClick={handleAccept}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2} alignItems={'center'}>
            <Typography variant={'h4'}>Continue?</Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {`You're about to add a contract that requires a ${rigTypes
                ?.map(({ name }) => name)
                ?.join(' or ')} rig to a ${resourceRigType} rig.`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </InfoModal>
  );
}

EventMismatchModal.propTypes = {
  event: PropTypes.object,
  handleAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

EventMismatchModal.defaultProps = {
  event: null
};

export default EventMismatchModal;
