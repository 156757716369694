import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MenuIcon,
  Modal,
  Stack,
  Typography,
  Paper,
  Box,
  Chip,
  DragIndicatorIcon,
  DeleteOutlineIcon,
  IconButton,
  Checkbox,
  ListItem,
  Divider,
  Alert
} from '@esgian/esgianui';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useStore } from '@store/Store';
import { getProject } from '@helpers/helpers';
import { useParams } from 'react-router-dom';
import { FLOW, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { RESOURCE_TYPES } from '@constants';
import { snackbar } from '@mobiscroll/react';
function SortResourcesModal({ resourceType, open, onClose }) {
  const [tempResourceOrder, setTempResourcesOrder] = useState([]);
  const [resetIds, setResetIds] = useState([]);
  const [removedResources, setRemovedResources] = useState([]);

  const params = useParams();
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();

  useEffect(() => {
    if (!projects?.length) return;
    const projectId = params.projectId;
    const resource = getProject(projects, projectId)?.[resourceType];
    setTempResourcesOrder(resource.map((item) => item));
  }, [open]);

  const handleRemovedResources = () => {
    let type = RIG_SUPPLIER_ACTIONS.REMOVE_RIG_FROM_PROJECT;
    let flow = FLOW.RIG_SUPPLIER;

    if (resourceType === RESOURCE_TYPES.OPERATORS) {
      type = OIL_COMPANY_ACTIONS.REMOVE_OPERATOR_FROM_PROJECT;
      flow = FLOW.OIL_COMPANY;
    }

    removedResources.forEach((resourceId) => {
      dispatch({
        type: type,
        flow: flow,
        payload: { projectId: params.projectId, id: resourceId }
      });
    });
  };

  const handleResetResources = () => {
    let type = RIG_SUPPLIER_ACTIONS.RESET_RIG;
    let flow = FLOW.RIG_SUPPLIER;

    if (resourceType === RESOURCE_TYPES.OPERATORS) {
      type = OIL_COMPANY_ACTIONS.RESET_OPERATOR;
      flow = FLOW.OIL_COMPANY;
    }

    resetIds.forEach((resourceId) => {
      dispatch({
        type: type,
        flow: flow,
        payload: { projectId: params.projectId, id: resourceId }
      });
    });
  };

  const handleSave = () => {
    let type = RIG_SUPPLIER_ACTIONS.UPDATE_RIGS_ORDER;
    let flow = FLOW.RIG_SUPPLIER;

    if (removedResources) {
      handleRemovedResources();
    }
    if (resetIds) {
      handleResetResources();
    }

    if (resourceType === RESOURCE_TYPES.OPERATORS) {
      flow = FLOW.OIL_COMPANY;
      type = OIL_COMPANY_ACTIONS.UPDATE_OPERATORS_ORDER;
    }
    dispatch({
      type: type,
      flow: flow,
      payload: { projectId: params.projectId, resource: tempResourceOrder }
    });
    snackbar({
      message: `${resourceType.charAt(0).toUpperCase() + resourceType.slice(1)} updated`,
      color: 'success',
      duration: 5000,
      display: 'top'
    });
    onClose();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const newItems = reorder(tempResourceOrder, source.index, destination.index);
    setTempResourcesOrder(newItems);
  };
  const handleResetClick = (id) => {
    if (!resetIds.includes(id)) {
      setResetIds((prev) => [...prev, id]);
    } else {
      setResetIds([...resetIds].filter((val) => val !== id));
    }
  };

  const handleDeleteClick = (displayId) => {
    const filtered = [...tempResourceOrder].filter(
      ({ id, rigId }) => id !== displayId && rigId !== displayId
    );
    setRemovedResources((prevState) => [...prevState, displayId]);
    setTempResourcesOrder(filtered);
  };

  return (
    <Modal
      title={`Update ${resourceType} order`}
      size={'sm'}
      PaperProps={{ sx: { height: '75vh' } }}
      open={open}
      DialogActionsProps={{ sx: { justifyContent: 'center', gap: '16px' } }}
      handleClose={onClose}
      actions={[
        <Button withUnderline key={'cancel-create'} color={'secondary'} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          disableElevation
          color={'secondary'}
          key={'submit-create'}
          variant={'contained'}
          onClick={handleSave}>
          save
        </Button>
      ]}>
      <Stack direction={'column'} justifyContent={'center'}>
        <Alert severity={'info'}>Changes done will not be applied until saved.</Alert>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <ListItem
            divider
            sx={{
              pl: 3.2,
              pr: 1,
              backgroundColor: ({ palette }) => palette.background.paper
            }}>
            <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'}>
              <Typography variant={'subtitle1'} bold>
                {resourceType.charAt(0).toUpperCase() + resourceType.slice(1)}
              </Typography>
              <Stack direction={'row'} spacing={1}>
                <Typography bold variant={'subtitle1'}>
                  Reset
                </Typography>
                <Divider sx={{ height: 'inherit' }} orientation={'vertical'} />
                <Typography bold variant={'subtitle1'}>
                  Delete
                </Typography>
              </Stack>
            </Stack>
          </ListItem>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  style={{ flexDirection: 'column', display: 'flex', gap: '8px' }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {tempResourceOrder.map(({ name, id, rigName, rigId }, index) => {
                    let displayName = resourceType === RESOURCE_TYPES.OPERATORS ? name : rigName;
                    let displayId = resourceType === RESOURCE_TYPES.OPERATORS ? id : rigId;

                    return (
                      <Draggable
                        key={`item-${displayName.replace(/\s+/g, '-')}`}
                        draggableId={`${displayId}`}
                        index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <span style={{ display: 'none' }}>{provided.placeholder}</span>

                            <ListItem
                              divider
                              sx={{
                                pl: 0,
                                backgroundColor: ({ palette }) => palette.background.paper
                              }}>
                              <Stack
                                sx={{ width: '100%' }}
                                spacing={1}
                                direction={'row'}
                                justifyContent={'space-between'}>
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                  <DragIndicatorIcon />
                                  <Typography>{displayName}</Typography>
                                </Stack>
                                <Stack spacing={1} direction={'row'}>
                                  <Divider sx={{ height: 'inherit' }} orientation={'vertical'} />
                                  <Checkbox
                                    checked={resetIds.includes(displayId)}
                                    onChange={() => handleResetClick(displayId)}
                                    size={'small'}
                                  />
                                  <Divider sx={{ height: 'inherit' }} orientation={'vertical'} />
                                  <IconButton onClick={() => handleDeleteClick(displayId)}>
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Stack>
                              </Stack>
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      </Stack>
    </Modal>
  );
}

SortResourcesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  resourceType: PropTypes.string
};

SortResourcesModal.defaultProps = {
  open: false,
  resourceType: RESOURCE_TYPES.OPERATORS
};

export default SortResourcesModal;
